import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inDraftBtn: {
    color: "#3E5463",
    borderRadius: "20px",
    background: "#DCE3E8",
    padding: "4px 8px 4px 8px",
  },
  createdBtn: {
    color: "#278BD2",
    borderRadius: "20px",
    background: "#E1F6FF",
    padding: "4px 8px 4px 8px",
  },
  mintingBtn: {
    color: "#149973",
    borderRadius: "20px",
    background: "#D0FFDD",
    padding: "4px 8px 4px 8px",
  },
  mintedBtn: {
    color: "#4E5AD6",
    borderRadius: "20px",
    background: "#ECEDFF",
    padding: "4px 8px 4px 8px",
  },
});
export const InDraftStatus = () => {
  const classes = useStyles();
  return <span className={classes.inDraftBtn}>In-draft NFT</span>;
};
export const CreatedStatus = () => {
  const classes = useStyles();
  return <span className={classes.createdBtn}>Pre-mint NFT</span>;
};
export const MintingStatus = () => {
  const classes = useStyles();
  return <span className={classes.mintingBtn}>Minting NFT</span>;
};
export const MintedStatus = () => {
  const classes = useStyles();
  return <span className={classes.mintedBtn}>Minted NFT</span>;
};
