import React from "react";
import ForgetPassword from "../pages/authenticate/forget-password/ForgetPassword";
import { LoginLayout } from "../layouts/login";
import { DashBoardLayout } from "../layouts/DashBoardLayout/index";

import Login from "../pages/authenticate/login";
import Register from "../pages/authenticate/sign-up/signup";
import ResetPassword from "../pages/authenticate/forget-password/ResetPassword";
import EmailVerification from "../pages/authenticate/sign-up/email-verification";
import SetupPassword from "../pages/authenticate/sign-up/setup-password";
import AdminLogin from "../pages/authenticate/admin-login";
import SetupInformation from "../pages/authenticate/sign-up/setup-information";
import VerifycationSuccessfully from "../pages/verification-successful";
import LadingPage from "../pages/landing-page";
import { MainLayout } from "../layouts/main";
import NFTList from "../pages/NFTs-list";
import CorporateUserList from "../pages/corporate-user-list";
import { links } from "../constants/links";
import Settings from "../pages/settings";
import AdminWallet from "../pages/admin-wallet";
import WalletDetails from "../pages/wallet-details";
import CorporateUserDetails from "../pages/user-details";
import Wallet from "../pages/Wallet";
import WalletTabs from "../pages/wallet-tabs";
import SendNFT from "../pages/send-NFT";
import MintingNFT from "../pages/minting-nft";
import NFTDetail from "../pages/NFT-detail";
import Static from "../pages/static";
import NFTService from "../pages/NFT-service";
import NFTServiceDetails from "../pages/NFT-service-details";
import ManageBanner from "../pages/manage-banners";
import { PageTypeEnum } from "../services/static";
import OfferDetail from "../pages/Offer-detail";
import ProcessManagementList from "../pages/progress-management";
import { EmptyLayout } from "../layouts/EmptyLayout";
import Preview3dNFT from "../pages/preview-3d-nft";

export interface IRoute {
  restricted?: boolean;
  layout?: React.ComponentType;
  title?: string;
}

export enum ROLE {
  ADMIN = "admin",
  CORPORATE = "corporate",
}

const privateRoutes = [
  {
    children: <CorporateUserList />,
    path: links.LIST_USER,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <CorporateUserDetails />,
    path: links.USER_DETAIL,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <NFTList />,
    path: links.LIST_NFT,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },

  {
    children: <NFTDetail />,
    path: links.NFT_DETAIL,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE, ROLE.ADMIN],
  },
  {
    children: <AdminWallet />,
    path: links.WALLET_LIST,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <WalletDetails />,
    path: links.WALLET_DETAILS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <NFTService />,
    path: links.NFT_SERVICE,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <NFTServiceDetails />,
    path: links.NFT_SERVICE_DETAILS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <Static provide={{ type: PageTypeEnum.TERMS, label: "Terms", category: "Terms" }} />,
    path: links.TERMS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <Static provide={{ type: PageTypeEnum.ABOUT_US, label: "About Us" }} />,
    path: links.ABOUT_US,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <Static provide={{ type: PageTypeEnum.FAQ, label: "FAQ" }} />,
    path: links.FAQ,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <Wallet />,
    path: links.WALLET,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <Settings />,
    path: links.SETTINGS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <WalletTabs />,
    path: links.MY_NFTS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <SendNFT />,
    path: links.SEND_NFT,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <MintingNFT />,
    path: links.MINTING_NFT,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <ManageBanner />,
    path: links.LIST_BANNERS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN],
  },
  {
    children: <OfferDetail />,
    path: links.OFFER_DETAILS,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.CORPORATE],
  },
  {
    children: <ProcessManagementList />,
    path: links.PROCESS_MANAGEMENT,
    restricted: true,
    layout: DashBoardLayout,
    role: [ROLE.ADMIN, ROLE.CORPORATE],
  },
];
const publicRoutes = [
  {
    children: <Login />,
    path: links.LOGIN,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <LadingPage />,
    path: "*",
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <AdminLogin />,
    path: links.ADMIN_LOGIN,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <ForgetPassword />,
    path: links.FORGOT_PASSWORD,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <ResetPassword />,
    path: links.RESET_PASSWORD,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <Register />,
    path: links.SIGN_UP,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <EmailVerification />,
    path: links.EMAIL_VERIFICATION,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <SetupPassword />,
    path: links.SETUP_PASSWORD,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <SetupInformation />,
    path: links.SETUP_INFORMATION,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <VerifycationSuccessfully />,
    path: links.VERIFY_SUCCESS,
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <Preview3dNFT />,
    path: links.NFT_DETAIL_PREVIEW,
    layout: EmptyLayout,
    restricted: true,
  },
];
const noLayoutRoutes: IRoute[] = [];
interface IConfigRoutes {
  appRoutes: IRoute[] | {};
  isPrivate?: boolean;
  layout?: React.FC;
  role?: ROLE;
}

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
  },
  {
    appRoutes: noLayoutRoutes,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
    isPrivate: false,
  },
];
