import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Items from "../../services/items";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, FormControl, Grid, MenuItem, Select, TableHead, TextField, Typography } from "@mui/material";
import { SearchOutlined } from "@material-ui/icons";
import WhiteButton from "../../components/WhiteButton";
import { MintStatus } from "../../const";
import CustomDateRangePicker from "../../components/date-range-picker";
import moment from "moment";
import { CreatedStatus, InDraftStatus, MintedStatus, MintingStatus } from "../../components/mintStatus";
import { useNavigate } from "react-router-dom";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import CloseIcon from "@mui/icons-material/Close";
import EmptyRecord from "../../components/EmptyRecord";
import NoItems from "../../components/NoItems";
import CustomRangePagination from "../../components/RangePagination";
import useResponsive from "../../utils/responsive";

const sortcolumn = [
  {
    id: "brand",
    label: "Brand",
    width: "10%",
  },
  {
    id: "category",
    label: "Category",
    width: "15%",
  },
  {
    id: "subCategory",
    label: "Sub-Category",
    width: "15%",
  },
];
const nonSortColumn = [
  {
    id: "name",
    label: "Name",
    width: "15%",
  },
  {
    id: "contractID",
    width: "15%",
    label: "Policy ID",
  },
  {
    id: "createdDate",
    width: "15%",
    label: "Created date",
  },
  {
    id: "status",
    width: "15%",
    label: "Status",
  },
];

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {},
  container: {
    maxHeight: 610,
  },
});
export default function NFTList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [listNft, setListNft] = useState<any>([]);
  const [meta, setMeta] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [status, setStatus] = useState("all");
  const { isSmallDesktop, isDesktopOrLaptop, isTabletOrMobile } = useResponsive();

  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<any[]>([null, null]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const getNFTsList = async (params?: any) => {
    const res = await Items.getListNFTsAdmin({
      search: search,
      page: params?.page ?? page,
      limit: params?.rowsPerPage ?? rowsPerPage,
      status: status,
      startDate: selectedDate[0] ? moment(selectedDate[0]).toISOString() : undefined,
      endDate: selectedDate[1] ? moment(selectedDate[1]).toISOString() : undefined,
    });
    if (res.statusCode === 200) {
      setListNft(res.data.items);
      setMeta(res.data.meta);
    }
  };

  useEffect(() => {
    getNFTsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearchChange = (e: any) => {
    setIsSearch(true);
    setSearch(e.target.value);
  };
  const onClearFilter = async () => {
    setSearch("");
    setStatus("all");
    const res = await Items.getListNFTsAdmin({
      search: "",
      page: 0,
      limit: 20,
      status: "all",
    });
    if (res.statusCode === 200) {
      setListNft(res.data.items);
      setMeta(res.data.meta);
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const statusRender = (e: string) => {
    if (e === MintStatus.InDraft) {
      return <InDraftStatus />;
    }
    if (e === MintStatus.Created) {
      return <CreatedStatus />;
    }
    if (e === MintStatus.Minting) {
      return <MintingStatus />;
    }
    return <MintedStatus />;
  };

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>NFT List </span>
      <Box component={"div"} marginTop={4}>
        <Grid marginBottom={3}>
          <Box component={"div"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
            <h2>NFT List</h2>
          </Box>
        </Grid>
        <Grid container alignItems={"center"} gap={5}>
          <Grid item xs={isDesktopOrLaptop ? 4 : 5}>
            <Typography sx={{ fontWeight: "bold" }}>Search &nbsp;</Typography>
            <TextField
              label={search === "" ? "Enter brand, category, sub-category, name" : ""}
              fullWidth
              sx={{
                marginTop: 1,
                "& label": {
                  marginLeft: 4,
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
              }}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
                endAdornment: search && (
                  <IconButton onClick={() => setSearch("")}>
                    <CloseIcon />
                  </IconButton>
                ),
              }}
              size="small"
              onChange={(e) => {
                handleSearchChange(e);
              }}
              value={search}
            ></TextField>
          </Grid>
          <Grid item xs={2}>
            <Typography sx={{ fontWeight: "bold" }}>Status &nbsp;</Typography>
            <FormControl fullWidth>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                sx={{
                  marginTop: 1,
                  borderRadius: 20,
                  height: "40px",
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={MintStatus.InDraft}>In draft</MenuItem>
                <MenuItem value={MintStatus.Created}>Created</MenuItem>
                <MenuItem value={MintStatus.Minting}>Minting</MenuItem>
                <MenuItem value={MintStatus.Minted}>Minted</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Box component={"div"}>
              <Typography sx={{ fontWeight: "bold", ml: "30px" }}>Created date</Typography>
              <Box component={"div"} sx={{ mt: 1 }}>
                <CustomDateRangePicker
                  onChange={handleDateChange}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Box>
            </Box>
          </Grid>
          {isDesktopOrLaptop && (
            <Grid item>
              <Box component={"div"}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>

                <WhiteButton label="Clear all filters" onClick={onClearFilter} width="150px" sx={{ fontSize: 16 }} />
              </Box>
            </Grid>
          )}
          {isDesktopOrLaptop && (
            <Grid item>
              <Box component={"div"}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>

                <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getNFTsList}>
                  Search
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        {isSmallDesktop && (
          <Box component={"div"} display={"flex"}>
            <Box component={"div"} mr={2}>
              <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
              <WhiteButton
                label="Clear all filters"
                onClick={() => {
                  onClearFilter();
                }}
                width="150px"
                sx={{ fontSize: 16 }}
              />
            </Box>
            <Box component={"div"}>
              <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
              <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getNFTsList}>
                Search
              </Button>
            </Box>
          </Box>
        )}
        <Box component={"div"} mt={5}>
          {listNft.length > 0 && (
            <>
              <TableContainer className={classes.container} style={{ maxHeight: isTabletOrMobile ? "35vh" : "40vh" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {sortcolumn.map((column) => (
                        <TableCell key={column.id} style={{ width: column.width }}>
                          {column.label}
                        </TableCell>
                      ))}
                      {nonSortColumn.map((column) => (
                        <TableCell key={column.id} style={{ minWidth: column.width }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listNft.map((row: any, index: number) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                          <TableCell
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/nft-details/${row?.id}`, { state: { id: row?.id } });
                            }}
                          >
                            {row?.corporate?.name ? row?.corporate?.name : ""}
                          </TableCell>
                          <TableCell>{row?.category?.name}</TableCell>
                          <TableCell>{row?.secondCategory?.name}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell style={{ cursor: "pointer", color: "#518CE0" }} onClick={() => {}}>
                            <u>{row.txId}</u>
                          </TableCell>
                          <TableCell>{moment(row.createdAt).format("MM-DD-YYYY")}</TableCell>
                          <TableCell>{statusRender(row.status)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomRangePagination
                onPageChange={handleChangePage}
                meta={meta}
                page={page}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
              />
            </>
          )}
          {listNft.length === 0 && !isSearch && <EmptyRecord />}
          {listNft.length === 0 && isSearch && <NoItems />}
        </Box>
      </Box>
    </Box>
  );
}
