import * as React from "react";
import Box from "@mui/material/Box";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import { Button, Grid, Typography } from "@mui/material";
import CustomTextField from "../../components/CustomTextField";
import SVG from "react-inlinesvg";
import copy from "../../assets/icons/copy.svg";
import CustomModal from "../../components/modal";
import successIcon from "../../assets/icons/check-one.svg";
import { makeStyles } from "@mui/styles";
import { links } from "../../constants/links";
import { useLocation, useNavigate } from "react-router-dom";
import { copyAddressToClipboard } from "../../utils/utils";
import { ToastMessage } from "../../const";
import Items from "../../services/items";
import { useWalletContext } from "../../contexts/wallet-provider";
import MintingNft from "../../services/minting-nft";
import _ from "lodash";
const useStyles = makeStyles({
  detailsContainer: {
    marginLeft: "20%",
    marginBottom: 5,
  },
});

const SendNFT = () => {
  const [isNextStep, setIsNextStep] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [receiveAddress, setReceiveAddress] = React.useState("");
  const { state } = useLocation();
  const { selectedWallet } = useWalletContext();
  const [exChangeAda, setExchangeAda] = React.useState<any>();

  const [errMsg, setErrMsg] = React.useState("");
  const [feeData, setFeeData] = React.useState<any>();
  const navigate = useNavigate();
  const classes = useStyles();
  const handleConitnueStep2 = async () => {
    try {
      const res = await Items.sendNFT({
        password: password,
        walletAddress: state.walletAddress ?? selectedWallet?.address,
        receivingAddress: receiveAddress || state.address,
        itemId: state?.nftDetails?.id,
      });
      if (res.statusCode === 200) {
        setShowModal(true);
      }
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }
  };

  const hideAdress = () => {
    if (receiveAddress || state.address)
      return (
        `${receiveAddress.slice(0, 6)}...${receiveAddress.slice(-6)}` ||
        `${state.address.slice(0, 6)}...${state.address.slice(-6)}`
      );
  };

  const getExchangeAda = async () => {
    const res = await MintingNft.getPriceADA();
    if (_.get(res, "statusCode") === 200) {
      setExchangeAda(res.data);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <SVG src={successIcon} />
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Send Request submitted
          </Typography>
        </Box>

        <Grid container className={classes.detailsContainer}>
          <Grid item xs={5}>
            <Typography variant="h4">NFT</Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle1" sx={{ color: themeColors.blue }}>
              {`${state.nftDetails?.id.slice(0, 6)}...${state.nftDetails?.id.slice(-6)}`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.detailsContainer}>
          <Grid item xs={5}>
            <Typography variant="h4">Fee</Typography>
          </Grid>
          <Grid>
            <Box component={"div"} display={"flex"} flexDirection="column">
              <Typography variant="subtitle1">{feeData?.estFee} ADA</Typography>
              <Typography variant="subtitle1">
                {feeData?.estFee ? `≈ ${(feeData?.estFee * exChangeAda.price).toFixed(6)}` : ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container className={classes.detailsContainer}>
          <Grid item xs={5}>
            <Typography variant="h4">Service fee</Typography>
          </Grid>
          <Grid>
            <Box component={"div"} display={"flex"} flexDirection="column">
              <Typography variant="subtitle1">{feeData?.estFee} ADA</Typography>
              <Typography variant="subtitle1">
                {feeData?.estFee ? `≈ ${(feeData?.systemFee * exChangeAda.price).toFixed(6)}` : ""}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container className={classes.detailsContainer}>
          <Grid item xs={5}>
            <Typography variant="h4">Receiving address</Typography>
          </Grid>
          <Grid>
            <Box component={"div"} display={"flex"}>
              <Typography mr={1} variant="subtitle1" sx={{ color: themeColors.blue }}>
                {hideAdress()}
              </Typography>
              <SVG
                src={copy}
                onClick={() => copyAddressToClipboard(receiveAddress || state.address, ToastMessage.WalletCoppied)}
              />
            </Box>
          </Grid>
        </Grid>

        <Box component={"div"} mt={2} mb={2}>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
            It may take a while for your blockchain confirmation to appear during times
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
            of heavy blockchain load.
          </Typography>
        </Box>
        <Box component={"div"} display="flex" justifyContent={"center"}>
          <Button variant="contained" sx={{ width: 150 }} onClick={() => navigate(links.WALLET)}>
            Back to wallet
          </Button>
        </Box>
      </>
    );
  };

  const getEstimateFee = async () => {
    const params = {
      walletAddress: selectedWallet.address,
      receiveAddress: receiveAddress || state.address,
      itemIds: state?.nftDetails?.id,
    };
    const res = await Items.estimateFee(params);

    if (res?.statusCode === 200) {
      setFeeData(res?.data);
    }
  };

  React.useEffect(() => {
    getExchangeAda();
  }, []);

  return (
    <Box component={"div"}>
      <Box component={"div"} display={"flex"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Wallet&ensp; <>&gt;&ensp;</> My NFTs&ensp; <>&gt;&ensp;</>
          {state?.nftDetails?.name}&ensp; <>&gt;&ensp;</>
        </Typography>
        <Typography variant="subtitle1">Send NFT</Typography>
      </Box>
      <Typography variant="h3" mt={2} mb={2}>
        Send NFT
      </Typography>
      {isNextStep && (
        <>
          <Typography variant="subtitle1" mb={1}>
            Please provide your spending password to confirm this transaction:
          </Typography>
          <CustomTextField
            placeholder="Spending password"
            label="Password"
            sx={{ width: "30%" }}
            type="password"
            onChange={(e: string) => setPassword(e)}
            value={password}
          />
        </>
      )}
      {!isNextStep && (
        <CustomTextField
          placeholder="Enter ADA deposit address"
          label="Recipient address"
          value={receiveAddress || state.address}
          sx={{ width: "30%" }}
          onChange={(e: string) => setReceiveAddress(e)}
        />
      )}

      {isNextStep && (
        <Box component={"div"} mb={2}>
          <Grid container>
            <Grid item sx={{ width: 180 }}>
              <Typography variant="h4">Receiving address</Typography>
            </Grid>
            <Grid>
              <Box component={"div"} display="flex">
                <Typography variant="subtitle1" sx={{ color: themeColors.lightBlue, marginRight: 1 }}>
                  {hideAdress()}
                </Typography>
                <SVG
                  style={{ cursor: "pointer" }}
                  src={copy}
                  onClick={() => copyAddressToClipboard(receiveAddress || state.address, ToastMessage.WalletCoppied)}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid container>
        <Grid item sx={{ width: 180 }}>
          <Typography variant="h4">Fee</Typography>
        </Grid>
        <Grid>
          <Box component={"div"} display={"flex"} flexDirection="column">
            <Typography variant="subtitle1">
              {feeData?.estFee} {feeData?.estFee ? "ADA" : "0 ADA"}
            </Typography>
            <Typography variant="subtitle1">
              {feeData?.estFee ? `≈ ${(feeData?.estFee * exChangeAda.price).toFixed(2)}` : "0"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {errMsg && (
        <Typography sx={{ color: "red", fontStyle: "italic", marginTop: 2, fontSize: 14 }}>{errMsg}</Typography>
      )}
      {!isNextStep && (
        <Typography mt={2} variant="subtitle1">
          Get a 50% fees discount for sending this NFT to a DVOLD wallet!
        </Typography>
      )}
      {!isNextStep && (
        <Box component={"div"} width={"25%"} display="flex" justifyContent={"center"}>
          <Button
            variant="contained"
            sx={{ width: 150, marginTop: 3 }}
            onClick={() => {
              setIsNextStep(true);
              getEstimateFee();
            }}
          >
            Continue
          </Button>
        </Box>
      )}
      {isNextStep && (
        <Box component={"div"} width={"25%"} display="flex" justifyContent={"center"}>
          <Button variant="contained" sx={{ width: 150, marginTop: 3 }} onClick={handleConitnueStep2}>
            Continue
          </Button>
        </Box>
      )}
      {showModal && (
        <CustomModal open={showModal} onClose={() => setShowModal(false)}>
          {renderModalContent()}
        </CustomModal>
      )}
    </Box>
  );
};

export default SendNFT;
