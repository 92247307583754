import axiosClient from "../../api/axiosClient";
import { getPage } from "../../utils/utils";

const Offers = {
  getListOffer(params?: any): Promise<any> {
    const page = getPage(params);
    const url = `/offers?page=${page ?? 1}&limit=${
      params?.limit ?? 20
    }&${`${`walletAddress=${params?.walletAddress}`}`}&offerType=${params?.offerType}`;
    return axiosClient.get(url);
  },
  rejectApproveOffer(params?: any): Promise<any> {
    const url = `/offers/${params.id}`;
    return axiosClient.put(url,{ status: params.status });
  },
  cancelContinueOffer(params?: any): Promise<any> {
    const url = `/offers/${params.id}/cancel`;
    return axiosClient.put(url,{ status: "canceled" });
  },

};

export default Offers;
