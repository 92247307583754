import { makeStyles } from "@mui/styles";
import { themeColors } from "../../theme/schemes/PureLightTheme";

const useStyles = makeStyles({
  cancelledBtn: {
    color: "#8E45DD",
    borderRadius: "20px",
    background: "#F1E6FF",
    padding: "4px 8px 4px 8px",
  },
  rejectedBtn: {
    color: "#D64CB1",
    borderRadius: "20px",
    background: "#FFE3F5",
    padding: "4px 8px 4px 8px",
  },
  expiredBtn: {
    color: "#198EA9",
    borderRadius: "20px",
    background: "#D5F9FF",
    padding: "4px 8px 4px 8px",
  },
  waitingBtn: {
    color: "#BF830F",
    borderRadius: "20px",
    background: "#FFFBD3",
    padding: "4px 8px 4px 8px",
  },
  approvedBtn: {
    color: "#149973",
    borderRadius: "20px",
    background: themeColors.lightGreen,
    padding: "4px 8px 4px 8px",
  },
});
export const CancelledStatus = () => {
  const classes = useStyles();
  return <span className={classes.cancelledBtn}>Cancelled</span>;
};
export const RejectedStatus = () => {
  const classes = useStyles();
  return <span className={classes.rejectedBtn}>Rejected</span>;
};
export const ExpiredStatus = () => {
  const classes = useStyles();
  return <span className={classes.expiredBtn}>Expired</span>;
};
export const WaitingStatus = () => {
  const classes = useStyles();
  return <span className={classes.waitingBtn}>Waiting</span>;
};
export const ApprovedStatus = () => {
  const classes = useStyles();
  return <span className={classes.approvedBtn}>Approved</span>;
};
