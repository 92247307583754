import { Box, Button, styled } from "@mui/material";
import React from "react";
import Banner from "../../../assets/images/wallet-banner.png";
import WhiteButton from "../../../components/WhiteButton";

interface IWalletBanerProps {
  handleCreateWallet: () => void;
  handleRestoreWallet: () => void;
}

const MainContent = styled(Box)(
  () => `
    background-image: url("${Banner}");
    background-image: url("${Banner}"), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 30vh;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    zIndex: -1;
    `,
);

const Avatar = styled(Box)(
  () => `
    width: 150px;
    height: 150px;
    background: #FFFFFF;
    border-radius: 50%;
    // margin: -70px auto;
    background-image: url("${Banner}");
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
`,
);

const ActionArea = styled(Box)(
  () => `
    display: flex;
    gap: 0.8rem;
    justify-content: space-between;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    width: 100%;
  `,
);

const WalletBanner: React.FunctionComponent<IWalletBanerProps> = (props) => {
  const { handleCreateWallet, handleRestoreWallet } = props;

  return (
    <MainContent>
      <ActionArea>
        <Box component={"div"} sx={{ display: "flex", gap: "0.8rem" }}>
          <WhiteButton onClick={handleCreateWallet} label="Create a DVOLD/ADA wallet" />
          <Button onClick={handleRestoreWallet} variant="contained">
            Restore DVOLD/ADA wallet
          </Button>
        </Box>
      </ActionArea>
      <Avatar></Avatar>
    </MainContent>
  );
};

export default WalletBanner;
