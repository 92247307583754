import axiosClient from "../../api/axiosClient";
import { getPage } from "../../utils/utils";

const Users = {
  uploadDocs(params: any): Promise<any> {
    const url = "/users/upload-docs";
    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("token", params.token);
    return axiosClient.post(url, formData);
  },
  uploadMultipleDocs({ files, token }: { files: []; token: string }): Promise<any> {
    const url = "/users/upload-docs";
    const formData = new FormData();
    formData.append("token", token);
    files.forEach((file: any, index: number) => {
      formData.append(`file${index}`, file);
    });
    return axiosClient.post(url, formData);
  },
  getCurrentUser(): Promise<any> {
    const url = "/users/profile";
    return axiosClient.get(url);
  },
  getListNFTs(params?: any): Promise<any> {
    const url = `/users/nfts?page=${params?.page ?? 1}&limit=${params?.limit ?? 10}
    &${!params?.mainCategoryId && `mainCategoryId=${params?.mainCategoryId}`}
    &${params?.subCategoryIds !== undefined && `subCategoryIds=${params?.subCategoryIds}`}`;
    return axiosClient.get(url);
  },
  getListUser(params?: any): Promise<any> {
    const page = getPage(params);

    const url = `/users?page=${page ?? 1}&limit=${params?.limit ?? 10}&${`${
      params.role && `role=${params.role}`
    }`}&search=${params.search}&${`${params.status && `status=${params.status}`}`}`;
    return axiosClient.get(url);
  },
  changeCorporateStatus(params?: any): Promise<any> {
    const url = "/users/change-status";
    return axiosClient.put(url, params);
  },
  changeEmail(params?: any): Promise<any> {
    const url = "/users/change-email";
    return axiosClient.put(url, params);
  },
  updateUserInfo(params?: any): Promise<any> {
    const url = "/users/profile";
    return axiosClient.put(url, params);
  },
  resendEmail(params?: any): Promise<any> {
    const url = "/users/resend-verify";
    return axiosClient.put(url, params);
  },
  verifyToken(params?: any): Promise<any> {
    const url = "/users/verify-token";
    return axiosClient.post(url, params);
  },
  deleteAccount(params: any): Promise<any> {
    const url = "/users/delete";
    return axiosClient.put(url, params);
  },
  uploadFiles(params: any): Promise<any> {
    const url = "/users/upload-files";
    const formData = new FormData();
    formData.append("file", params.file);
    return axiosClient.post(url, formData);
  },
  getUserStatus(params: any): Promise<any> {
    const url = `/users/${params?.id}/status`;
    return axiosClient.get(url);
  },
  getUserDetail(params: any): Promise<any> {
    const url = `/users/user-profile?userId=${params.userId}`;
    return axiosClient.get(url);
  },
  changePassword(params: any): Promise<any> {
    const url = "/users/change-password";
    return axiosClient.put(url, params);
  },
};

export default Users;
