import { Box, Button, FormControlLabel, Grid, InputAdornment, Radio, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BoughtItems from "../../../components/BoughtNTFItem";
import { LeftNFTDetails } from "../../../components/LeftNFTDetails";
import { useLocation } from "react-router-dom";
import Items from "../../../services/items";
import { generateLinkType, MintStatus, ToastMessage, WalletDetailsTabs } from "../../../const";
import { useWalletContext } from "../../../contexts/wallet-provider";
import downloadtemplateIcon from "../../../assets/icons/download-template.svg";
import copy from "../../../assets/icons/copy.svg";
import createBatchIcon from "../../../assets/icons/create-batch.svg";
import SVG from "react-inlinesvg";
import { makeStyles } from "@mui/styles";
import CustomModal from "../../../components/modal";
import SmallWallet from "../../../assets/icons/small-wallet.svg";
import upload from "../../../assets/icons/upload.svg";
import generate from "../../../assets/icons/generate.svg";
import reset from "../../../assets/icons/reset.svg";
import colors from "../../../theme/colors";
import { toast } from "react-toastify";
import { Loading } from "../../../components";
import CustomRangePagination from "../../../components/RangePagination";
import WhiteButton from "../../../components/WhiteButton";
import { copyAddressToClipboard, isNFTModelType, uploadFileSize } from "../../../utils/utils";
import { NFT3Drender } from "../../../utils/nft3dRender";
import Users from "../../../services/users";

const useStyles = makeStyles({
  svgIcon: {
    marginLeft: 12,
  },
});

const MyNonMintedNFTs = ({
  title,
  walletDetails,
  walletList,
}: {
  title: string;
  walletDetails: any;
  walletList?: any;
}) => {
  const classes = useStyles();
  const inputFile = useRef(null);
  const [page, setPage] = React.useState(0);
  const [meta, setMeta] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [listNonMintedNFT, setListNonMintedNft] = useState<any>([]);
  const [walletOrder, setWalletOrder] = useState<any>(0);
  const location = useLocation();
  const [isShowModal, setIsShowModal] = useState(false);
  const { selectedWallet } = useWalletContext();
  const [mintBatch, setMintBatch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowLinkGenerate, setIsShowLinkGenerate] = useState(false);
  const [uploadFile, setUploadFile] = useState({ file: null, link: "" });
  const uploadFileRef = useRef<any>(null);
  const [errMsg, setErrMsg] = useState("");
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleUploadMintBatch = async (walletAddress: any, file: any) => {
    setIsLoading(true);

    let msg;
    const res = await Items.uploadMintBatch({ walletAddress, file });
    try {
      if (res.statusCode === 200) {
        msg = ToastMessage.UploadFileSuccess;
      }
    } catch (error) {
      msg = ToastMessage.SomeThingWentWrong;
    }
    setIsLoading(false);
    toast(msg);
    setIsShowModal(false);
  };

  const handleChangeMintBatch = async (e: any) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setMintBatch(file);
    if (walletList.length === 1) {
      handleUploadMintBatch(walletList[0].address, file);
    }
    if (walletList.length > 1) {
      setIsShowModal(true);
    }
  };
  const getListNonMintedNFT = async () => {
    const res = await Items.getListNFTs({
      page: page,
      limit: rowsPerPage,
      status: MintStatus.Created,
      walletAddress: location?.state?.walletDetails?.address ?? selectedWallet?.address,
    });
    if (res.statusCode === 200) {
      setListNonMintedNft(res.data.items);
      setMeta(res.data.meta);
    }
  };

  useEffect(() => {
    getListNonMintedNFT();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);
  const onCreateBatchClick = () => {
    (inputFile.current as any).click();
  };

  const createMintBatch = async () => {
    handleUploadMintBatch(walletList[walletOrder].address, mintBatch);
  };

  const onUpload3DFileClick = () => {
    (uploadFileRef as any)?.current?.click();
  };

  const handleUpload3DFile = async (e: any) => {
    setErrMsg("");
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!uploadFileSize(file.size)) {
      return;
    }
    setUploadFile({ ...uploadFile, file: file });
    setIsLoading(true);
    try {
      const res = await Users.uploadFiles({ file: file });
      if (res.statusCode === 200) {
        setUploadFile({ ...uploadFile, link: res.data[0].link });
      }
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }
    setIsLoading(false);
  };
  const onResetFile = () => {
    setUploadFile({ link: "", file: null });
    const file3d = document.getElementById("file-3d") as HTMLInputElement;
    if (file3d) {
      file3d.value = "";
    }
  };

  const generateLinkContent = () => {
    return (
      <Box component={"div"}>
        {uploadFile.link ? (
          <>
            {isNFTModelType(uploadFile?.link) &&
              NFT3Drender({
                link: uploadFile?.link,
                isAutoRorate: true,
              })}
            <Typography sx={{ fontWeight: 600, mt: 3 }}>Embed codes</Typography>
            <TextField
              fullWidth
              value={uploadFile?.link}
              sx={{ input: { color: colors.darkGreen } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => copyAddressToClipboard(uploadFile?.link, ToastMessage.LinkCoppied)}
                  >
                    <SVG src={copy} style={{ cursor: "pointer" }} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              mt={4}
              sx={{ cursor: "pointer" }}
              onClick={onResetFile}
              component={"div"}
            >
              <Typography color={colors.darkGreen} sx={{ mr: 1 }}>
                Reset
              </Typography>
              <SVG src={reset} />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              background: "#F1F1F1",
              height: "150px",
              borderRadius: "16px",
              marginTop: "90px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={onUpload3DFileClick}
            component={"div"}
          >
            <input
              id="file-3d"
              type="file"
              accept={generateLinkType}
              style={{
                width: "0",
                height: "0",
                opacity: "0",
                cursor: "pointer",
              }}
              onChange={handleUpload3DFile}
              ref={uploadFileRef}
            />
            <SVG src={upload} />
            <Typography sx={{ fontWeight: 500, mt: 2 }}>Click here to upload</Typography>
            <Typography>Maximum 10MB</Typography>
          </Box>
        )}
        {errMsg && (
          <Box display="flex" justifyContent={"center"} mt={2} component={"div"}>
            <Typography color={colors.warning}>{errMsg}</Typography>
          </Box>
        )}
      </Box>
    );
  };
  return (
    <Grid container>
      {isLoading && <Loading />}
      <Grid item xs={2} pr={2}>
        <LeftNFTDetails title={title} hasMintBtn={true} walletDetails={walletDetails} />
      </Grid>
      <Grid item xs={10}>
        <Box component={"div"} display="flex" justifyContent={"end"}>
          <WhiteButton
            endIcon={<SVG className={classes.svgIcon} src={generate}></SVG>}
            onClick={() => {
              setIsShowLinkGenerate(true);
            }}
            label="Link Generation"
          />
          <Button
            variant="contained"
            sx={{ marginX: 3 }}
            onClick={() => {
              window.open(process.env.REACT_APP_PREMINT_BATCH_TEMPLATE, "_blank");
            }}
          >
            Download Template
            <SVG className={classes.svgIcon} src={downloadtemplateIcon}></SVG>
          </Button>
          <Button variant="contained" onClick={onCreateBatchClick}>
            Create NFT Batch
            <SVG className={classes.svgIcon} src={createBatchIcon}></SVG>
          </Button>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={handleChangeMintBatch}
          />
        </Box>
        <CustomRangePagination
          meta={meta}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
        <Box component={"div"} display="flex" flexWrap={"wrap"}>
          {listNonMintedNFT.map((item: any, index: number) => {
            return (
              <BoughtItems key={index} itemDetails={item} mintTab={WalletDetailsTabs.nonMinted} hasStatus={true} />
            );
          })}
        </Box>
        {listNonMintedNFT.length > 0 && (
          <CustomRangePagination
            meta={meta}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
        <CustomModal open={isShowModal} onClose={() => setIsShowModal(false)} sx={{ width: 500 }} showClear>
          <Box component={"div"} sx={{ marginTop: "-30px", marginBottom: 4 }}>
            <Box component={"div"} display="flex">
              <SVG color="#548574" style={{ marginRight: 10 }} src={SmallWallet}></SVG>
              <b>Choose the wallet for NFT batch creation</b>
            </Box>
          </Box>
          {walletList.map((item: any, i: number) => {
            return (
              <Box
                component={"div"}
                key={i}
                mt={2}
                sx={{ border: `1.6px solid ${colors.secondaryLight}`, padding: "10px", borderRadius: "16px" }}
              >
                <Box
                  component={"div"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => setWalletOrder(i)}
                >
                  <Box component={"div"}>
                    <FormControlLabel
                      value={i}
                      control={<Radio checked={walletOrder === i} />}
                      label={
                        <Box component={"div"} display={"flex"} fontSize={"16px"}>
                          {walletList[i].name}{" "}
                          {i === 0 && (
                            <Box
                              component={"div"}
                              sx={{
                                fontSize: "12px",
                                color: "#42424280",
                                display: "flex",
                                alignItems: "center",
                                marginLeft: 1,
                              }}
                            >
                              (Default)
                            </Box>
                          )}
                        </Box>
                      }
                    />
                  </Box>
                  <Box component={"div"} sx={{ color: "#518CE0", fontSize: "16px" }}>
                    {item?.address.slice(0, 8)}...{item?.address.slice(-8)}
                  </Box>
                </Box>
              </Box>
            );
          })}
          <Box component={"div"} display="flex" justifyContent="center" width={"100%"} mt={4}>
            <Button variant="contained" fullWidth onClick={createMintBatch}>
              Confirm
            </Button>
          </Box>
        </CustomModal>
        <CustomModal
          open={isShowLinkGenerate}
          onClose={() => {
            setIsShowLinkGenerate(false);
            onResetFile();
          }}
          sx={{ width: 500 }}
          showClear
        >
          <Box component={"div"} sx={{ marginTop: "-30px", marginBottom: 4 }}>
            <Box component={"div"} display="flex" alignItems={"center"}>
              <SVG color="#548574" style={{ marginRight: 10 }} src={generate}></SVG>
              <b>Link Generation</b>
            </Box>
            {generateLinkContent()}
          </Box>
        </CustomModal>
      </Grid>
    </Grid>
  );
};
export default MyNonMintedNFTs;
