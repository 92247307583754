import styled from "@emotion/styled";
import { Typography, Box, TextField, InputAdornment, IconButton, Button } from "@mui/material";
import { useState } from "react";

import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { MAXIMUM_TEXT_FIELD_LENGTH } from "../../../const";
import { validationPatterns } from "../../../validation/pattern";
import { themeColors } from "../../../theme/schemes/PureLightTheme";
import WhiteButton from "../../../components/WhiteButton";
import CustomModal from "../../../components/modal";
import Users from "../../../services/users";
import { toast } from "react-toastify";
import WarningCircle from "../../../assets/icons/warning-circle.svg";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router-dom";
import Authenticate from "../../../services/authenticate";
const PasswordVadilation = styled(Typography)({
  fontSize: "16px !important",
  mt: 2,
});

export default function ChangePassword({
  setIsChangePassword,
  verifyToken,
  setStep,
  step,
  previousLink,
}: {
  setIsChangePassword?: any;
  verifyToken?: string;
  setStep?: any;
  step?: number;
  previousLink?: any;
}) {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();

  const onConfirmChangePassword = async () => {
    if (location?.state?.previousLink) {
      try {
        const res = await Authenticate.resetPassword({
          password: newPassword,
          rePassword: confirmPassword,
          token: verifyToken,
        });
        if (res.statusCode === 200) {
          setShowModal(false);
          setStep(4);
        }
      } catch (error: any) {
        setShowModal(false);
        setErrorMsg(error.response.data.message);
        setShowModal(false);
      }
    } else {
      try {
        const res = await Users.changePassword({
          currentPassword: currentPassword,
          password: newPassword,
          rePassword: confirmPassword,
        });
        if (res.statusCode === 200) {
          setIsChangePassword(false);
          toast("Change password successfully");
        }
      } catch (error: any) {
        setErrorMsg(error.response.data.message);
        setShowModal(false);
      }
    }
  };

  return (
    <Box component={"div"}>
      <Typography sx={{ fontSize: 20, fontWeight: "600 !important" }} mb={1}>
        Change password
      </Typography>
      <PasswordVadilation sx={{ fontWeight: "bold", mt: 2 }}>Current password</PasswordVadilation>
      <TextField
        label={currentPassword === "" ? "Enter your current password" : ""}
        type={showCurrentPassword ? "text" : "password"}
        fullWidth
        sx={{
          marginTop: 1,
          "& label": {
            "&.Mui-focused": {
              color: "#B3B3B3",
            },
          },
        }}
        InputLabelProps={{ shrink: false }}
        value={currentPassword}
        onChange={(e) => {
          setCurrentPassword(e.target.value);
          setErrorMsg("");
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
      ></TextField>
      <PasswordVadilation sx={{ fontWeight: "bold", mt: 2 }}>New password</PasswordVadilation>
      <TextField
        label={newPassword === "" ? "Enter your new password" : ""}
        type={showNewPassword ? "text" : "password"}
        fullWidth
        sx={{
          marginTop: 1,
          "& label": {
            "&.Mui-focused": {
              color: "#B3B3B3",
            },
          },
        }}
        InputLabelProps={{ shrink: false }}
        value={newPassword}
        onChange={(e) => {
          setNewPassword(e.target.value);
          setErrorMsg("");
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
      ></TextField>
      <PasswordVadilation sx={{ fontWeight: "bold", mt: 2 }}>Confirm new password</PasswordVadilation>
      <TextField
        label={confirmPassword === "" ? "Re-Enter your new password" : ""}
        type={showConfirmPassword ? "text" : "password"}
        fullWidth
        sx={{
          marginTop: 1,
          "& label": {
            "&.Mui-focused": {
              color: "#B3B3B3",
            },
          },
        }}
        InputLabelProps={{ shrink: false }}
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          setErrorMsg("");
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
      ></TextField>
      {errorMsg && (
        <Typography align="center" sx={{ color: "red", fontStyle: "italic", marginTop: 2, fontSize: 14 }}>
          {errorMsg}
        </Typography>
      )}
      <Box component={"div"} marginTop={2} marginBottom={2}>
        <Box
          component={"div"}
          display="flex"
          alignItems="center"
          color={newPassword.length >= 8 ? themeColors.primary : themeColors.gray}
        >
          <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
          <PasswordVadilation>At least 8 characters.</PasswordVadilation>
        </Box>
        <Box
          component={"div"}
          display="flex"
          alignItems="center"
          color={validationPatterns.mixture.test(newPassword) ? themeColors.primary : themeColors.gray}
        >
          <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
          <PasswordVadilation>A mixture of numbers, uppercase and lowercase letters.</PasswordVadilation>
        </Box>
        <Box
          component={"div"}
          display="flex"
          alignItems="center"
          color={validationPatterns.specialCharacter.test(newPassword) ? themeColors.primary : themeColors.gray}
        >
          <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
          <PasswordVadilation>Include of at least one special character,e.g., ! @ # ? ]</PasswordVadilation>
        </Box>
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <WhiteButton
            onClick={() => {
              if (step === 3) {
                window.location.href = previousLink;
              } else {
                setIsChangePassword(false);
              }
            }}
            label="Cancel"
            width="150px"
          />
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(true);
            }}
            sx={{ width: "150px", ml: 2 }}
            disabled={
              !validationPatterns.specialCharacter.test(newPassword) ||
              !validationPatterns.mixture.test(newPassword) ||
              newPassword.length < 8 ||
              newPassword === "" ||
              confirmPassword === "" ||
              currentPassword === ""
            }
          >
            Next
          </Button>
        </Box>
      </Box>
      <CustomModal sx={{ maxWidth: "608px" }} open={showModal} onClose={() => setShowModal(false)}>
        <Box component={"div"} sx={{ textAlign: "center" }}>
          <SVG src={WarningCircle} style={{ marginRight: "5px" }} />
        </Box>
        <Typography variant="h3">Confirm change password</Typography>
        <Typography variant="body1" mt={2}>
          Are you sure you want to change your password?{" "}
        </Typography>
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              setShowModal(false);
            }}
            sx={{ width: "150px", mr: 2 }}
          >
            Cancel
          </Button>
          <WhiteButton onClick={onConfirmChangePassword} label="Confirm" width="150px" />
        </Box>
      </CustomModal>
    </Box>
  );
}
