/* eslint-disable react/no-unescaped-entities */
import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import CustomTextField from "../../components/CustomTextField";
import WhiteButton from "../../components/WhiteButton";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomModal from "../../components/modal";
import Users from "../../services/users";
import { ActiveStatus, DeactivatedStatus, PendingStatus, RejectedStatus } from "../../components/status";
import { ApproveStatus } from "../../const";
import defaultImage from "../../assets/images/image 2.png";
import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import { PhoneNumberInput } from "../../components/phoneNumberInput";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import FileIcon from "../../assets/icons/nft-detail/file.svg";

const styles = makeStyles(() => ({
  placeholder: {
    color: "#aaa",
  },
  textarea: {
    "& .MuiInputBase-root": {
      borderRadius: "16px !important",
    },
  },
  select: {
    "&.MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#E0E0E0 !important",
    },
  },
  businessLicense: {
    cursor: "pointer",
  },
}));

const CorporateUserDetails = () => {
  const location = useLocation();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [businessLicense, setbusinessLicense] = useState<any>([]);
  const classes = styles();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState({
    status: "",
    actionRequired: "",
  });
  const [phoneNumber, setPhoneNumber] = useState<any>("");

  const Placeholder = ({ children }: any) => {
    return <div className={classes.placeholder}>{children}</div>;
  };

  const phoneNumberChange = (telephone?: string) => {
    setPhoneNumber(telephone);
  };

  useEffect(() => {
    if (location?.state?.currentUser) {
      setUserDetails(location?.state?.currentUser);
      setPhoneNumber(`${location?.state?.currentUser?.dialCode} ${location?.state?.currentUser?.phone}`);
    }
  }, [location]);
  const getUserDetail = async () => {
    const res = await Users.getUserDetail({ userId: location?.state?.currentUser?.id });
    if (res.statusCode === 200) {
      setbusinessLicense(res?.data?.corporate?.docs);
    }
  };
  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHandleApprove = async () => {
    try {
      const res = await Users.changeCorporateStatus({ userId: userDetails.id, corporateStatus: account.status });
      if (res.statusCode === 200) {
        setShowModal(false);
        setIsEdit(true);
        await getUserStatus();
        toast("Update profile successfuly");
      }
    } catch (error: any) {
      setShowModal(false);
      toast(error.response.data.message);
    }
  };

  const getUserStatus = async () => {
    const res = await Users.getUserStatus({ id: location?.state?.currentUser?.id });
    setUserDetails({ ...userDetails, corporateStatus: res.data.corporateStatus });
  };

  const renderStatus = (e: string) => {
    if (e === ApproveStatus.Approved) {
      return <ActiveStatus />;
    }
    if (e === ApproveStatus.Pending) {
      return <PendingStatus />;
    }
    if (e === ApproveStatus.Rejected) {
      return <RejectedStatus />;
    }
    return <DeactivatedStatus />;
  };

  const onCancelClick = () => {
    setIsEdit(true);
    setPhoneNumber(`${location?.state?.currentUser?.dialCode} ${location?.state?.currentUser?.phone}`);
    getUserStatus();
  };

  return (
    <Box component={"div"}>
      <Box component={"div"} marginTop={4}>
        <Grid container>
          <Grid item xs={4}>
            <Box component={"div"} display="flex" alignItems="center" flexDirection="column">
              <img
                src={userDetails?.coverImage ? userDetails?.coverImage : defaultImage}
                alt=""
                style={{ borderRadius: 60, height: "120px", width: "120px" }}
              />
              <h2>
                {userDetails?.firstName} {userDetails?.lastName}
              </h2>
              <span style={{ marginTop: "5px", marginBottom: "5px" }}>{userDetails?.corporate?.name}</span>
              {renderStatus(userDetails?.corporateStatus)}
            </Box>
          </Grid>
          <Grid item xs={5}>
            <h2>Update Information</h2>
            <CustomTextField label="ID" value={userDetails?.id} placeholder="Enter your id" disabled />
            <CustomTextField
              label="Name"
              value={`${userDetails?.firstName} ${userDetails?.lastName}`}
              placeholder="Enter your name"
              disabled
            />
            <CustomTextField
              label="Company"
              value={userDetails?.corporate?.name}
              placeholder="Enter your company"
              disabled
            />
            <CustomTextField
              label="Position"
              value={userDetails?.position}
              placeholder="Enter your position"
              disabled
            />

            <PhoneNumberInput
              id="phoneInput"
              onChange={(telephone) => {
                phoneNumberChange(telephone);
              }}
              value={phoneNumber}
              disabled={isEdit}
            />
            <Typography sx={{ fontWeight: "bold" }}>
              <Box component={"div"} display="flex">
                Business licenses
              </Box>
            </Typography>
            {businessLicense.length > 0 &&
              businessLicense.map((doc: any, index: number) => {
                return (
                  <Box
                    component={"div"}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    key={index}
                    className={classes.businessLicense}
                  >
                    <Box component={"div"} display="flex" columnGap="5px">
                      <SVG src={FileIcon} />
                      <a href={doc.link} target="_blank" rel="noreferrer" style={{ color: "black" }}>
                        {doc.name}
                      </a>
                    </Box>
                  </Box>
                );
              })}
            <Typography sx={{ fontWeight: "bold" }} mt={1}>
              Status &nbsp;
            </Typography>
            <Box component={"div"} mb={2}>
              <FormControl fullWidth>
                <Select
                  className={classes.select}
                  disabled={isEdit}
                  value={
                    userDetails?.corporateStatus === ApproveStatus.Pending
                      ? account.status
                      : userDetails?.corporateStatus
                  }
                  onChange={(e) => setAccount({ ...account, status: e.target.value })}
                  sx={{
                    marginTop: 1,
                    borderRadius: 20,
                  }}
                  displayEmpty
                  renderValue={
                    account.status !== ""
                      ? undefined
                      : () => {
                          return userDetails?.corporateStatus !== ApproveStatus.Pending ? (
                            <Placeholder>
                              {userDetails?.corporateStatus.charAt(0).toUpperCase() +
                                userDetails?.corporateStatus.slice(1)}{" "}
                            </Placeholder>
                          ) : (
                            <Placeholder>Pending Review</Placeholder>
                          );
                        }
                  }
                >
                  <MenuItem value={ApproveStatus.Approved}>Approved</MenuItem>
                  <MenuItem value={ApproveStatus.Rejected}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {account.status === ApproveStatus.Rejected && (
              <CustomTextField
                className={classes.textarea}
                label="Action required"
                value={account.actionRequired}
                placeholder=" Please provide action required for the corporate user to get approved!"
                onChange={(e: string) => setAccount({ ...account, actionRequired: e })}
                multiline
                rows={4}
              />
            )}
            {isEdit ? (
              <Box component={"div"} display="flex" justifyContent="center" mt={2}>
                <WhiteButton
                  label="Back"
                  onClick={() => {
                    setUserDetails && setUserDetails(null);
                    navigate(links.LIST_USER);
                  }}
                  width="140px"
                />
                <Button
                  variant="contained"
                  onClick={() => setIsEdit(false)}
                  sx={{ width: "140px", marginLeft: 2 }}
                  disabled={userDetails?.deletedAt !== null}
                >
                  Edit
                </Button>
              </Box>
            ) : (
              <Box component={"div"} display="flex" justifyContent="center" mt={2}>
                <WhiteButton label="Cancel" onClick={onCancelClick} width="140px" />
                <Button
                  variant="contained"
                  onClick={() => setShowModal(true)}
                  sx={{ width: "140px", marginLeft: 2 }}
                  disabled={account.status === ApproveStatus.Rejected && account.actionRequired === ""}
                >
                  Save
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <Box component={"div"}>
          <Typography align="center" variant="h3">
            {account.status === ApproveStatus.Approved ? "Approve profile" : "Reject profile"}
          </Typography>
          {account.status === ApproveStatus.Approved ? (
            <>
              <Typography align="center" variant="body2" marginTop={3}>
                Approved corporate users will receive an email notification and could
              </Typography>
              <Typography align="center" variant="body2">
                start using corporate’s features.
              </Typography>
              <Typography align="center" variant="body2">
                Are you sure you want to approve this corporate user's profile?
              </Typography>
            </>
          ) : (
            <>
              <Typography align="center" variant="body2" marginTop={3}>
                Rejected corporate users will receive an email notification.
              </Typography>
              <Typography align="center" variant="body2">
                Are you sure you want to reject this corporate user’s profile?
              </Typography>
            </>
          )}
        </Box>
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <WhiteButton
            label="Cancel"
            onClick={() => {
              setShowModal(false);
            }}
            width="140px"
          />

          <Button variant="contained" onClick={onHandleApprove} sx={{ width: "140px", marginLeft: 2 }}>
            {account.status === ApproveStatus.Approved ? "Approve" : "Reject"}
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};
export default CorporateUserDetails;
