import axios from "axios";
import { localStorageHelper } from "../helpers";
import axiosClient from "./axiosClient";

const TokenApi = {
  getTokenBalance(): Promise<any> {
    const url = "/token/balance";
    return axiosClient.get(url);
  },

  refreshToken({ filter }: any): Promise<any> {
    const url = `${process.env.REACT_APP_BASE_URL}user/refresh-token`;
    return axios.post(
      url,
      { filter },
      {
        headers: {
          refresh_token: localStorageHelper.getItem(process.env.REACT_APP_REFRESH_TOKEN!),
          Authorization: `Bearer ${localStorageHelper.getItem(
            process.env.REACT_APP_ACCESS_TOKEN_KEY!,
          )}`,
        },
      },
    );
  },
};
export default TokenApi;
