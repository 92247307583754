import axiosClient from "../../api/axiosClient";

const Authenticate = {
  sendVerify(params: any): Promise<any> {
    const url = "/auth/send-verify";
    return axiosClient.post(url, { ...params, type: "web" });
  },
  resendVerify(params: any): Promise<any> {
    const url = "/auth/resend-verify";
    return axiosClient.put(url, params);
  },
  verifyToken(params: any): Promise<any> {
    const url = "/auth/verify-token";
    return axiosClient.post(url, params);
  },
  initInfo(params: any): Promise<any> {
    const url = "/auth/init-info";
    return axiosClient.put(url, params);
  },
  createPassword(params: any): Promise<any> {
    const url = "/auth/create-password";
    return axiosClient.post(url, params);
  },
  login(params: any): Promise<any> {
    const url = "/auth/login";
    return axiosClient.post(url, { ...params, isWeb: true });
  },
  forgotPassword(params: any): Promise<any> {
    const url = "/auth/forgot-password";
    return axiosClient.post(url, params);
  },
  verifyCode(params: any): Promise<any> {
    const url = "/auth/verify-code";
    return axiosClient.post(url, params);
  },
  resetPassword(params: any): Promise<any> {
    const url = "/auth/reset-password";
    return axiosClient.post(url, params);
  },
};

export default Authenticate;
