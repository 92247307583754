import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import _ from "lodash";
import { useEffect, useState } from "react";

const StyledListAnswerWords = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;
const StyledItemAnswerWord = styled.div`
  width: 105px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: 600;
  color: #616161;
  padding-left: 10px;
  &.selected {
    cursor: pointer;
  }
`;
const StyledListQuestionWords = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;
const StyledItemQuestionWord = styled.div`
  width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #616161;
  cursor: pointer;
  &.selected {
    color: #ccc;
    cursor: default;
  }
`;

interface IProps {
  status: boolean;
  setWidthModal: React.Dispatch<React.SetStateAction<number>>;
  handleContinue: (seedPhrase: string[], id: string) => Promise<void>;
  seedPhraseInfo: any;
}

const RecoveryFillData = ({ status, setWidthModal, handleContinue, seedPhraseInfo }: IProps) => {
  const { seed_phrase: seedPhrase = [] } = seedPhraseInfo;

  const [answerWords, setAnswerWords] = useState(
    Array(seedPhrase.length).fill({ label: "", value: "", isSelected: false }),
  );
  const [questionWords, setQuestionWords] = useState(
    _.shuffle(seedPhrase.map((word: string) => ({ label: word, value: word, isSelected: false }))),
  );

  useEffect(() => {
    if (status) {
      setWidthModal(1100);
    }
  }, [setWidthModal, status]);

  useEffect(() => {
    if (status) {
      const resetQuestionWords = questionWords.map((word) => ({
        ...word,
        isSelected: false,
      }));
      setQuestionWords(resetQuestionWords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleClickQuestionWord = (word: any) => {
    if (word.isSelected) return;

    const newQuestions = [...questionWords];
    const objIndex = newQuestions.findIndex((item) => item.value === word.value);
    newQuestions[objIndex].isSelected = true;
    setQuestionWords(newQuestions);

    const newAnswers = [...answerWords];
    const position = newAnswers.findIndex((item) => item.isSelected === false);
    const newWords = { label: word.label, value: word.label, isSelected: true };
    newAnswers[position] = newWords;
    setAnswerWords(newAnswers);
  };

  const handleClickAnswerWord = (word: any) => {
    if (!word.isSelected) return;

    const newAnswers = [...answerWords];
    const objIndex = newAnswers.findIndex((item) => item.value === word.value);
    const newWords = { label: "", value: "", isSelected: false };
    newAnswers[objIndex] = newWords;
    setAnswerWords(newAnswers);

    const newQuestions = [...questionWords];
    const position = newQuestions.findIndex((item) => item.value === word.value);
    newQuestions[position].isSelected = false;
    setQuestionWords(newQuestions);
  };

  return (
    <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
      <Typography variant="h3" sx={{ mb: 1 }}>
        Recorvery Phrase
      </Typography>
      <br />
      <Typography sx={{ mb: 3 }}>Click each word in the correct order to verify your recovery phrase</Typography>
      <StyledListAnswerWords>
        {Array.isArray(answerWords) &&
          answerWords.length > 0 &&
          answerWords.map((word, index) => (
            <StyledItemAnswerWord
              key={Math.random()}
              onClick={() => handleClickAnswerWord(word)}
              className={word.isSelected ? "selected" : ""}
            >
              {`${index + 1}. ${word.label}`}
            </StyledItemAnswerWord>
          ))}
      </StyledListAnswerWords>
      <StyledListQuestionWords>
        {Array.isArray(questionWords) &&
          questionWords.length > 0 &&
          questionWords.map((word) => (
            <StyledItemQuestionWord
              key={word.value + word.isSelected}
              onClick={() => handleClickQuestionWord(word)}
              className={word.isSelected ? "selected" : ""}
            >
              {word.label}
            </StyledItemQuestionWord>
          ))}
      </StyledListQuestionWords>
      <Button
        variant="contained"
        sx={{ width: 160 }}
        disabled={answerWords.filter((word) => word.isSelected).length < answerWords.length}
        onClick={() => handleContinue(answerWords, seedPhraseInfo.id)}
      >
        Continue
      </Button>
    </Box>
  );
};

export default RecoveryFillData;
