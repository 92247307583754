import * as React from "react";
import Box from "@mui/material/Box";
import { NFT3Drender } from "../../utils/nft3dRender";
import Items from "../../services/items";
import styled from "styled-components";

const StyledDetailEventItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export default function Preview3dNFT() {
  const [image3d, setImage3d] = React.useState("");
  const get3dLink = async () => {
    const res = await Items.getNFTImage(window.location.href.split("/").pop());
    if (res.statusCode === 200) {
      setImage3d(res.data);
    }
  };
  React.useEffect(() => {
    get3dLink();
  }, []);
  return (
    <Box component={"div"} height="100vh">
      { image3d && (
        image3d.includes(".mp4") ? (
          <Box component={"div"}>
            <StyledDetailEventItem>
              <video controls autoPlay style={{ maxWidth: "100%" }}>
                <source src={image3d} type="video/mp4" />
              </video>
            </StyledDetailEventItem>
          </Box>
        ) : (
          NFT3Drender({
            // eslint-disable-next-line max-len
            link: image3d,
          })
        )
      )}
    </Box>
  );
}
