import { Box, Typography, TextField, Button, Tooltip } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import styledC from "styled-components";
import uploadImage from "../../assets/icons/default-pic-1.svg";
import uploadBtnImage from "../../assets/icons/upload-btn-img.svg";
import paperclip from "../../assets/icons/paperclip.svg";
import FileIcon from "../../assets/icons/nft-detail/file.svg";
import upload from "../../assets/icons/updoad.svg";
import remove3D from "../../assets/icons/spin.svg";
import camera from "../../assets/icons/camera-1.svg";
import colors from "../../theme/colors";
import SVG from "react-inlinesvg";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import { IAttributeResponse, IDataInfo, TypeAttribute } from ".";
import WhiteButton from "../../components/WhiteButton";
import { MAXIMUM_TEXT_FIELD_LENGTH, MintStatus } from "../../const";
import { Loading } from "../../components";
import ErrorMessage from "../../components/ErrorMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import moment from "moment";
import Users from "../../services/users";
import { NFT3Drender } from "../../utils/nft3dRender";
import { isNFTModelType, MAX_NFT_IMAGE_SIZE, nftModalType, uploadFileSize } from "../../utils/utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import _ from "lodash";
export interface INftInfoProps {
  handleNext: () => void;
  infoAttribute: IDataInfo | null;
  setFileUpload: React.Dispatch<
    React.SetStateAction<{
      file: null;
      imagePreviewUrl: string;
    }>
  >;
  fileUpload: {
    file: null;
    imagePreviewUrl: string;
  };
  file3DUpload?: any;
  setFile3DUpload?: any;
  setInfoFieldsConfig: React.Dispatch<any>;
  setDocsConfig: React.Dispatch<React.SetStateAction<IDocConfig[] | null>>;
  infoFieldsConfig: any;
  docsConfig: IDocConfig[] | null;
  handleMintData: (type: MintStatus, payloadMint?: any) => Promise<unknown>;
  stepLoading: boolean;
  mintedFailError: any;
  isEdit: boolean;
}

export interface IDocConfig {
  name: string;
  isRequired: boolean;
  file: string;
  id: string;
  error: string;
  [k: string]: any;
}

const Image = styled(Box)(() => ({
  background: "transparent",
  textAlign: "right",
  width: "70%",
  height: "136px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "1.2rem",
  cursor: "pointer",
  "& img": {
    borderRadius: "1.2rem",
  },
}));

const Info = styled(Box)(() => ({
  border: `1px solid ${colors.lighterGray1}`,
  borderRadius: "1.2rem",
  padding: "1rem",
  paddingRight: "3rem",
}));

const LineBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "1.2rem",

  "& :first-of-type": {
    minWidth: "11rem",
  },
}));

const StyledDetailEventItem = styledC.div`
  border-bottom: 1px solid ${themeColors.lighterGray};
  padding: 15px 0;
  font-weight: bold;
  font-size: 16px;
  color: ${themeColors.black};
  a {
    font-weight: bold;
    font-size: 16px;
    color: ${themeColors.black};
  }
`;

const NftInfo: React.FunctionComponent<INftInfoProps> = ({
  handleNext,
  infoAttribute,
  fileUpload,
  setFileUpload,
  file3DUpload,
  setFile3DUpload,
  setInfoFieldsConfig,
  setDocsConfig,
  infoFieldsConfig,
  docsConfig,
  handleMintData,
  stepLoading,
  mintedFailError,
  ...props
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [currentUser, setCurrentUser] = useState<any>();
  const [fileSizeError, setFileSizeError] = useState(false);
  const inputFileRef = useRef(null);
  const input3DFileRef = useRef<any>(null);
  const defaultInfo: IAttributeResponse[] = useMemo(
    () => [
      {
        label: "Name your NFT",
        name: "Name",
        value: (infoAttribute?.item as any)?.name || "",
        isRequired: true,
        placeholder: "name your NFT",
        type: TypeAttribute.INFO,
      },
      {
        label: "Description",
        name: "Description",
        value: (infoAttribute?.item as any)?.description || "",
        isRequired: true,
        placeholder: "NFT description",
        type: TypeAttribute.INFO,
      },
    ],
    [infoAttribute],
  );
  const handleChangeImage = (e: any) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file || file.size > MAX_NFT_IMAGE_SIZE) {
      return;
    }
    reader.onloadend = () => {
      setFileUpload({
        file: file,
        imagePreviewUrl: reader.result as string,
      });
    };

    reader.readAsDataURL(file);
  };
  const handleChange3DImage = async (e: any) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    if (!uploadFileSize(file.size) || !isNFTModelType(e.target.value) && !e.target.value.includes(".mp4")) {
      setFileSizeError(true);
      return;
    }
    setFileSizeError(false);
    setLoading(true);
    const res = await Users.uploadFiles({ file: file });
    if (res.statusCode === 200) {
      setFile3DUpload({ ...file3DUpload, link: res.data[0].link });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (state?.isEdit) {
      setFile3DUpload({ ...file3DUpload, link: state?.item?.item?.image3d });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.isEdit]);

  const onUploadImageClick = () => {
    /*Collecting node-element and performing click*/
    (inputFileRef as any)?.current?.click();
  };
  const onUpload3DFileClick = () => {
    /*Collecting node-element and performing click*/
    (input3DFileRef as any)?.current?.click();
  };
  const renderImage = () => {
    return (
      <Box
        component={"div"}
        sx={{
          width: "100%",
          height: "100%",
          cursor: "pointer",
          display: "flex",
          alignItems: "end",
          position: "relative",
        }}
      >
        <Image onClick={() => onUploadImageClick()}>
          <input
            type="file"
            style={{
              width: "100%",
              height: "0",
              opacity: "0",
              // position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              cursor: "pointer",
            }}
            onChange={handleChangeImage}
            accept="image/*"
            ref={inputFileRef}
          />
          {fileUpload.file || (infoAttribute?.item as any)?.coverImage ? (
            <Box component={"div"} sx={{ width: "auto", height: "100%" }}>
              <img
                src={fileUpload.imagePreviewUrl || (infoAttribute?.item as any)?.coverImage}
                style={{ width: "auto", height: "100%" }}
              />
            </Box>
          ) : (
            <Box
              component={"div"}
              sx={{
                display: "flex",
                backgroundColor: colors.darkGreen,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
              }}
            >
              <SVG style={{ height: "56px" }} src={uploadImage}></SVG>
              <Box
                component={"div"}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: 2,
                }}
              >
                <Typography variant="body1" color="white">
                  Click to upload NFT image
                </Typography>
                <Typography variant="body2" sx={{ color: colors.lighterGray1 }}>
                  Maximum 10MB
                </Typography>
              </Box>
            </Box>
          )}
        </Image>

        {(fileUpload?.file || (infoAttribute?.item as any)?.coverImage) && (
          <Button
            variant="contained"
            sx={{
              width: "160px",
              height: "48px",
              fontSize: "15px",
              marginLeft: 2,
            }}
            onClick={() => onUploadImageClick()}
          >
            <SVG src={camera} style={{ marginRight: "5px" }} />
            Change
          </Button>
        )}
      </Box>
    );
  };

  const render3DView = () => {
    return (
      <Box component={"div"} display={"flex"} alignItems={"center"}>
        <input
          id="file-3d"
          type="file"
          accept={nftModalType.toString()}
          style={{
            width: "0",
            height: "0",
            opacity: "0",
            cursor: "pointer",
          }}
          onChange={handleChange3DImage}
          ref={input3DFileRef}
        />
        {!file3DUpload.file && !file3DUpload.link && (
          <>
            {" "}
            <TextField
              {...props}
              fullWidth
              sx={{
                width: "150%",
                "& label": {
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
              }}
              placeholder="Enter 3D link, video link"
              InputLabelProps={{ shrink: false }}
              onBlur={(e) => {
                setFile3DUpload({
                  ...file3DUpload,
                  link: isNFTModelType(e.target.value) || e.target.value.includes(".mp4") ? e.target.value : "",
                });
              }}
              inputProps={{ style: { fontSize: 16 } }}
              defaultValue={file3DUpload.link ?? ""}
            ></TextField>
            <Box component={"div"} mx={2} color={"gray"}>
              Or
            </Box>
            <Button
              variant="contained"
              sx={{ width: "140px", height: "48px", fontSize: "15px" }}
              onClick={() => {
                onUpload3DFileClick();
              }}
            >
              <SVG src={uploadBtnImage} style={{ marginRight: "5px" }} />
              Upload
            </Button>
          </>
        )}
        <Box component={"div"} display={"flex"} alignItems="end" width={"100%"} justifyContent="space-between">
          {file3DUpload.link && (
            <>
              <Box component={"div"} maxWidth={"80%"}>
                {isNFTModelType(file3DUpload.link) ? (
                  NFT3Drender({
                    link: file3DUpload.link,
                    isAutoRorate: true,
                  })
                ) : (
                  <Box component={"div"}>
                    <video controls autoPlay style={{ width: "100%" }}>
                      <source src={file3DUpload.link} type="video/mp4" />
                    </video>
                  </Box>
                )}
              </Box>

              <Button
                variant="contained"
                sx={{
                  width: "160px",
                  height: "48px",
                  fontSize: "15px",
                  marginLeft: 2,
                }}
                onClick={() => {
                  setFile3DUpload({ link: "", file: null });
                  const file3d = document.getElementById("file-3d") as HTMLInputElement;
                  if (file3d) {
                    file3d.value = "";
                  }
                }}
              >
                <SVG src={remove3D} style={{ marginRight: "5px" }} />
                Remove
              </Button>
            </>
          )}
        </Box>
      </Box>
    );
  };
  const handleChangeDocs = (e: any, index: number) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      if (docsConfig) {
        const newDocsConfig = [...docsConfig];
        newDocsConfig[index].file = file;
        setDocsConfig(newDocsConfig);
      }
    };

    reader.readAsDataURL(file);
  };

  const getCurrentUser = async () => {
    const res = await Users.getCurrentUser();

    if (res.statusCode === 200) {
      setCurrentUser(res?.data);
    }
  };
  useEffect(() => {
    getCurrentUser();
  }, []);

  const renderInfo = () => {
    return (
      <Box component={"div"}>
        <Info>
          <LineBox id="policy_id">
            <Typography variant="h4">Policy ID</Typography>
            <Typography variant="subtitle2">
              {(infoAttribute?.item as any)?.item?.policyId || "Will be generated after submitting the request"}
            </Typography>
          </LineBox>
          <LineBox id="owner">
            <Typography variant="h4">Owner</Typography>
            <Box component={"div"} flexDirection="column">
              <Box component={"div"}>
                <>
                  <Typography variant="subtitle2">
                    {currentUser?.firstName} {currentUser?.lastName}{" "}
                  </Typography>
                </>
              </Box>
            </Box>
          </LineBox>

          <LineBox id="date">
            <Typography variant="h4">Created date</Typography>
            <Typography variant="subtitle2">{moment().format("MM/DD/YYYY")}</Typography>
          </LineBox>
          <LineBox id="brand">
            <Typography variant="h4">Brand</Typography>
            <Typography variant="subtitle2">{currentUser?.corporate?.name}</Typography>
          </LineBox>
        </Info>
      </Box>
    );
  };

  const handleInputChange = (name: string, value: any) => {
    const newInputFields = infoFieldsConfig?.map((item: any) => {
      if (item.name === name) {
        if (!value) {
          return { ...item, value, error: "The field is required" };
        }
        return { ...item, value, error: "" };
      } else return item;
    });

    setInfoFieldsConfig(newInputFields);
  };

  const handleNextStep = (docs: IDocConfig[], info: IAttributeResponse[]) => {
    if (!docs || !info) return true;
    let isDocsDone;
    let isInforDone: any[] = [];

    docs.forEach((item) => {
      if (!item.value) {
        if (item.isRequired) {
          if (!item.file) {
            isDocsDone = false;
            return;
          }
          isDocsDone = true;
        }
        isDocsDone = true;
        return true;
      } else {
        isDocsDone = true;
      }
    });

    info?.map((x: any) => {
      if (x?.isRequired) {
        isInforDone?.push(x);
      }
    });
    const filterInforDone = !isInforDone?.some((x: any) => !x?.value);

    return isDocsDone && filterInforDone && (fileUpload.file || (infoAttribute?.item as any)?.coverImage);
  };

  const handleSaveDraft = () => {
    handleMintData(MintStatus.InDraft).then(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(links.MY_NFTS, { state: { nftTab: 2 } });
      }, 2000);
    });
  };
  useEffect(() => {
    const infoConfig =
      infoAttribute?.info &&
      [...defaultInfo, ...infoAttribute.info]?.map((field) => {
        return {
          ...field,
          label: field.label ?? field.name,
          placeholder: `Enter ${field.placeholder ?? field.name} `,
          name: field.name.trim().split(" ").join("").toLowerCase(),
          isRequired: field.isRequired,
          value: field.name === "Date of purchasing" ? field.value || moment(new Date()) : field.value || "",
          error: "",
        };
      });

    const tempDocsConfig = infoAttribute?.docs?.map((field) => {
      return {
        ...field,
        name: field.name,
        isRequired: field.isRequired,
        file: "",
        id: field.id,
        error: "",
      };
    });
    setDocsConfig(tempDocsConfig as any);

    setInfoFieldsConfig(infoConfig);

    if (infoFieldsConfig?.some((x: any) => x?.value)) {
      setInfoFieldsConfig(infoFieldsConfig);
    }

    if (docsConfig?.some((x: any) => x?.file)) {
      let docDiff = _.differenceWith(docsConfig, tempDocsConfig as any, function (o1: any, o2: any) {
        return o1["id"] === o2["id"];
      });

      let newDoc: any[] = [];
      newDoc = (docsConfig as any)?.concat(docDiff);
      setDocsConfig(newDoc);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultInfo, infoAttribute, setDocsConfig, setInfoFieldsConfig]);
  const renderInputInfo = () => {
    return (
      infoFieldsConfig &&
      infoFieldsConfig.map((field: IAttributeResponse, index: number) => {
        return (
          <Box component={"div"} key={`${index}${field.label}`}>
            <Typography variant="h4" sx={{ color: themeColors.black, textAlign: "left" }}>
              {field.label} {field.isRequired && <span style={{ color: themeColors.error }}>*</span>}
            </Typography>

            {field.label === "Date of purchasing" ? (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ width: "100%", marginBottom: 3 }}
                    onChange={(e: any) => handleInputChange(field.name, moment(e).format("MM-DD-YYYY"))}
                    defaultValue={field?.value ? moment(field?.value) : moment(new Date())}
                  />
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <TextField
                {...props}
                placeholder={field.placeholder}
                fullWidth
                error={field.isRequired && !!field.error}
                helperText={field.isRequired ? field.error : ""}
                sx={{
                  marginTop: 1,
                  marginBottom: 3,
                  width: "100%",
                  "& label": {
                    "&.Mui-focused": {
                      color: "#B3B3B3",
                    },
                  },
                }}
                InputLabelProps={{ shrink: false }}
                value={field.value}
                onChange={(e) => handleInputChange(field.name, e.target.value)}
                inputProps={{
                  style: { fontSize: 16 },
                  maxLength: field.name !== "description" ? MAXIMUM_TEXT_FIELD_LENGTH : 1000,
                }}
              ></TextField>
            )}
          </Box>
        );
      })
    );
  };
  const renderDocument = () => {
    return (
      <Box component={"div"} width="100%">
        <Typography variant="h4" sx={{ color: themeColors.black, textAlign: "left" }}>
          Docs
        </Typography>
        {docsConfig?.map((field, index) => {
          return (
            <StyledDetailEventItem key={index}>
              <Box component={"div"} display="flex" justifyContent="space-between" alignItems="center">
                <Box component={"div"} display="flex" alignItems="center" columnGap="5px">
                  <SVG src={FileIcon} />
                  <a href={field.value} target="_blank" rel="noreferrer">
                    {field.name}{" "}
                    {field.isRequired && (
                      <Typography component={"span"} sx={{ color: "red" }}>
                        *
                      </Typography>
                    )}
                  </a>
                </Box>
                <Box component={"div"}>
                  <Tooltip title={field.isRequired ? "Required" : "Optional"} placement="top-end">
                    <Button sx={{ position: "relative", cursor: "pointer" }}>
                      <input
                        type="file"
                        style={{
                          width: "100%",
                          height: "100%",
                          opacity: "0",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 10,
                          cursor: "pointer",
                        }}
                        onChange={(e) => handleChangeDocs(e, index)}
                        accept="*"
                      />
                      <SVG style={{ cursor: "pointer" }} src={field?.file || field?.value ? paperclip : upload}></SVG>
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </StyledDetailEventItem>
          );
        })}
      </Box>
    );
  };

  return (
    <Box component={"div"} sx={{ width: "80%" }}>
      {loading && <Loading />}
      {stepLoading && <Loading />}
      <Typography variant="h3" sx={{ mb: 1 }}>
        NFT information
      </Typography>
      <Box component={"div"} display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
        <Box component={"div"} gridColumn="span 5">
          <Box component={"div"} display="flex" gap={2} sx={{ flexDirection: "column" }}>
            <Typography variant="h4" sx={{ color: themeColors.black, textAlign: "left" }}>
              Upload Preview Image
            </Typography>
            {renderImage()}
            <Typography variant="h4" sx={{ color: themeColors.black, textAlign: "left" }}>
              Upload 3D view
            </Typography>

            {render3DView()}
            {fileSizeError && (
              <Typography variant="h4" sx={{ color: themeColors.error }}>
                Wrong file format or the file size exceeds 20 MB
              </Typography>
            )}
            {renderInfo()}
          </Box>
        </Box>
        <Box component={"div"} gridColumn="span 7">
          <Box component={"div"}>{renderInputInfo()}</Box>
        </Box>
        <Box component={"div"} gridColumn="span 12">
          <Box component={"div"} width="100%">
            {renderDocument()}
          </Box>
        </Box>
        <Box component={"div"} gridColumn="span 12">
          <ErrorMessage msg={mintedFailError} />
          <Box component={"div"} display="flex" justifyContent="center" gap={2}>
            {/* SAVE AS DRAFT */}
            <WhiteButton width={160} onClick={handleSaveDraft} label="Save as Draft"></WhiteButton>
            <Button
              variant="contained"
              sx={{ width: 160 }}
              disabled={!handleNextStep(docsConfig as IDocConfig[], infoFieldsConfig)}
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NftInfo;
