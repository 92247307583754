/* eslint-disable react/no-unknown-property */
import { OrbitControls, useGLTF, Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

export const NFT3Drender = ({ link, isAutoRorate = true }: { link: string; isAutoRorate?: boolean; file?: any }) => {
  const { scene } = useGLTF(link);
  useGLTF.preload(
    "https://cdn.jsdelivr.net/gh/Sean-Bradley/React-Three-Fiber-Boilerplate@glTFLoader/public/models/monkey.glb",
  );
  // eslint-disable-next-line no-console
  console.log("isAutoRorate", isAutoRorate);
  // eslint-disable-next-line react/no-unknown-property
  return (
    <Canvas style={{ height: "100vh" }} camera={{ position: [0, 0, 1.25], fov: 15 }}>
      <ambientLight />
      <Environment preset="city" />
      <mesh>
        <boxBufferGeometry args={[10, 10, 10]} />
      </mesh>
      <primitive object={scene} />

      <OrbitControls />
    </Canvas>
  );
};
