import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { WalletDetailsTabs } from "../../../../const";
import OffersItems from "../../../../components/OfferItem";
import Offers from "../../../../services/offers";
import CustomRangePagination from "../../../../components/RangePagination";
import { useWalletContext } from "../../../../contexts/wallet-provider";

const ReceievedOffers = () => {
  const [page, setPage] = React.useState(0);
  const [meta, setMeta] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [listOffer, setListOffer] = useState<any>([]);
  const location = useLocation();
  const { selectedWallet } = useWalletContext();
  const { address } = location?.state?.walletDetails ?? selectedWallet.address;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const getListOffer = async () => {
    const res = await Offers.getListOffer({
      page: page,
      limit: rowsPerPage,
      offerType: "received",
      walletAddress: address ?? selectedWallet.address,
    });
    if (res.statusCode === 200) {
      setListOffer(res.data.items);
      setMeta(res.data.meta);
    }
  };

  useEffect(() => {
    getListOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <CustomRangePagination
          meta={meta}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          onPageChange={handleChangePage}
        />
        <Box component={"div"} display="flex" flexWrap={"wrap"}>
          {listOffer.map((item: any, index: number) => {
            return (
              <OffersItems
                key={index}
                itemDetails={item}
                mintTab={WalletDetailsTabs.offers}
                getListOffer={getListOffer}
                type="received"
              />
            );
          })}
        </Box>
        {listOffer.length > 0 && (
          <CustomRangePagination
            meta={meta}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
            onPageChange={handleChangePage}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default ReceievedOffers;
