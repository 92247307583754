import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loading, ErrorFallback } from "./components";
import { AppRoutes } from "./routes";
import { ToastContainer } from "react-toastify";

import "react-dropzone-uploader/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import "./styles/app.scss";

function App(): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      {/*loading action when api error*/}
      {/*<ErrorBoundary FallbackComponent={ErrorFallback}>{loading ? <Loading /> : <AppRoutes />}</ErrorBoundary>*/}
      {/* @ts-ignore */}
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} theme="dark" />
      {/* <ErrorFallback error={error1} resetErrorBoundary={() => {}} /> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>{<AppRoutes />}</ErrorBoundary>
    </Suspense>
  );
}

export default App;
