import { Typography, Box } from "@mui/material";
import SVG from "react-inlinesvg";
import emptyBox from "../../assets/images/GroupBox.svg";

export default function NoItems() {
  return (
    <>
      <Box component={"div"} display={"flex"} justifyContent="center" marginTop={"15%"}>
        <SVG src={emptyBox} />
      </Box>
      <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
        No items match your filters.
      </Typography>
      <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
        Try another search!
      </Typography>
    </>
  );
}
