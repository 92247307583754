/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page404 } from "../pages/page-404";
import { configRoutes } from "./config";
import { RestrictedRoute } from "./restricted-route";

export const AppRoutes: React.FC = () => {
  const mappedRoutes = configRoutes.map(({ appRoutes, isPrivate, layout: MainLayout }) => {
    return (
      Array.isArray(appRoutes) &&
      appRoutes.map(({ children, path, layout, role }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              <RestrictedRoute role={role} isPrivate={isPrivate} layout={layout || MainLayout}>
                {children}
              </RestrictedRoute>
            }
          ></Route>
        );
      })
    );
  });

  return (
    <BrowserRouter>
      <Routes>
        {mappedRoutes}
        <Route path="*" element={<Page404 />}></Route>;
      </Routes>
    </BrowserRouter>
  );
};
