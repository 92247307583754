import { Box, TableHead, TableRow, TableCell, TableBody, TableContainer, Table } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { CreatedStatus, ProcessingStatus, SuccessfulStatus } from "../../components/status";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import CustomRangePagination from "../../components/RangePagination";
import Items from "../../services/items";
import moment from "moment";
import copy from "../../assets/icons/copy.svg";
import SVG from "react-inlinesvg";
import { copyAddressToClipboard } from "../../utils/utils";
import { ToastMessage } from "../../const";

export enum BatchJobMintNFTStatus {
  Created = "Created",
  Processing = "Processing",
  Success = "Successful",
}
const useStyles = makeStyles({
  container: {
    maxHeight: 610,
  },
  svgIcon: {
    "& path": {
      stroke: themeColors.primary,
    },
  },
  copyIcon: {
    marginLeft: "5px",
    height: 20,
    width: 20,
    cursor: "pointer",
  },
});

export default function ProcessManagementList() {
  const classes = useStyles();
  const [listMintBatch, setListMintBatch] = useState([]);
  const [meta, setMeta] = useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const sortcolumn = [
    {
      id: "no",
      label: "No",
      width: "5%",
    },
    {
      id: "fileName",
      label: "File name",
      width: "10%",
    },
    {
      id: "walletAddress",
      label: "Wallet Address",
      width: "40%",
    },
    {
      id: "status",
      label: "Status",
      width: "10%",
    },
    {
      id: "uploadedDate",
      label: "Uploaded Date",
      width: "7%",
    },
    {
      id: "errorFile",
      label: "Error file / Link",
      width: "15%",
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const statusRender = (e: string) => {
    if (e === BatchJobMintNFTStatus.Created) {
      return <CreatedStatus />;
    }
    if (e === BatchJobMintNFTStatus.Processing) {
      return <ProcessingStatus />;
    }
    if (e === BatchJobMintNFTStatus.Success) {
      return <SuccessfulStatus />;
    }
  };
  const getUsersList = async () => {
    const res = await Items.getMintbatchList({
      page: page,
      limit: rowsPerPage,
    });
    if (res.statusCode === 200) {
      setListMintBatch(res?.data?.items);
      setMeta(res.data.meta);
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Box component="div">
      <Box component="div" marginTop={4}>
        <CustomRangePagination
          onPageChange={handleChangePage}
          meta={meta}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />
        <Box component="div" mt={5}>
          <>
            <TableContainer
              className={classes.container}
              style={{
                maxHeight: `calc(100vh - (${document?.getElementById("filter")?.clientHeight}px + 300px)) `,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {sortcolumn.map((column) => (
                      <TableCell key={column.id} style={{ width: column.width }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listMintBatch.length > 0 &&
                    listMintBatch?.map((row: any) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell>
                            <u>{row.serialNumber}</u>
                          </TableCell>
                          <TableCell>{row.fileNAme}</TableCell>
                          <TableCell>
                            <Box
                              component="div"
                              display={"flex"}
                              alignItems="flex-start"
                              justifyContent={"space-between"}
                            >
                              <Box component="div" color={"#518CE0"} fontSize="16px">
                                {row.walletAddress}
                              </Box>
                              <SVG
                                className={classes.copyIcon}
                                src={copy}
                                onClick={() => copyAddressToClipboard(row.walletAddress, ToastMessage.WalletCoppied)}
                              />
                            </Box>
                          </TableCell>
                          <TableCell>{statusRender(row.batJobStatus)}</TableCell>
                          <TableCell>{moment(row?.createdAt).format("MM-DD-YYYY")}</TableCell>
                          <TableCell
                            sx={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={() => (window.location.href = row.errorFileUrl)}
                            title={row.errorFileUrl}
                          >
                            {row.errorFileUrl?.slice(0, 20)}...
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      </Box>
    </Box>
  );
}
