import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { localStorageHelper } from "../helpers";
import TokenApi from "./tokenApi";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const token = localStorageHelper.getItem("access_token");
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error && error.response && error.response.status && error.response.status === 401) {
      if (error.response.data.status === 40103) {
        const originalRequest = error.config;
        const res = await TokenApi.refreshToken({
          refresh_token: localStorage.getItem("refreshtoken"),
        });
        if (res.data.code === 200) {
          localStorageHelper.setItem("REFRESH_TOKEN", res.data.refresh_token);
          localStorageHelper.setItem("access_token", res.data.access_token);
          return axiosClient(originalRequest);
        }
      } else if (
        error.response.data.status === 40100 ||
        error.response.data.status === 40101 ||
        error.response.data.status === 40102
      ) {
        localStorageHelper.removeItem("REFRESH_TOKEN");
        localStorageHelper.removeItem("access_token");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  },
);

export default axiosClient;
