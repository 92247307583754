import {
  Grid,
  Typography,
  Box,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Table,
} from "@mui/material";
import { useEffect, useState } from "react";
import WhiteButton from "../../components/WhiteButton";
import { SearchOutlined } from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Users from "../../services/users";
import { ApproveStatus } from "../../const";
import { ActiveStatus, DeactivatedStatus, PendingStatus, RejectedStatus } from "../../components/status";
import { useNavigate } from "react-router-dom";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import EmptyRecord from "../../components/EmptyRecord";
import CustomRangePagination from "../../components/RangePagination";
import useResponsive from "../../utils/responsive";

export enum CorporateStatusFilter {
  PENDING_REVIEW = "requested",
  APPROVED = "approved",
  DEACTIVED = "deactivated",
  REJECTED = "rejected",
}

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {},
  container: {
    maxHeight: 610,
  },
  approvedBtn: {
    color: "#149973",
    borderRadius: "20px",
    background: "#D0FFDD",
    padding: "4px 8px 4px 8px",
  },
  pendingBtn: {
    color: "#E06339",
    borderRadius: "20px",
    background: "#FFE4D0",
    padding: "4px 8px 4px 8px",
  },
  rejectedBtn: {
    color: "#D73021",
    borderRadius: "20px",
    background: "#FFE3E0",
    padding: "4px 8px 4px 8px",
  },
  deactivatedBtn: {
    color: "#3E5463",
    borderRadius: "20px",
    background: "#DCE3E8",
    padding: "4px 8px 4px 8px",
  },
  svgIcon: {
    "& path": {
      stroke: themeColors.primary,
    },
  },
});

export default function CorporateUserList() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [listUser, setListUser] = useState([]);
  const [meta, setMeta] = useState<any>();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [userDetails, setUserDetails] = useState<any>(null);
  const { isSmallDesktop, isDesktopOrLaptop } = useResponsive();

  const sortcolumn = [
    {
      id: "id",
      label: "ID",
      width: "10%",
    },
    {
      id: "name",
      label: "Name",
      width: "15%",
    },
    {
      id: "company",
      label: "Company",
      width: "15%",
    },
  ];
  const nonSortColumn = [
    {
      id: "email",
      label: "Email",
      width: "15%",
    },
    {
      id: "number",
      width: "15%",
      label: "Number",
    },
    {
      id: "position",
      width: "15%",
      label: "Position",
    },
    {
      id: "status",
      width: "15%",
      label: "Status",
    },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getUsersList = async (params?: any, id?: string) => {
    const res = await Users.getListUser({
      role: "corporate",
      search: search,
      page: params?.page ?? page,
      limit: params?.rowsPerPage ?? rowsPerPage,
      status: status,
    });
    if (res.statusCode === 200) {
      setListUser(res.data.items);
      setMeta(res.data.meta);
      if (id) {
        const selectedUser = res.data.items.find((x: any) => {
          return x.id === id;
        });
        setUserDetails(selectedUser);
      }
    }
  };

  useEffect(() => {
    getUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const onClearFilter = async () => {
    setSearch("");
    setStatus("all");
    const res = await Users.getListUser({
      role: "corporate",
      search: "",
      page: 0,
      limit: 20,
      status: "all",
    });
    if (res.statusCode === 200) {
      setListUser(res.data.items);
      setMeta(res.data.meta);
    }
  };

  const statusRender = (e: string) => {
    if (e === ApproveStatus.Approved) {
      return <ActiveStatus />;
    }
    if (e === ApproveStatus.Pending) {
      return <PendingStatus />;
    }
    if (e === ApproveStatus.Rejected) {
      return <RejectedStatus />;
    }
    return <DeactivatedStatus />;
  };

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>Corporate User List&ensp; {userDetails && <>&gt;&ensp;</>} </span>
      {userDetails && <span>Corporate user details</span>}

      <Box component={"div"} marginTop={4}>
        <Box component={"div"} id="filter">
          <Grid marginBottom={3}>
            <Box component={"div"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
              <h2>List of corporate users</h2>
            </Box>
          </Grid>
          <Grid container alignItems={"center"} gap={5}>
            <Grid item xs={5}>
              <Typography sx={{ fontWeight: "bold" }}>Search &nbsp;</Typography>
              <TextField
                label={search === "" ? "Search by Name, Company, Position" : ""}
                fullWidth
                sx={{
                  marginTop: 1,
                  "& label": {
                    marginLeft: 4,
                    "&.Mui-focused": {
                      color: "#B3B3B3",
                    },
                  },
                }}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  endAdornment: search && (
                    <IconButton onClick={() => setSearch("")}>
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                size="small"
                onChange={(e) => {
                  handleSearchChange(e);
                }}
                value={search}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getUsersList();
                  }
                }}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: "bold" }}>Status &nbsp;</Typography>
              <FormControl fullWidth>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  sx={{
                    marginTop: 1,
                    borderRadius: 20,
                    height: "40px",
                  }}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={CorporateStatusFilter.PENDING_REVIEW}>Pending review</MenuItem>
                  <MenuItem value={CorporateStatusFilter.APPROVED}>Active</MenuItem>
                  <MenuItem value={CorporateStatusFilter.DEACTIVED}>Deactivated</MenuItem>
                  <MenuItem value={CorporateStatusFilter.REJECTED}>Rejected</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {isDesktopOrLaptop && (
              <Grid item>
                <Box component={"div"}>
                  <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>

                  <WhiteButton label="Clear all filters" onClick={onClearFilter} width="150px" sx={{ fontSize: 16 }} />
                </Box>
              </Grid>
            )}
            {isDesktopOrLaptop && (
              <Grid item>
                <Box component={"div"}>
                  <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>

                  <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getUsersList}>
                    Search
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          {isSmallDesktop && (
            <Box component={"div"} display={"flex"}>
              <Box component={"div"} mr={2}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                <WhiteButton
                  label="Clear all filters"
                  onClick={() => {
                    onClearFilter();
                  }}
                  width="150px"
                  sx={{ fontSize: 16 }}
                />
              </Box>
              <Box component={"div"}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getUsersList}>
                  Search
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Box component={"div"} mt={5}>
          {listUser.length > 0 ? (
            <>
              <TableContainer
                className={classes.container}
                style={{
                  maxHeight: `calc(100vh - (${document?.getElementById("filter")?.clientHeight}px + 300px)) `,
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      {sortcolumn.map((column) => (
                        <TableCell key={column.id} style={{ width: column.width }}>
                          {column.label}
                        </TableCell>
                      ))}
                      {nonSortColumn.map((column) => (
                        <TableCell key={column.id} style={{ minWidth: column.width }}>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listUser.map((row: any) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          <TableCell
                            style={{ cursor: "pointer", color: "#518CE0" }}
                            onClick={() => {
                              setUserDetails(row);
                              navigate(`/user-detail/${row.id}`, { state: { currentUser: row } });
                            }}
                          >
                            <u>{row.serialNumber}</u>
                          </TableCell>
                          <TableCell>
                            {row.firstName} &nbsp;
                            {row.lastName}
                          </TableCell>
                          <TableCell>{row.corporate?.name}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>
                            {row.dialCode}
                            {row.phone}
                          </TableCell>
                          <TableCell width={"15%"}>{row.position}</TableCell>
                          <TableCell>{statusRender(row.corporateStatus)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <CustomRangePagination
                onPageChange={handleChangePage}
                meta={meta}
                page={page}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
              />
            </>
          ) : (
            <EmptyRecord />
          )}
        </Box>
      </Box>
    </Box>
  );
}
