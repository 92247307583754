/* eslint-disable max-len */
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { Box, Button, Checkbox, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import WalletBanner from "./Banner";
import ListWallet from "./List";
import { validationPatterns } from "../../validation/pattern";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import ModalRecoveryPhraseData from "./Modal/RecoveryPhraseData";
import ModalRecoveryFillData from "./Modal/RecoveryFillData";
import IronCase from "../../assets/images/iron-case.svg";
import SVG from "react-inlinesvg";
import WhiteButton from "../../components/WhiteButton";
import CustomModal from "../../components/modal";
import WalletService from "../../services/wallet";
import ErrorMessage from "../../components/ErrorMessage";
import { Loading } from "../../components";
import { toast } from "react-toastify";
import RecoveryPhraseSecret from "./Modal/RecoveryPhraseSecret";
import { MAXIMUM_TEXT_FIELD_LENGTH } from "../../const";

enum WalletModal {
  NONE = "NONE",
  CREATE_WALLET = "CREATE_WALLET",
  RECOVERY_PHRASE_CONFIRM = "RECOVERY_PHRASE_CONFIRM",
  RECOVERY_PHRASE_DATA = "RECOVERY_PHRASE_DATA",
  RECOVERY_PHRASE_FILL = "RECOVERY_PHRASE_FILL",
  RECOVERY_WALLET_SUCCESS = "RECOVERY_WALLET_SUCCESS",
  FILL_SECRET_PHRASE = "FILL_SECRET_PHRASE",
  UPDATE_INFO_WALLET = "UPDATE_INFO_WALLET",
}

const listConfirm = [
  {
    checked: false,
    label: "Nobody is looking at my screen.",
  },
  {
    checked: false,
    label: "If I lose my recovery phrase, I will no longer have access to this wallet.",
  },
  {
    checked: false,
    label: "Anyone with my recovery phrase, my account and password will be able to use my funds.",
  },
  {
    checked: false,
    label: "I will not take a screenshot. I will write it down.",
  },
];

const PasswordVadilation = styled(Typography)({
  fontSize: "16px !important",
});

const PDFLink = styled("a")({
  color: themeColors.lightBlue,
  textDecoration: "underline",
});

const CustomTextfield = styled(TextField)({
  "& .MuiInputLabel-root ": {
    fontSize: "16px !important",
  },
});
interface IWalletProps {
  [k: string]: any;
}

const Wallet: React.FunctionComponent<IWalletProps> = (props) => {
  const { setPaddingLayout } = props;
  const [showModalOption, setShowModalOption] = useState({ status: false, type: WalletModal.NONE });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [infoWallet, setInfoWallet] = useState({
    name: "",
    password: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [confirmRecoveryPhrase, setConfirmRecoveryPharse] = useState(listConfirm);
  const [widthModal, setWidthModal] = useState(608);
  const [errMsg, setErrMsg] = useState("");
  const [seedPhraseInfo, setSeedPhraseInfo] = useState<any>(null);
  const [secretPhraseInput, setSecretPhraseInput] = useState("");
  const [walletList, setWalletList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isWalletLoading, setIsWalletLoading] = useState(false);

  useLayoutEffect(() => {
    setPaddingLayout(0);

    return () => {
      setPaddingLayout(null);
    };
  }, [setPaddingLayout]);

  const getWalletList = async () => {
    const res = await WalletService.getListWallet();
    if (res?.statusCode === 200) {
      setWalletList(res.data.wallets);
      setShowModal(false);
      setIsWalletLoading(false);
    }
  };

  useEffect(() => {
    try {
      getWalletList();
    } catch (error: any) {
      setErrMsg(error?.response?.data?.message || "Something went wrong");
    }
  }, [showModalOption.type]);

  useEffect(() => {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(secretPhraseInput) && secretPhraseInput.length) {
      setErrMsg("Please enter only words");
    } else {
      setErrMsg("");
    }
  }, [secretPhraseInput]);

  useEffect(() => {
    if (showModalOption.type === WalletModal.RECOVERY_WALLET_SUCCESS) {
      setInfoWallet({ name: "", password: "", confirmPassword: "" });
      setConfirmRecoveryPharse((prev) => prev.map((item) => ({ ...item, checked: false })));
    }
  }, [showModalOption.status, showModalOption.type]);

  const handleConfirmSeedPhrase = async (seedPhrase: string[], id: string) => {
    try {
      const finalSeedPhrase = seedPhrase.map((word: any) => word.value);
      setIsLoading(true);
      await WalletService.confirmSeedPhrase({ id, seed_phrase: finalSeedPhrase });
      setErrMsg("");
      setIsLoading(false);
      setShowModalOption({ status: true, type: WalletModal.RECOVERY_WALLET_SUCCESS });
      toast("Create Wallet Successfully");
    } catch (error: any) {
      setErrMsg(error.response.data.message || "Something went wrong");
    }
  };

  const renderModalContent = (type: WalletModal) => {
    switch (type) {
      case WalletModal.CREATE_WALLET:
        return renderCreateAndRestoreWallet("create");
      case WalletModal.RECOVERY_PHRASE_CONFIRM:
        return recoveryPhraseConfirm();
      case WalletModal.RECOVERY_PHRASE_DATA:
        return (
          <ModalRecoveryPhraseData
            handleFillData={() => setShowModalOption({ status: true, type: WalletModal.RECOVERY_PHRASE_FILL })}
            setWidthModal={setWidthModal}
            seedPhraseInfo={seedPhraseInfo}
          />
        );
      case WalletModal.RECOVERY_PHRASE_FILL:
        return (
          <ModalRecoveryFillData
            handleContinue={handleConfirmSeedPhrase}
            status={showModalOption.status}
            setWidthModal={setWidthModal}
            seedPhraseInfo={seedPhraseInfo}
          />
        );
      case WalletModal.FILL_SECRET_PHRASE:
        return (
          <RecoveryPhraseSecret
            handleFillSecretPhrase={handleFillSecretPhrase}
            setSecretPhraseInput={setSecretPhraseInput}
            secretPhraseInput={secretPhraseInput}
            errMsg={errMsg}
          />
        );
      case WalletModal.UPDATE_INFO_WALLET:
        return renderCreateAndRestoreWallet("restore");
      default:
        return <></>;
    }
  };

  const handleCreateWallet = () => {
    setShowModalOption({ status: true, type: WalletModal.CREATE_WALLET });
  };

  const handleRestoreWallet = () => {
    setShowModalOption({ status: true, type: WalletModal.FILL_SECRET_PHRASE });
  };

  const handleFillSecretPhrase = async () => {
    try {
      setIsLoading(true);
      const mapSeedPhrase = secretPhraseInput
        ?.trim()
        .split(" ")
        .filter((word) => word);

      const res = await WalletService.recoverWallet({
        name: "sendSeedPhrase",
        password: "12345679aA@",
        seed_phrase: mapSeedPhrase,
      });
      if (res?.data?.["seed_phrase"]) {
        setSeedPhraseInfo(res.data);
      }
      setIsLoading(false);
      setShowModalOption({ status: true, type: WalletModal.UPDATE_INFO_WALLET });
    } catch (error: any) {
      setIsLoading(false);
      setErrMsg(error.response.data.message || "Something went wrong");
    }
  };

  const handleCheckConfirm = (index: number) => {
    const list = [...confirmRecoveryPhrase];
    list[index].checked = !list[index].checked;
    setConfirmRecoveryPharse(list);
  };

  const isValidContinueConfirmRecoryPhrase = () => {
    return confirmRecoveryPhrase.every((item) => item.checked);
  };

  const renderCreateAndRestoreWallet = (type: "create" | "restore") => {
    const isCreate = type === "create";
    const handleCreateAndRestoreWalletModal = async (
      name: string,
      password: string,
      type: "create" | "restore",
      seed_phrase: string,
    ) => {
      if (type === "create") {
        try {
          setIsLoading(true);
          const res = await WalletService.createWallet({ name, password });
          if (res?.data?.["seed_phrase"]) {
            setSeedPhraseInfo(res.data);
          }
          setIsLoading(false);
          setShowModalOption({ status: true, type: WalletModal.RECOVERY_PHRASE_CONFIRM });
        } catch (error: any) {
          setIsLoading(false);
          setErrMsg(error.response.data.message || "Something went wrong");
        }
      }
      if (type === "restore") {
        try {
          setIsLoading(true);
          const mapSeedPhrase = seed_phrase
            ?.trim()
            .split(" ")
            .filter((word) => word);

          const res = await WalletService.recoverWallet({ name, password, seed_phrase: mapSeedPhrase });
          if (res?.data?.["seed_phrase"]) {
            setSeedPhraseInfo(res.data);
          }
          setIsLoading(false);
          setShowModalOption({ status: false, type: WalletModal.NONE });
          toast("Restore Wallet Successfully");
        } catch (error: any) {
          setIsLoading(false);
          setErrMsg(error.response.data.message || "Something went wrong");
        }
      }
    };

    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            {isCreate ? "Create DVOLD Wallet" : "Restore DVOLD/ADA wallet"}
          </Typography>
        </Box>

        <br />
        <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>Wallet name</Typography>

        <TextField
          type="text"
          name="wallet-name"
          autoComplete="off"
          placeholder={infoWallet.name === "" ? "Wallet name" : ""}
          fullWidth
          sx={{
            marginTop: 1,
            marginBottom: 3,
            "& label": {
              "&.Mui-focused": {
                color: "#B3B3B3",
              },
            },
          }}
          InputLabelProps={{ shrink: false }}
          onChange={(e) => setInfoWallet((prev) => ({ ...prev, name: e.target.value }))}
          inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH, autocomplete: "off" }}
          defaultValue=""
        />
        <input
          name="wallet-name"
          type="text"
          autoComplete="off"
          placeholder={infoWallet.name === "" ? "Wallet name" : ""}
          style={{ width: 0, height: 0, opacity: 0, border: "none", padding: 0, margin: 0 }}
        />

        <PasswordVadilation sx={{ fontWeight: "bold", fontSize: 16 }}>Password</PasswordVadilation>
        <input
          id="wallet-password"
          name="wallet-password"
          autoComplete="new-wallet-password"
          type="password"
          style={{ width: 0, height: 0, opacity: 0, border: "none", padding: 0, margin: 0 }}
        />
        <CustomTextfield
          label={infoWallet.password === "" ? "Spending password" : ""}
          id="wallet-password"
          name="wallet-password"
          autoComplete="new-wallet-password"
          type={showPassword ? "text" : "password"}
          fullWidth
          sx={{
            marginTop: 1,
            "& label": {
              "&.Mui-focused": {
                color: "#B3B3B3",
              },
            },
          }}
          InputLabelProps={{ shrink: false }}
          value={infoWallet.password}
          onChange={(e) => setInfoWallet((prev) => ({ ...prev, password: e.target.value }))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH, autocomplete: "off" }}
        ></CustomTextfield>
        <PasswordVadilation sx={{ fontWeight: "bold", mt: 2, fontSize: 16 }}>Re-enter password</PasswordVadilation>
        <CustomTextfield
          label={infoWallet.confirmPassword === "" ? "Spending password" : ""}
          type={showConfirmPassword ? "text" : "password"}
          id="wallet-confirm-password"
          name="wallet-confirm-password"
          fullWidth
          sx={{
            marginTop: 1,
            "& label": {
              "&.Mui-focused": {
                color: "#B3B3B3",
              },
            },
          }}
          InputLabelProps={{ shrink: false }}
          value={infoWallet.confirmPassword}
          onChange={(e) => setInfoWallet((prev) => ({ ...prev, confirmPassword: e.target.value }))}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH, autocomplete: "new-wallet-password" }}
        ></CustomTextfield>
        <Box component={"div"} marginTop={2} marginBottom={2}>
          <Box
            component={"div"}
            display="flex"
            alignItems="center"
            color={infoWallet.password.length >= 10 ? themeColors.primary : themeColors.gray}
          >
            <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
            <PasswordVadilation>At least 10 characters.</PasswordVadilation>
          </Box>
          <Box
            component={"div"}
            display="flex"
            alignItems="center"
            color={validationPatterns.mixture.test(infoWallet.password) ? themeColors.primary : themeColors.gray}
          >
            <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
            <PasswordVadilation>A mixture of numbers, uppercase and lowercase letters.</PasswordVadilation>
          </Box>
          <Box
            component={"div"}
            display="flex"
            alignItems="center"
            color={
              validationPatterns.specialCharacter.test(infoWallet.password) ? themeColors.primary : themeColors.gray
            }
          >
            <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
            <PasswordVadilation>Include of at least one special character,e.g., ! @ # ? ]</PasswordVadilation>
          </Box>
        </Box>

        <Box component={"div"} display={"flex"} alignItems="center" flexDirection="column">
          <Button
            variant="contained"
            sx={{ width: 160 }}
            disabled={
              !validationPatterns.specialCharacter.test(infoWallet.password) ||
              !validationPatterns.mixture.test(infoWallet.password) ||
              infoWallet.password.length < 10 ||
              infoWallet.password === "" ||
              infoWallet.confirmPassword === "" ||
              infoWallet.name === "" ||
              infoWallet.password !== infoWallet.confirmPassword
            }
            onClick={() =>
              handleCreateAndRestoreWalletModal(
                infoWallet.name,
                infoWallet.password,
                isCreate ? "create" : "restore",
                secretPhraseInput,
              )
            }
          >
            {isLoading ? <Loading /> : "Continue"}
          </Button>

          {isCreate && (
            <Typography sx={{ textAlign: "center", marginTop: "1rem" }}>
              We have prepared a PDF form for you to print and keep your wallet information secure and organized. You
              can view and download the PDF{" "}
              <PDFLink href="https://noc22145.s3.us-west-1.amazonaws.com/52587a82-51a6-4f14-85da-b5263d7e6f97/1668652551871-RecoveryPhrase.pdf">
                here
              </PDFLink>{" "}
              .
            </Typography>
          )}
        </Box>
      </>
    );
  };

  const recoveryPhraseConfirm = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              mb: 2,
            }}
          >
            Recovery Phrase
          </Typography>

          <Typography sx={{ textAlign: "center", marginBottom: "1rem" }}>
            In the next screen you will see a list of 24 words that is the recovery phrase of your wallet. It can be
            used to access your DVOLD wallet in any device.
          </Typography>

          <Box component={"div"}>
            {confirmRecoveryPhrase.map((item, index) => (
              <Box
                component={"div"}
                key={item.label}
                sx={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}
              >
                <Checkbox checked={item.checked} onClick={() => handleCheckConfirm(index)} />
                <Typography>{item.label}</Typography>
              </Box>
            ))}
          </Box>

          <Button
            variant="contained"
            sx={{ width: 160, marginTop: "1rem" }}
            disabled={!isValidContinueConfirmRecoryPhrase()}
            onClick={() => setShowModalOption({ status: true, type: WalletModal.RECOVERY_PHRASE_DATA })}
          >
            Continue
          </Button>
        </Box>
      </>
    );
  };

  const renderCreateWalletSuccess = () => {
    return (
      <Box
        component={"div"}
        display={"flex"}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "70vh" }}
      >
        <Box component={"div"} sx={{ mb: 2 }}>
          <SVG src={IronCase} />
        </Box>
        <Typography sx={{ mb: 2 }}>Manage your NFTs on Cardano chain in a secure way with DVOLD.</Typography>
        <Box component={"div"}>
          <WhiteButton onClick={() => {}} label="Restore DVOLD/ADA wallet" />
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            onClick={() => setShowModalOption({ status: true, type: WalletModal.CREATE_WALLET })}
          >
            Create a DVOLD/ADA wallet
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box component={"div"}>
      {showModalOption.type !== WalletModal.RECOVERY_WALLET_SUCCESS ? (
        <>
          <WalletBanner handleCreateWallet={handleCreateWallet} handleRestoreWallet={handleRestoreWallet} />
          <ListWallet
            walletList={walletList}
            showModal={showModal}
            isLoading={isWalletLoading}
            setIsLoading={setIsWalletLoading}
            getWalletList={getWalletList}
            setShowModal={setShowModal}
          />
          <CustomModal
            sx={{ maxWidth: `${widthModal ?? 608}px`, width: "auto" }}
            open={showModalOption.status}
            onClose={() => {
              setShowModalOption({ status: false, type: WalletModal.NONE });
              setInfoWallet({ name: "", password: "", confirmPassword: "" });
              setSecretPhraseInput("");
            }}
          >
            {renderModalContent(showModalOption.type)}
            <Box component={"div"} sx={{ textAlign: "center" }}>
              <ErrorMessage msg={errMsg} />
            </Box>
          </CustomModal>
        </>
      ) : (
        renderCreateWalletSuccess()
      )}
    </Box>
  );
};

export default Wallet;
