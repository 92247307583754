import React, { useState } from "react";
import Box from "@mui/material/Box";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import ListBanners from "./ListBanners";
import { Button, Typography } from "@mui/material";
import CreateBanner from "./CreateBanner";
import EditBanner from "./EditBanner";
import DetailBanner from "./DetailBanner";
import CustomModal from "../../components/modal";
import { ReactComponent as BigDelete } from "../../assets/icons/delete2x.svg";
import WhiteButton from "../../components/WhiteButton";
import Banner, { BannerStatus, BannerType } from "../../services/banner";
import { toast } from "react-toastify";
import ErrorMessage from "../../components/ErrorMessage";
import { Loading } from "../../components";

export enum BannerState {
  CREATE = "create",
  EDIT = "edit",
  DETAIL = "detail",
  VIEW = "view",
}

export type FileType = {
  file: File | null;
  imagePreviewUrl: string;
};

export interface IBannerFields {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  countries: { id: string; code: string; name: string }[];
  type: BannerType;
  ctaButton: string[];
  image: string | FileType;
  bannerUrl: string;
  status: BannerStatus;
  isAll?: boolean;
  coverImage?: string;
  url?: string;
  country?: string[];
  amount?: number;
}

export default function ManageBanner() {
  const [bannerStatus, setBannerStatus] = useState(BannerState.VIEW);
  const [bannerDetailId, setBannerDetailId] = useState("");
  const [bannerGlobal, setBannerGlobal] = useState<any>(null);
  const [deleteBanner, setDeleteBanner] = useState({
    id: "",
    approved: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteBanner = async (id: string) => {
    try {
      setIsLoading(true);
      setDeleteBanner((prev) => ({ ...prev, approved: true }));
      await Banner.deleteBanner(id);
      setIsLoading(false);
      toast("Deleted banner successfully");
      setDeleteBanner((prev) => ({ ...prev, approved: false }));
      setBannerStatus(BannerState.VIEW);
    } catch (error: any) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const renderDeleteBannerModal = () => {
    return (
      <CustomModal
        open={deleteBanner.approved}
        onClose={() => setDeleteBanner((prev) => ({ ...prev, approved: false }))}
      >
        {isLoading && <Loading />}
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
            gap: 0.8,
          }}
        >
          <BigDelete />
          {/*  */}
          <Typography
            variant="h3"
            sx={{
              mb: 1,
              mt: 2,
            }}
          >
            Delete banner
          </Typography>

          <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
            Are you sure you want to delete this banner?
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
            WARNING this process can not be reversed. Cancel to go back.
          </Typography>
          {/*  */}

          <Box component={"div"} display="flex" justifyContent="center" alignItems="center" gap={1} mt={2}>
            <Button
              sx={{ paddingX: 4, paddingY: 1, cursor: "pointer" }}
              variant="contained"
              onClick={() => setDeleteBanner({ id: "", approved: false })}
            >
              Cancel
            </Button>

            <WhiteButton
              onClick={() => handleDeleteBanner(deleteBanner.id)}
              sx={{ paddingX: 4, paddingY: 0.9 }}
              label="Delete"
            />

            <ErrorMessage msg={error} />
          </Box>
        </Box>
      </CustomModal>
    );
  };

  const renderContent = () => {
    switch (bannerStatus) {
      case BannerState.CREATE:
        return <CreateBanner setBannerStatus={setBannerStatus} />;
      case BannerState.EDIT:
        return (
          <EditBanner
            bannerStatus={bannerStatus}
            bannerDetailId={bannerDetailId}
            setBannerStatus={setBannerStatus}
            bannerGlobal={bannerGlobal}
          />
        );
      case BannerState.DETAIL:
        return (
          <DetailBanner
            bannerStatus={bannerStatus}
            bannerDetailId={bannerDetailId}
            setBannerStatus={setBannerStatus}
            setDeleteBanner={setDeleteBanner}
            setBannerGlobal={setBannerGlobal}
          />
        );
      case BannerState.VIEW:
        return (
          <ListBanners
            setBannerDetailId={setBannerDetailId}
            setBannerStatus={setBannerStatus}
            setDeleteBanner={setDeleteBanner}
            isDeleteBanner={deleteBanner.approved}
            setBannerGlobal={setBannerGlobal}
          />
        );
      default:
        return null;
    }
  };

  const renderBreakCrumb = () => {
    switch (bannerStatus) {
      case BannerState.CREATE:
        return (
          <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
            <span style={{ cursor: "pointer" }} onClick={() => setBannerStatus(BannerState.VIEW)}>
              Manage banners
            </span>
            &ensp; <>&gt;&ensp;</>{" "}
            <Typography variant="subtitle1" component="span" style={{ color: themeColors.gray }}>
              Create new banner
            </Typography>
          </Typography>
        );
      case BannerState.EDIT:
        return (
          <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
            <span style={{ cursor: "pointer" }} onClick={() => setBannerStatus(BannerState.VIEW)}>
              Manage banners
            </span>
            &ensp; <>&gt;&ensp;</> Banner detail&ensp; <>&gt;&ensp;</>
            <Typography variant="subtitle1" component="span" style={{ color: themeColors.gray }}>
              Edit banner
            </Typography>
          </Typography>
        );
      case BannerState.DETAIL:
        return (
          <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
            <span style={{ cursor: "pointer" }} onClick={() => setBannerStatus(BannerState.VIEW)}>
              Manage banners
            </span>
            &ensp; <>&gt;&ensp;</>{" "}
            <Typography variant="subtitle1" component="span" style={{ color: themeColors.gray }}>
              Banner detail
            </Typography>
          </Typography>
        );
      case BannerState.VIEW:
        return (
          <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
            Manage banners
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Box component={"div"}>
      {renderBreakCrumb()}

      {renderContent()}

      {renderDeleteBannerModal()}
    </Box>
  );
}
