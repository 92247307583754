import React, { useContext, useEffect, useState } from "react";
import { localStorageHelper } from "../helpers";
import { Props } from "../utils/utils";

export const WalletContext = React.createContext<any>(null);

export const useWalletContext = (): any => useContext(WalletContext);

export const WalletProvider: React.FC<Props> = ({ children }) => {
  const getWalletFromLocalStorage = () => {
    const wallet = localStorageHelper.getItem("walletInfo", { isObject: true });
    return wallet;
  };

  const removeWalletFromLocalStorage = () => {
    localStorageHelper.removeItem("walletInfo");
  };

  const [selectedWallet, setSelectedWallet] = useState<any>(getWalletFromLocalStorage());

  useEffect(() => {
    localStorageHelper.setItem("walletInfo", selectedWallet);
  }, [selectedWallet]);

  return (
    <WalletContext.Provider value={{ selectedWallet, setSelectedWallet, removeWalletFromLocalStorage }}>
      {children}
    </WalletContext.Provider>
  );
};
