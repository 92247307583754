import { Button, Typography } from "@mui/material";
import React from "react";
import { FallbackProps } from "react-error-boundary";
import ErrorImage from "../../assets/images/error.png";

export const ErrorFallback: React.FC<FallbackProps> = () => {
  return (
    <div
      role="alert"
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <img src={ErrorImage} alt="error" style={{ width: "35%", height: "40%" }} />
      <Typography variant="h2" sx={{ mt: 2 }}>
        Something went wrong
      </Typography>
      <pre>We’re sorry , something went wrong there. Try again</pre>
      <Button variant="contained" onClick={() => window.location.reload()} sx={{ mt: 2, cursor: "pointer" }}>
        Try again
      </Button>
    </div>
  );
};
