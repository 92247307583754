import styled from "@emotion/styled";
import { Grid, Typography, Box, Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import CustomTextField from "../../components/CustomTextField";
import LogoutIcon from "@mui/icons-material/Logout";
import Users from "../../services/users";
import CustomModal from "../../components/modal";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { emailValidation } from "../../utils/utils";
import { ActiveStatus, DeactivatedStatus, PendingStatus, RejectedStatus } from "../../components/status";
import { ApproveStatus } from "../../const";
import SVG from "react-inlinesvg";
import paper from "../../assets/icons/File.svg";
import WarningCircle from "../../assets/icons/warning-circle.svg";
import { makeStyles } from "@mui/styles";
import mailIcon from "../../assets/icons/mail-send 1.svg";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import { CustomLink } from "../authenticate/login";
import defaultImage from "../../assets/images/image 2.png";
import camera from "../../assets/icons/Button Main.svg";
import { Loading } from "../../components";
import { PhoneNumberInput } from "../../components/phoneNumberInput";
import { toast } from "react-toastify";
import { convertName } from "../../utils/convertName";
import { SettingPageMessage } from "./const";
import CustomDropzone from "../../components/CustomDropzone";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import warningLogo from "../../assets/icons/WarningCircle.svg";
import WhiteButton from "../../components/WhiteButton";
import ChangePassword from "./change-password";

const useStyles = makeStyles({
  coverImageContainer: {
    position: "relative",
    "& input": {
      display: "none",
    },
  },
  errorMsg: {
    color: "red",
    fontStyle: "italic",
    marginTop: 2,
    fontSize: 14,
  },
  buttonDropzone: {
    position: "absolute",
    bottom: "15%",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    "& button": {
      background: themeColors.blue,
      color: themeColors.white,
    },
  },
});

const TextLimit = styled(Typography)({
  display: "inline-block",
  width: "28vw",
  whiteSpace: "nowrap",
  overflow: "hidden !important",
  textOverflow: "ellipsis",
  textAlign: "center",
});

enum ModalStatus {
  NONE = "NONE",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  VERIFY = "VERIFY",
}

export default function Settings() {
  const classes = useStyles();

  const [account, setAccount] = useState<any>({
    email: "",
    firstName: "",
    lastName: "",
    position: "",
    dialCode: "",
    phone: "",
    id: "",
    corporateStatus: "",
    coverImage: "",
    corporate: {},
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [password, setPassword] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [showModalOption, setShowModalOption] = useState({ status: false, type: ModalStatus.NONE });
  const [showPassword, setShowPassword] = useState(false);
  const [docs, setDocs] = useState<any[]>([]);
  const [countdown, setCountdown] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [updateImage, setUpdateImage] = useState<any>(undefined);
  const [imagePreview, setImagePreview] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [totalFile, setTotalFile] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [isChangePassword, setIsChangePassword] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reader, _] = useState(new FileReader());

  const getCurrentUser = async () => {
    setIsLoading(true);
    const res = await Users.getCurrentUser();

    if (res.statusCode === 200) {
      setAccount(res.data);
      setName(`${res.data.firstName} ${res.data.lastName}`);
      setDocs(res.data?.corporate?.docs);
      setIsLoading(false);
      setPhoneNumber(`${res.data.dialCode} ${res.data.phone}`);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const onHandleUpload = async (item: any) => {
    setIsLoading(true);
    const res = await Users.uploadDocs({ file: item.file, token: localStorage.getItem("access_token") });
    if (res.statusCode === 200) {
      let newDocs = [...docs, res?.data[0]];
      setDocs(newDocs);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = (file: any, allFiles: any) => {
    if (allFiles === "done") {
      onHandleUpload(file);
      setTotalFile(totalFile + 1);
    } else if (allFiles === "removed") {
      setTotalFile(totalFile - 1);
    }
  };

  const onChangeEmail = async () => {
    try {
      const res = await Users.changeEmail({ email: email, password: password, type: "web" });
      if (res.statusCode === 200) {
        setShowModalOption({ status: true, type: ModalStatus.VERIFY });
      }
    } catch (error: any) {
      setError(error.response.data.message || "Change email failed");
    }
  };

  useEffect(() => {
    if (countdown <= 0) {
      return;
    }
    const timer = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const updateUser = async (item?: any) => {
    const phoneString = (document.getElementById("phoneInput") as HTMLInputElement)?.value;

    const dialCode = phoneString?.split(" ")[0];
    const phoneNumer = phoneString.substring(phoneString.indexOf(" ") + 1);

    const res = await Users.updateUserInfo({
      ...(account.firstName && { firstName: account.firstName }),
      ...(account.lastName && { lastName: account.lastName }),
      phone: phoneNumer,
      dialCode: dialCode,
      ...(account.position && { position: account.position }),
      ...(docs && { docs: docs }),
      ...(updateImage && { coverImage: item.link }),
    });
    if (res.statusCode === 200) {
      await getCurrentUser();
      toast("Update information successfully");
    }
  };
  const onDoneClick = async () => {
    setIsLoading(true);
    const res = await Users.uploadFiles({ file: updateImage });
    if (res.statusCode === 200) {
      updateUser(res.data[0]);
      setIsEdit(true);
      setTotalFile(0);
    }
  };

  const fileHandler = (e: any) => {
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setImagePreview(reader?.result);
        setUpdateImage(e.target.files![0]);
      });
    }
  };
  const onBackClick = async () => {
    getCurrentUser();
    setImagePreview(undefined);
    setIsEdit(true);
  };

  const onLogout = async () => {
    localStorage.removeItem("account");
    localStorage.removeItem("access_token");
    localStorage.removeItem("walletInfo");
    window.location.href = "/login";
  };

  const renderStatus = (e: string) => {
    if (e === ApproveStatus.Approved) {
      return <ActiveStatus />;
    }
    if (e === ApproveStatus.Pending) {
      return <PendingStatus />;
    }
    if (e === ApproveStatus.Rejected) {
      return <RejectedStatus />;
    }
    return <DeactivatedStatus />;
  };

  const resendEmail = async () => {
    const res = await Users.resendEmail({ email: email, type: "web" });
    if (res.statusCode === 200) {
      setCountdown(300);
    }
  };

  const handleActiveDeleteAccount = async (password: string) => {
    try {
      await Users.deleteAccount({ password });
      onLogout();
    } catch (err: any) {
      setError(err.response.data.message || SettingPageMessage.WRONG_PASSWORD);
    }
  };

  const handleDeleteAccount = () => {
    setShowModalOption({ status: true, type: ModalStatus.DELETE_ACCOUNT });
  };

  const renderChangeEmailModal = () => {
    return (
      <>
        <Box component={"div"}>
          <Typography align="center" variant="h3" mb={2}>
            Change email
          </Typography>
          <Typography align="center" variant={undefined}>
            Only after you have verified your new email, you will be able to sign in
          </Typography>
          <Typography align="center" variant={undefined}>
            with the new email.
          </Typography>
          <Typography align="center" variant={undefined}>
            After verifying the new email, you will need to wait until DVOLD admin
          </Typography>
          <Typography align="center" variant={undefined}>
            approves your Corporate Profile to use corporate features!
          </Typography>
          <Typography align="center" variant={undefined} mt={2}>
            Please notice that verifying process will be expected to take 2-3
          </Typography>
          <Typography align="center" variant={undefined} mb={2}>
            business days
          </Typography>
          <CustomTextField
            label="Email"
            value={email}
            placeholder="Enter your new email"
            onChange={(e: string) => setEmail(e)}
          />
          <Typography sx={{ fontWeight: "bold" }}>Password</Typography>
          <TextField
            label={password === "" ? "Enter your password" : ""}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            fullWidth
            sx={{
              marginTop: 1,
              "& label": {
                "&.Mui-focused": {
                  color: "#B3B3B3",
                },
              },
            }}
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
        <Typography className={classes.errorMsg}>{error}</Typography>
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            sx={{ width: "140px" }}
            onClick={onChangeEmail}
            disabled={emailValidation(email) === SettingPageMessage.INVALID_EMAIL || !password}
          >
            Next
          </Button>
        </Box>
      </>
    );
  };

  const renderVerifyEmail = () => {
    return (
      <Box component={"div"}>
        <Box component={"div"} display={"flex"} justifyContent="center">
          <SVG src={mailIcon} />
        </Box>
        <Typography align="center" variant="h3" mb={2}>
          Verify your email
        </Typography>
        <Typography align="center">
          A link has been sent to <b>{email}</b> to verify your
        </Typography>
        <Typography align="center">account. If you have not received the email after a few minutes,</Typography>
        <Typography align="center">please check your spam folder.</Typography>
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <Button
            variant="contained"
            // sx={{ width: "140px" }}
            onClick={resendEmail}
            disabled={countdown !== 0}
          >
            Resend verification email {countdown !== 0 && `(${countdown}s)`}
          </Button>
        </Box>
      </Box>
    );
  };

  const renderDeleteAccount = () => {
    return (
      <Box component={"div"}>
        <Box component={"div"} sx={{ textAlign: "center" }}>
          <SVG src={WarningCircle} style={{ marginRight: "5px" }} />
        </Box>
        <Typography align="center" variant="h3" mb={2}>
          Delete account
        </Typography>
        <Typography align="center" variant={undefined}>
          You’re about to delete your account. You can always create another one but you will lose all your saved
          preferences. Please enter your password.
        </Typography>

        <Box component={"div"}>
          <Typography sx={{ fontWeight: "bold" }}>Password</Typography>
          <TextField
            label={password === "" ? "Enter your password" : ""}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            fullWidth
            sx={{
              marginTop: 1,
              "& label": {
                "&.Mui-focused": {
                  color: "#B3B3B3",
                },
              },
            }}
            InputLabelProps={{ shrink: false }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Box component={"div"} sx={{ display: "flex", justifyContent: "end", marginTop: "10px" }}>
            <CustomLink sx={{ fontSize: "14px" }}>Forgot password?</CustomLink>
          </Box>
          {error && (
            <Typography align="center" className={classes.errorMsg}>
              {error}
            </Typography>
          )}
          <Typography
            onClick={() => handleActiveDeleteAccount(password)}
            align="center"
            variant={undefined}
            mt={2}
            sx={{ color: themeColors.error, cursor: "pointer" }}
          >
            Delete Account
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderModalContent = (type: ModalStatus) => {
    switch (type) {
      case ModalStatus.CHANGE_EMAIL:
        return renderChangeEmailModal();
      case ModalStatus.VERIFY:
        return renderVerifyEmail();
      case ModalStatus.DELETE_ACCOUNT:
        return renderDeleteAccount();
      default:
        return <></>;
    }
  };

  const onDeleteFile = (item: any) => {
    setShowModal(true);
    setSelectedItem(item);
  };

  const onDeleteDocument = async () => {
    const deletedDocument = docs.filter((item: any) => item.link !== selectedItem?.link);
    setShowModal(false);
    setIsLoading(true);
    const res = await Users.updateUserInfo({
      docs: deletedDocument,
    });
    if (res.statusCode === 200) {
      getCurrentUser();
    }
  };

  const renderImage = () => {
    if (imagePreview) return imagePreview;
    return account.coverImage ? account.coverImage : defaultImage;
  };

  const phoneNumberChange = (telephone?: string) => {
    setPhoneNumber(telephone);
  };

  return (
    <Box component={"div"}>
      {isLoading && <Loading />}
      <Box component={"div"} display={"flex"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Settings &gt;&nbsp;
        </Typography>
        <Typography variant="subtitle1">My account {isChangePassword && "  >  Change password"}</Typography>
      </Box>
      <Box component={"div"} marginTop={4}>
        <Grid container>
          <Grid item xs={4}>
            <Box component={"div"} display="flex" alignItems="center" flexDirection="column">
              <Box component={"div"} className={classes.coverImageContainer}>
                <label htmlFor="file-input">
                  <img src={renderImage()} alt="Alt" style={{ borderRadius: 60, height: 120, width: 120 }} />
                </label>
                {!isEdit && (
                  <>
                    <Box component={"div"} sx={{ position: "absolute", right: 0, bottom: 0 }}>
                      <SVG
                        src={camera}
                        onClick={() => {
                          document?.getElementById("file-input")!.click();
                        }}
                      />
                    </Box>
                    <input id="file-input" type="file" onChange={fileHandler} />
                  </>
                )}
              </Box>
              <Box component={"div"}>
                <TextLimit sx={{ fontSize: 20, fontWeight: "600 !important" }}>{name}</TextLimit>
              </Box>
              <Typography style={{ marginTop: "5px", marginBottom: "5px", fontWeight: 400 }} variant="h4">
                {account?.corporate?.name}
              </Typography>

              {renderStatus(account.corporateStatus)}
            </Box>
          </Grid>
          <Grid item xs={5}>
            {isChangePassword ? (
              <ChangePassword setIsChangePassword={setIsChangePassword} />
            ) : (
              <>
                <Typography sx={{ fontSize: 20, fontWeight: "600 !important" }} mb={1}>
                  Corporate account
                </Typography>
                <Box component={"div"} position={"relative"}>
                  <CustomTextField
                    label="Email address"
                    value={account.email}
                    placeholder="Enter your email address"
                    onChange={(e: string) => setAccount({ ...account, email: e })}
                    disabled
                  />
                  <Typography
                    onClick={() => {
                      setShowModalOption({ status: true, type: ModalStatus.CHANGE_EMAIL });
                    }}
                    sx={{
                      position: "absolute",
                      top: "55%",
                      right: "2%",
                      color: "#518CE0",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    variant="h6"
                  >
                    Change Email
                  </Typography>
                </Box>
                <CustomTextField
                  label="Password"
                  value={"*********"}
                  placeholder="Enter your password"
                  type="password"
                  disabled
                />
                <Typography sx={{ fontSize: 20, fontWeight: "600 !important" }}>Update Information</Typography>
                <CustomTextField label="ID" value={account.id} placeholder="Enter your first name" disabled />
                <CustomTextField
                  label="First name"
                  value={convertName(account.firstName)}
                  placeholder="Enter your first name"
                  onChange={(e: string) => setAccount({ ...account, firstName: e })}
                  disabled={isEdit}
                />
                <CustomTextField
                  label="Last name"
                  value={convertName(account.lastName)}
                  placeholder="Enter your last name"
                  onChange={(e: string) => setAccount({ ...account, lastName: e })}
                  disabled={isEdit}
                />
                <CustomTextField
                  label="Position"
                  value={convertName(account.position)}
                  placeholder="Enter your position"
                  onChange={(e: string) => setAccount({ ...account, position: e })}
                  disabled={isEdit}
                />
                <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
                  Phone number
                </Typography>
                <PhoneNumberInput
                  id="phoneInput"
                  onChange={(telephone) => {
                    phoneNumberChange(telephone);
                  }}
                  value={phoneNumber}
                  disabled={isEdit}
                />
                <Typography sx={{ fontWeight: "bold", mb: 1, mt: 2 }} variant="h4">
                  <Box component={"div"} display="flex">
                    Business licenses
                  </Box>
                </Typography>
                <Box component={"div"} display="flex" flexDirection={"column"} mb={2}>
                  {account?.corporate?.docs?.length > 0 &&
                    account?.corporate?.docs.map((x: any, index: number) => {
                      return (
                        <Box component={"div"} key={index} display="flex" alignItems={"center"}>
                          <SVG src={paper} style={{ marginRight: "5px" }} />
                          <a style={{ color: "black", fontSize: 14 }} href={x.link}>
                            <b>{x.name}</b>
                          </a>
                          {!isEdit && (
                            <ClearRoundedIcon
                              sx={{ cursor: "pointer", color: "red" }}
                              onClick={() => onDeleteFile(x)}
                            />
                          )}
                        </Box>
                      );
                    })}
                </Box>
                {!isEdit && (
                  <>
                    <CustomDropzone handleChangeStatus={handleChangeStatus} totalFile={totalFile} />
                  </>
                )}
                {isEdit ? (
                  <Box component={"div"} display="flex" justifyContent="center" mt={2}>
                    <WhiteButton
                      label="Change password"
                      onClick={() => {
                        setIsChangePassword(true);
                      }}
                      width={150}
                    />
                    <Button variant="contained" sx={{ width: 150, marginLeft: 2 }} onClick={() => setIsEdit(false)}>
                      Edit
                    </Button>
                  </Box>
                ) : (
                  <Box component={"div"} display="flex" justifyContent="center" mt={2}>
                    <WhiteButton onClick={onBackClick} label="Back" width={150} />
                    <Button variant="contained" sx={{ width: 150, marginLeft: 2 }} onClick={onDoneClick}>
                      Done
                    </Button>
                  </Box>
                )}
                <Box component={"div"} display={"flex"} justifyContent="center" marginTop={3}>
                  <LogoutIcon sx={{ cursor: "pointer" }} onClick={onLogout} />
                  <Typography sx={{ fontWeight: "bold", cursor: "pointer" }} onClick={onLogout} variant="h4">
                    &nbsp;Logout
                  </Typography>
                </Box>
                <Box component={"div"} display={"flex"} justifyContent="center" marginTop={3}>
                  <span
                    onClick={handleDeleteAccount}
                    style={{ color: themeColors.lightGray, cursor: "pointer", fontSize: 12 }}
                  >
                    <u>Delete account</u>
                  </span>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        sx={{ maxWidth: "608px" }}
        open={showModalOption.status}
        onClose={() => setShowModalOption({ status: false, type: ModalStatus.NONE })}
      >
        {renderModalContent(showModalOption.type)}
      </CustomModal>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <SVG src={warningLogo} />

          <Typography
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
            sx={{ textAlign: "center" }}
            mt={2}
            mb={2}
          >
            Are you sure you want to remove this document?
          </Typography>

          <Box component={"div"} display="flex" justifyContent="center">
            <Button variant="contained" onClick={() => setShowModal(false)} sx={{ width: "150px", mr: 2 }}>
              Close
            </Button>
            <WhiteButton onClick={onDeleteDocument} label="Confirm" width="150px" />
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
}
