import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, TableHead, TextField, Typography } from "@mui/material";
import { SearchOutlined } from "@material-ui/icons";
import WhiteButton from "../../components/WhiteButton";
import CustomDateRangePicker from "../../components/date-range-picker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import Wallet from "../../services/wallet";
import CustomRangePagination from "../../components/RangePagination";
import EmptyRecord from "../../components/EmptyRecord";
import NoItems from "../../components/NoItems";
import CloseIcon from "@mui/icons-material/Close";
import useResponsive from "../../utils/responsive";
import SVG from "react-inlinesvg";
import { copyAddressToClipboard } from "../../utils/utils";
import { ToastMessage } from "../../const";
import copy from "../../assets/icons/copy.svg";

const sortcolumn = [
  {
    id: "rank",
    label: "#",
    width: "1%",
  },
  {
    id: "walletID",
    label: "Wallet address",
    width: "50%",
  },
  {
    id: "walletName",
    label: "Wallet name",
    width: "10%",
  },
  {
    id: "createdDate",
    label: "Created date",
    width: "8%",
    textAlign: "center",
  },
];
const nonSortColumn = [
  {
    id: "transactionDate",
    label: "Last transaction date",
    width: "8%",
    textAlign: "center",
  },
  {
    id: "nftsOwned",
    width: "15%",
    label: "NFTs owned",
    textAlign: "center",
  },
];

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
  },
  svgIcon: {
    "& path": {
      stroke: themeColors.primary,
    },
  },
  copyIcon: {
    height: 20,
    width: 20,
    cursor: "pointer",
    marginLeft: 5,
  },
});
export default function AdminWallet() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [listWallet, setListWallet] = useState<any>([]);
  const [meta, setMeta] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectedDate, setSelectedDate] = useState<any[]>([null, null]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isSearch, setIsSearch] = useState(false);
  const { isSmallDesktop, isDesktopOrLaptop } = useResponsive();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const getListWallet = async (params?: any) => {
    const res = await Wallet.getAdminListWallet({
      name: search,
      page: params?.page ?? page,
      limit: params?.rowsPerPage ?? rowsPerPage,
      startDate: selectedDate[0] ? moment(selectedDate[0]).toISOString() : undefined,
      endDate: selectedDate[1] ? moment(selectedDate[1]).toISOString() : undefined,
    });
    if (res.statusCode === 200) {
      setMeta(res.data.meta);
      setListWallet(res.data.items);
    }
  };

  useEffect(() => {
    getListWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleSearchChange = (e: any) => {
    setIsSearch(true);
    setSearch(e.target.value);
  };

  const onClearFilter = async () => {
    setSearch("");
    setEndDate("");
    setStartDate("");
    setSelectedDate([null, null]);
    const res = await Wallet.getAdminListWallet({
      name: "",
      page: 0,
      limit: 20,
      startDate: null,
      endDate: null,
    });
    if (res.statusCode === 200) {
      setListWallet(res.data.items);
      setMeta(res.data.meta);
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>Wallet List </span>
      <Box component={"div"} marginTop={4}>
        <Box component={"div"} id="filter">
          <Grid marginBottom={3}>
            <Box component={"div"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
              <h2>List of wallets in DVOLD</h2>
            </Box>
          </Grid>
          <Grid container alignItems={"center"} gap={5}>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: "bold" }}>Search &nbsp;</Typography>
              <TextField
                label={search === "" ? "Search by Wallet name" : ""}
                fullWidth
                sx={{
                  marginTop: 1,
                  "& label": {
                    marginLeft: 4,
                    "&.Mui-focused": {
                      color: "#B3B3B3",
                    },
                  },
                }}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ),
                  endAdornment: search && (
                    <IconButton onClick={() => setSearch("")}>
                      <CloseIcon />
                    </IconButton>
                  ),
                }}
                size="small"
                onChange={(e) => {
                  handleSearchChange(e);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    getListWallet();
                  }
                }}
                value={search}
              ></TextField>
            </Grid>

            <Grid item>
              <Box component={"div"}>
                <Typography sx={{ fontWeight: "bold" }}>Created date &nbsp;</Typography>
                <CustomDateRangePicker
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={handleDateChange}
                  className={classes.dateRangePicker}
                />
              </Box>
            </Grid>
            {isDesktopOrLaptop && (
              <Grid item>
                <Box component={"div"}>
                  <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                  <WhiteButton
                    label="Clear all filters"
                    onClick={() => {
                      onClearFilter();
                    }}
                    width="150px"
                    sx={{ fontSize: 16 }}
                  />
                </Box>
              </Grid>
            )}
            {isDesktopOrLaptop && (
              <Grid item>
                <Box component={"div"}>
                  <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                  <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getListWallet}>
                    Search
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          {isSmallDesktop && (
            <Box component={"div"} display={"flex"}>
              <Box component={"div"} mr={2}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                <WhiteButton
                  label="Clear all filters"
                  onClick={() => {
                    onClearFilter();
                  }}
                  width="150px"
                  sx={{ fontSize: 16 }}
                />
              </Box>
              <Box component={"div"}>
                <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
                <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getListWallet}>
                  Search
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Box component={"div"} mt={5}>
          <TableContainer
            style={{
              maxHeight: `calc(100vh - (${document?.getElementById("filter")?.clientHeight}px + 300px)) `,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {sortcolumn.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ width: column.width }}
                      sx={{ textAlign: column?.textAlign ?? "left" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {nonSortColumn.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.width }}
                      sx={{ textAlign: column?.textAlign ?? "left" }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listWallet?.length > 0 &&
                  listWallet.map((row: any, index: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{(meta?.currentPage - 1) * meta?.itemsPerPage + index + 1}</TableCell>
                        <TableCell sx={{ cursor: "pointer", color: "#518CE0" }}>
                          <Box
                            component={"div"}
                            display={"flex"}
                            alignItems="center"
                            justifyContent={"space-between"}
                            mt={1}
                          >
                            <Box
                              component={"div"}
                              onClick={() => {
                                navigate(links.WALLET_DETAILS, { state: { itemDetails: row } });
                              }}
                            >
                              {row?.address}
                            </Box>
                            <SVG
                              className={classes.copyIcon}
                              src={copy}
                              onClick={() => copyAddressToClipboard(row?.address, ToastMessage.WalletCoppied)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{moment(row.createdAt).format("MM-DD-YYYY")}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{moment(row.updatedAt).format("MM-DD-YYYY")}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{row.countItems}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <CustomRangePagination
          meta={meta}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          onPageChange={handleChangePage}
        />
        {listWallet.length === 0 && !isSearch && <EmptyRecord />}
        {listWallet.length === 0 && isSearch && <NoItems />}
      </Box>
    </Box>
  );
}
