import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { themeColors } from "../../theme/schemes/PureLightTheme";

import moment from "moment";
import { CreatedStatus, InDraftStatus, MintedStatus, MintingStatus } from "../mintStatus";
import { MintStatus, WalletDetailsTabs } from "../../const";
import { useNavigate } from "react-router-dom";
import useResponsive from "../../utils/responsive";
import DoneIcon from "../../assets/icons/done.svg";
import SVG from "react-inlinesvg";

interface IBoughtItems {
  itemDetails?: any;
  hasStatus?: boolean;
  mintTab?: string;
}

const useStyles = makeStyles({
  detailsContainer: {
    border: `1px solid ${themeColors.lighterGray1}`,
    borderRadius: 16,

    padding: 10,
    marginTop: 20,
    marginRight: 20,
  },
  img: {
    height: "160px",
    objectFit: "cover",
    width: "inherit",
    borderRadius: 16,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BoughtItems = ({ itemDetails, hasStatus = false, mintTab }: IBoughtItems) => {
  const classes = useStyles();
  const { isDesktopOrLaptop } = useResponsive();

  const statusRender = (e: string) => {
    if (e === MintStatus.InDraft) {
      return <InDraftStatus />;
    }
    if (e === MintStatus.Created) {
      return <CreatedStatus />;
    }
    if (e === MintStatus.Minting) {
      return <MintingStatus />;
    }
    return <MintedStatus />;
  };
  const navigate = useNavigate();

  return (
    <Box
      component={"div"}
      className={classes.detailsContainer}
      display="flex"
      flexDirection={"column"}
      justifyContent={"space-between"}
      sx={{ width: isDesktopOrLaptop ? "20%" : "30%" }}
    >
      <Box
        component={"div"}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/nft-details/${itemDetails?.id}?tab=${mintTab}`, { state: { mintTab: mintTab } });
        }}
      >
        <Box component={"div"} sx={{ height: 160, width: "100%" }}>
          <img className={classes.img} src={itemDetails?.coverImage} alt="" />
        </Box>

        <Typography variant="h4" mb={1} mt={1} sx={{ fontSize: 20 }} display="flex" alignItems="center" gap={0.5}>
          {itemDetails?.name} {mintTab === WalletDetailsTabs.minted && <SVG src={DoneIcon} />}
        </Typography>
        <Typography variant="subtitle1" mb={3}>
          {itemDetails?.description}
        </Typography>
      </Box>
      {hasStatus && (
        <Box component={"div"} display="flex" flexDirection="column">
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h4"> Mint date </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1">
                {itemDetails.mintedAt ? moment(itemDetails.mintedAt).format("MM/DD/YYYY") : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" mt={2} mb={2}>
            <Grid item xs={6}>
              <Typography variant="h4"> Status </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1"> {statusRender(itemDetails.status)}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box component={"div"} display="flex" justifyContent={"space-between"}>
        <Typography sx={{ fontSize: 12 }}>
          Created date: {moment(itemDetails?.createdAt).format("MM-DD-YYYY")}
        </Typography>
      </Box>
    </Box>
  );
};
export default BoughtItems;
