import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import CustomTextField from "../../components/CustomTextField";
import Wallet from "../../services/wallet";
import { themeColors } from "../../theme/schemes/PureLightTheme";

const useStyles = makeStyles({
  input: {
    width: "60% !important",
  },
});
export default function WalletDetails() {
  const classes = useStyles();
  const location = useLocation();
  const [balance, setBalance] = useState(0);

  const getBalance = async () => {
    const res = await Wallet.getWalletBalance({ id: location?.state?.itemDetails?.id });
    if (res.statusCode === 200) {
      setBalance(res.data[0].quantity);
    }
  };
  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>
        Wallet List &nbsp;&nbsp;&gt;&nbsp;&nbsp;<span style={{ color: themeColors.gray }}>Wallet detail</span>
      </span>
      <Typography variant="h3" mt={2} mb={1}>
        Information
      </Typography>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={`${location?.state?.itemDetails?.user?.firstName} ${location?.state?.itemDetails?.user?.lastName}`}
          label="Wallet owner"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={location?.state?.itemDetails?.user?.id}
          label="Wallet owner ID"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={location?.state?.itemDetails?.name}
          label="Wallet name"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={`${location?.state?.itemDetails?.address?.slice(
            0,
            20,
          )}...${location?.state?.itemDetails?.address?.slice(-20)}`}
          label="Wallet address"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={moment(location?.state?.itemDetails?.createdAt).format("DD/MM/YYYY")}
          label="Created date"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={location?.state?.itemDetails?.countTransactions}
          label="Number of transactions"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={moment(location?.state?.itemDetails?.lastTransaction?.createdAt).format("DD/MM/YYYY") ?? ""}
          label="Last transaction date"
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled
          className={classes.input}
          value={balance ?? 0}
          label="Balance (ADA)"
        ></CustomTextField>
      </Grid>
    </Box>
  );
}
