/* eslint-disable react/no-unescaped-entities */
import { Grid } from "@mui/material";
import { LeftNFTDetails } from "../../../components/LeftNFTDetails";

const MyFTs = ({ title, walletDetails }: { title: string; walletDetails: any }) => {
  return (
    <Grid container>
      <Grid item xs={2}>
        <LeftNFTDetails title={title} price={"10 ADA"} hasMintBtn={false} walletDetails={walletDetails} isNT={true} />
      </Grid>
    </Grid>
  );
};
export default MyFTs;
