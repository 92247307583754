import { Box, Button, Typography } from "@mui/material";
import successIcon from "../../assets/icons/check-one.svg";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import AuthenticateLayout from "../../components/AuthenticateLayout";
import { useEffect, useState } from "react";
import Users from "../../services/users";

const VerifycationSuccessfully = () => {
  const navigate = useNavigate();
  const [isVerified, setisVerified] = useState(false);
  const token = window.location.href.split("=").pop();
  useEffect(() => {
    if (isVerified) {
      setTimeout(() => {
        window.location.href = "/login";
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified]);

  const verifyToken = async () => {
    const res = await Users.verifyToken({ token: token });
    if (res.statusCode === 200) {
      setisVerified(true);
    }
  };

  useEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVerified ? (
    <AuthenticateLayout>
      <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
        <SVG src={successIcon} />
        <Typography
          variant="h3"
          sx={{
            mb: 2,
            mt: 2,
          }}
        >
          Congratulations!
        </Typography>
        <Typography
          variant="h4"
          color="text.secondary"
          fontWeight="normal"
          sx={{ textAlign: "center", marginBottom: 2 }}
        >
          We have successfully verified your new email.
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          You will be transferred to the next page in a few moments. If you
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          are not automatically transferred, please press the <b>“Next”</b> button below.
        </Typography>

        <Button variant="contained" sx={{ marginTop: 4 }} onClick={() => navigate("/login")}>
          Back to Sign in
        </Button>
      </Box>
    </AuthenticateLayout>
  ) : (
    <h1>Page not found</h1>
  );
};

export default VerifycationSuccessfully;
