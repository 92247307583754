import { Box, Typography, styled, Button, TextField, InputAdornment, IconButton, Checkbox } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { emailValidation } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import Authenticate from "../../../services/authenticate";
import { useUser } from "../../../contexts/user-provider";
import { links } from "../../../constants/links";
import { ROLE } from "../../../routes/config";

export const CustomLink = styled("span")({
  color: "#518ce0",
  textDecoration: "underline",
  cursor: "pointer",
});

const Login = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [errMsg, setErrMsg] = useState("");
  const [isSaveUser, setIsSaveUser] = useState(!!localStorage.getItem("coporateUserName"));
  const [account, setAccount] = useState({
    userName: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("coporateUserName")) {
      setAccount({ ...account, userName: localStorage.getItem("coporateUserName")!! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignin = async () => {
    emailValidation(account.userName);
    try {
      const res = await Authenticate.login({ email: account.userName, password: account.password });
      if (res.statusCode === 200) {
        setUser(res.data);
        localStorage.setItem("access_token", res.data.jwtAccessToken);
        localStorage.setItem("account", JSON.stringify(res.data));
        if (isSaveUser) {
          localStorage.setItem("coporateUserName", account.userName);
        } else {
          localStorage.removeItem("coporateUserName");
        }
        if (res.data.role === ROLE.ADMIN) {
          navigate(links.LIST_USER);
        } else if (res.data.role === ROLE.CORPORATE) {
          navigate(links.WALLET);
        }
      }
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }
  };
  const onSignUp = () => {
    navigate("/sign-up");
  };
  return (
    <>
      <AuthenticateLayout>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography
            variant="h2"
            sx={{
              mb: 1,
            }}
          >
            Sign in
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal">
            Welcome to DVOLD! Please login to access exclusive features for
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" marginBottom={3}>
            corporate users.
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: "bold" }}>Email</Typography>
        <TextField
          id="dvold-email"
          name="dvold-email"
          placeholder="Enter your email"
          autoComplete="new-email"
          fullWidth
          sx={{
            marginTop: 1,
            marginBottom: 3,
            "& label": {
              "&.Mui-focused": {
                color: "#B3B3B3",
              },
            },
          }}
          defaultValue={localStorage.getItem("coporateUserName") ?? ""}
          InputLabelProps={{ shrink: false }}
          onChange={(e) => setAccount({ ...account, userName: e.target.value })}
          error={emailValidation(account.userName) === "Invalid email"}
          helperText={emailValidation(account.userName) === "Invalid email" ? "Invalid email address" : ""}
          inputProps={{ autocomplete: "new-email" }}
        ></TextField>
        <Typography sx={{ fontWeight: "bold" }}>Password</Typography>

        <TextField
          placeholder="Enter your password"
          id="dvold-password"
          name="dvold-password"
          autoComplete="new-password"
          type={showPassword ? "text" : "password"}
          fullWidth
          sx={{
            marginTop: 1,
            "& label": {
              "&.Mui-focused": {
                color: "#B3B3B3",
              },
            },
          }}
          InputLabelProps={{ shrink: false }}
          value={account.password}
          onChange={(e) => setAccount({ ...account, password: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ autocomplete: "new-password" }}
        ></TextField>
        <Typography sx={{ color: "red" }}>{errMsg}</Typography>

        <Box component={"div"} my={4} display={"flex"} justifyContent={"space-between"} alignItems="center">
          <Box
            component={"div"}
            display={"flex"}
            alignItems="center"
            onClick={() => setIsSaveUser(!isSaveUser)}
            sx={{ cursor: "pointer" }}
          >
            <Checkbox checked={isSaveUser} />
            <Typography>Remember me</Typography>
          </Box>
          <Box component={"div"}>
            <CustomLink
              onClick={() =>
                navigate(links.FORGOT_PASSWORD, { state: { previousLink: window.location.href, forgetPassword: true } })
              }
            >
              Forgot password?
            </CustomLink>
          </Box>
        </Box>
        <Box component={"div"} display={"flex"} justifyContent="center">
          <Button
            variant="contained"
            sx={{ width: 160 }}
            disabled={account.password === "" || account.userName === ""}
            onClick={onSignin}
          >
            Sign in
          </Button>
        </Box>
        <Box component={"div"} display={"flex"} justifyContent="center" mt={3}>
          Don’t have an account yet?&nbsp; <CustomLink onClick={onSignUp}> Sign up</CustomLink>
        </Box>
      </AuthenticateLayout>
    </>
  );
};

export default Login;
