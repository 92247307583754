/* eslint-disable max-len */
import { Box, Typography, styled, Button, TextField, InputAdornment, IconButton, Checkbox } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { emailValidation } from "../../../utils/utils";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import Authenticate from "../../../services/authenticate";
import SVG from "react-inlinesvg";
import successIcon from "../../../assets/icons/check-one.svg";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../contexts/user-provider";
import { links } from "../../../constants/links";
import { ROLE } from "../../../routes/config";

const CustomLink = styled("span")({
  color: "#518ce0",
  textDecoration: "underline",
  cursor: "pointer",
});

const AdminLogin = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  const [account, setAccount] = useState({
    userName: "",
    password: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isSaveUser, setIsSaveUser] = useState(!!localStorage.getItem("adminUserName"));

  useEffect(() => {
    if (localStorage.getItem("adminUserName")) {
      setAccount({ ...account, userName: localStorage.getItem("adminUserName")!! });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignin = async () => {
    emailValidation(account.userName);
    try {
      const res = await Authenticate.login({ email: account.userName, password: account.password });
      if (res.statusCode === 200) {
        setIsShow(true);
        setUser(res.data);
        localStorage.setItem("access_token", res.data.jwtAccessToken);
        localStorage.setItem("account", JSON.stringify(res.data));
        if (isSaveUser) {
          localStorage.setItem("adminUserName", account.userName);
        } else {
          localStorage.removeItem("adminUserName");
        }
        if (res.data.role === ROLE.ADMIN) {
          navigate(links.LIST_USER);
        } else if (res.data.role === ROLE.CORPORATE) {
          navigate(links.WALLET);
        }
      }
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    if (isShow) {
      setTimeout(() => {
        window.location.href = links.LIST_USER;
      }, 2000);
    }
  }, [isShow]);

  const SuccessScreen = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <SVG src={successIcon} />
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Logged in successfully
          </Typography>
        </Box>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          You will automatilly be tranfer to the homepage in a moment....
        </Typography>
        <br />
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          If you have waited more than a minute and you are still seeing this message, please click on the <b>“Next”</b>{" "}
          button below.
        </Typography>
        <Box component={"div"} display={"flex"} justifyContent="center">
          <Button
            variant="contained"
            onClick={() => {
              navigate(links.LIST_USER);
            }}
            sx={{ marginTop: 3, marginBottom: 3, color: "white", width: 160 }}
          >
            Next
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <AuthenticateLayout>
        {isShow ? (
          <SuccessScreen />
        ) : (
          <>
            <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                }}
              >
                Sign in
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: "bold" }}>Email</Typography>

            <TextField
              placeholder="Enter your email"
              fullWidth
              name="email-sign-in"
              autoComplete="off"
              sx={{
                marginTop: 1,
                marginBottom: 3,
                "& label": {
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => {
                setAccount({ ...account, userName: e.target.value });
                setErrMsg("");
              }}
              inputProps={{ style: { width: 450 }, autocomplete: "off" }}
              error={emailValidation(account.userName) === "Invalid email"}
              helperText={emailValidation(account.userName) === "Invalid email" ? "Invalid email address" : ""}
              defaultValue={localStorage.getItem("adminUserName") ?? ""}
            ></TextField>
            <Typography sx={{ fontWeight: "bold" }}>Password</Typography>

            <TextField
              placeholder="Enter your password"
              id="outlined-adornment-password"
              autoComplete="off"
              defaultValue=""
              name="password-sign-in"
              type={showPassword ? "text" : "password"}
              fullWidth
              sx={{
                marginTop: 1,
                "& label": {
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
              }}
              InputLabelProps={{ shrink: false }}
              onChange={(e) => {
                setAccount({ ...account, password: e.target.value });
                setErrMsg("");
              }}
              inputProps={{ autocomplete: "off" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Typography sx={{ color: "red" }}>{errMsg}</Typography>

            <Box component={"div"} my={4} display={"flex"} justifyContent={"space-between"} alignItems="center">
              <Box
                component={"div"}
                display={"flex"}
                alignItems="center"
                onClick={() => setIsSaveUser(!isSaveUser)}
                sx={{ cursor: "pointer" }}
              >
                <Checkbox checked={isSaveUser} />
                <Typography>Remember me</Typography>
              </Box>
              <Box component={"div"}>
                <CustomLink
                  onClick={() =>
                    navigate(links.FORGOT_PASSWORD, {
                      state: { previusLink: window.location.href },
                    })
                  }
                >
                  Forgot password?
                </CustomLink>
              </Box>
            </Box>
            <Box component={"div"} display={"flex"} justifyContent="center">
              <Button
                variant="contained"
                sx={{ width: 160 }}
                disabled={account.password === "" || account.userName === ""}
                onClick={onSignin}
              >
                Sign in
              </Button>
            </Box>
          </>
        )}
      </AuthenticateLayout>
    </>
  );
};

export default AdminLogin;
