/* eslint-disable max-len */
import axiosClient from "../../api/axiosClient";

const MintingNft = {
  getCategory: (mainCategoryId: string) => {
    return axiosClient.get("/categories", { params: { mainCategoryId } });
  },
  getCategories: () => {
    return axiosClient.get("/categories");
  },
  getAttributeNFTByCategoryId: (categoryId: string) => {
    return axiosClient.get(`/attributes/list?categoryId=${categoryId}`);
  },
  createMintNft: (data: any) => {
    return axiosClient.post("/items/create-nft", data);
  },
  estimateGas: (params: any) => {
    return axiosClient.get(`/items/estimate-gas?walletAddress=${params?.walletAddress}`);
  },
  getPriceADA: () => {
    return axiosClient.get("/wallet/exchange-ada-usd");
  },
  requestMintNFT: (body: any) => {
    return axiosClient.post("/items/request-mint-nft", body);
  },
  updateNft: (id: string, body: any) => {
    return axiosClient.put(`/items/${id}/edit-nft`, body);
  },
  deleteNft: (id: string) => {
    return axiosClient.delete(`/items/${id}/`);
  },
  burnNft: (body: any) => {
    return axiosClient.post("/items/burn-nft", body);
  },
  estimateFee: (params: any) => {
    return axiosClient.get(
      `/items/estimate-gas-send-nft?walletAddress=${params?.walletAddress}&receiveAddress=${params?.receiveAddress}&itemIds=${params?.itemIds}`,
    );
  },
};

export default MintingNft;
