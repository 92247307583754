import axiosClient from "../../api/axiosClient";
import { getPage } from "../../utils/utils";

const ServicesManagment = {
  createService(params?: any): Promise<any> {
    const url = "/services/create-or-update";
    return axiosClient.post(url, params);
  },
  getListService(params?: any): Promise<any> {
    const page = getPage(params);
    const url = `/services/list-service?page=${page ?? 1}&limit=${params?.limit ?? 5}&${`${
      params?.serviceType && `serviceType=${params?.serviceType}`
    }`}&${`${params?.categoryId && `categoryId=${params?.categoryId}`}`}&${`${
      params?.subCategoryId && `subCategoryId=${params?.subCategoryId}`
    }`}&${`${params?.startDate && `startDate=${params?.startDate}`}`}&${`${
      params?.endDate && `endDate=${params?.endDate}`
    }`}`;
    return axiosClient.get(url);
  },
  getServiceDetail(params?: any): Promise<any> {
    const url = `/services/detail-service?serviceId=${params.serviceId}`;
    return axiosClient.get(url);
  },
  deleteService(params?: any): Promise<any> {
    const url = `/services/delete-service/${params.serviceId}`;
    return axiosClient.delete(url);
  },
};

export default ServicesManagment;
