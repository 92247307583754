import React, { useContext, useState } from "react";
import { localStorageHelper } from "../helpers";
import { MayBe } from "../types";
import { Props } from "../utils/utils";

export const UserContext = React.createContext<MayBe<any>>(null);

export const useUser = (): MayBe<any> => useContext(UserContext);

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<MayBe<any>>(localStorageHelper.getItem("account", { isObject: true }));
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
