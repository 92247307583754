import axiosClient from "../../api/axiosClient";

const Wallet = {
  getListWallet(params?: any): Promise<any> {
    const page = !params?.page ? 1 : params?.page + 1;

    const url = `/wallet/list?page=${page ?? 1}&limit=${params?.limit ?? 10}&${params?.name && `name=${params?.name}`}`;
    return axiosClient.get(url);
  },
  deleteWallet(params?: any): Promise<any> {
    const url = `/wallet/${params?.id}`;
    return axiosClient.delete(url);
  },
  createWallet(body: { name: string; password: string }): Promise<any> {
    const url = "/wallet/create";
    return axiosClient.post(url, body);
  },
  confirmSeedPhrase(body: { id: string; seed_phrase: string[] }): Promise<any> {
    const url = "/wallet/confirm-seed-phrase";
    return axiosClient.post(url, body);
  },
  getAdminListWallet(params?: any): Promise<any> {
    const page = !params?.page ? 1 : params?.page + 1;
    const url = `/wallet/admin/list?page=${page ?? 1}&limit=${params?.limit ?? 10}&${
      params?.name && `name=${params?.name}`
    }&${`${params?.startDate && `startDate=${params?.startDate}`}`}&${`${
      params?.endDate && `endDate=${params?.endDate}`
    }`}`;
    return axiosClient.get(url);
  },
  recoverWallet(body: { name: string; password: string; seed_phrase: string[] }): Promise<any> {
    const url = "/wallet/recover";
    return axiosClient.post(url, body);
  },
  getWalletBalance(params?: any): Promise<any> {
    const url = `/wallet/wallet-balance/${params?.id}`;
    return axiosClient.get(url);
  },
};

export default Wallet;
