import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Collapse, Menu, MenuItem, Tooltip } from "@mui/material";
import SVG from "react-inlinesvg";
import wallet from "../../assets/icons/wallet.svg";
import vector from "../../assets/icons/setting-two.svg";
import filetext from "../../assets/icons/FileText.svg";
import filetxt from "../../assets/icons/file-txt.svg";
import headset from "../../assets/icons/Headset.svg";
import peoples from "../../assets/icons/peoples.svg";
import question from "../../assets/icons/Question.svg";
import profile from "../../assets/icons/User.svg";
import frame from "../../assets/icons/frame.svg";
import wrench from "../../assets/icons/process-management.svg";
import { useState } from "react";
import { useUser } from "../../contexts/user-provider";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import DVOLDLogo from "../../assets/logos/DVOLD_def 1.png";
import DVOLD from "../../assets/logos/Frame 625843.png";
import { Props } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import { role } from "../../const";
import { themeColors } from "../../theme/schemes/PureLightTheme";
const drawerWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const HeaderLogoContainer = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

interface ChildrenProps {
  setPaddingLayout?: React.Dispatch<React.SetStateAction<number | null>>;
  [k: string]: any;
}

export const DashBoardLayout: React.FC<Props> = ({ children }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const settings = ["Logout"];
  const [paddingLayout, setPaddingLayout] = useState<number | null>(null);

  const AdminDrawer = [
    {
      name: "Corporate User List",
      svgIcon: profile,
      svgFill: themeColors.gray,
      path: links.LIST_USER,
      subPath: [links.USER_DETAIL],
    },
    {
      name: "Wallet List",
      svgIcon: wallet,
      path: links.WALLET_LIST,
      svgFill: "transparent",
      subPath: [links.WALLET_DETAILS],
    },
    {
      name: "NFT List",
      svgIcon: vector,
      path: links.LIST_NFT,
      svgFill: themeColors.gray,
      subPath: [links.NFT_DETAILS],
    },
    {
      name: "NFT Service",
      svgIcon: headset,
      svgFill: "transparent",
      path: links.NFT_SERVICE,
    },
    {
      name: "Manage Banners",
      svgIcon: frame,
      svgFill: "transparent",
      path: links.LIST_BANNERS,
    },
    {
      name: "Process Management",
      svgIcon: wrench,
      svgFill: themeColors.gray,
      path: links.PROCESS_MANAGEMENT,
    },
  ];

  const staticPages = [
    {
      name: "Terms",
      svgIcon: filetext,
      svgFill: "transparent",
      path: links.TERMS,
    },
    {
      name: "About us",
      svgIcon: peoples,
      svgFill: "transparent",
      path: links.ABOUT_US,
    },
    {
      name: "FAQ",
      svgIcon: question,
      svgFill: "transparent",
      path: links.FAQ,
    },
  ];

  const CorporateDrawer = [
    {
      name: "Wallet",
      svgIcon: wallet,
      svgFill: "transparent",
      path: links.WALLET,
    },
    {
      name: "Settings",
      svgIcon: vector,
      svgFill: themeColors.gray,
      path: links.SETTINGS,
    },
    {
      name: "Process Management",
      svgIcon: wrench,
      svgFill: themeColors.gray,
      path: links.PROCESS_MANAGEMENT,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: any) => {
    if (e.target.innerHTML === "Logout") {
      localStorage.removeItem("walletInfo");
      localStorage.removeItem("access_token");
      if (JSON.parse(localStorage.getItem("account")!).role === "admin") {
        window.location.href = "/admin-login";
      } else {
        window.location.href = "/login";
      }
      localStorage.removeItem("account");
    }

    setAnchorElUser(null);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setPaddingLayout } as ChildrenProps);
    }
    return child;
  });

  return (
    <Box component={"div"} sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ height: 64 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box component={"div"} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <HeaderLogoContainer>
              <img src={DVOLDLogo} alt="" style={{ marginRight: 10, marginLeft: open ? 20 : 0 }} />

              <img src={DVOLD} alt="" />
            </HeaderLogoContainer>
            <Box component={"div"} sx={{ flexGrow: 0, marginRight: 5 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="DVOLD" src={user?.avatar || "/static/images/avatar/2.jpg"} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{
                  mt: "45px",
                  "& .MuiMenu-paper": {
                    padding: 0,
                  },
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={(e: any) => handleCloseUserMenu(e)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ width: "100%", maxWidth: 360 }} component="nav" aria-labelledby="nested-list-subheader">
          {user.role === role.corporate && (
            <>
              {CorporateDrawer.map((x: any, index: number) => {
                const path = window.location.pathname.split("/")[1];
                const itemPath = x?.path?.substring(1);
                const isSubPath = x?.subPath?.includes(`/${path}`);

                return (
                  <ListItemButton
                    key={index}
                    onClick={() => navigate(x.path)}
                    sx={{
                      backgroundColor: path === itemPath || isSubPath ? themeColors.primary : "transparent !important",
                      borderRadius: 20,
                      paddingLeft: "14px",
                      color: path === itemPath || isSubPath ? themeColors.white : themeColors.gray,
                      display: "flex",
                    }}
                  >
                    <ListItemIcon>
                      <Box
                        component={"div"}
                        sx={{
                          "& .svgIcon": {
                            "& path": {
                              stroke: themeColors.white,
                            },
                          },
                          display: "flex",
                        }}
                      >
                        <SVG
                          src={x.svgIcon}
                          fill={path === itemPath || isSubPath ? "transparent" : x?.svgFill}
                          className={path === itemPath || isSubPath ? "svgIcon" : ""}
                        />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={x.name}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: path === itemPath || isSubPath ? themeColors.white : themeColors.black,
                        },
                      }}
                    />
                  </ListItemButton>
                );
              })}
            </>
          )}
          {user.role === role.admin && (
            <>
              {AdminDrawer.map((x: any, index: number) => {
                const path = window.location.pathname.split("/")[1];
                const itemPath = x?.path?.substring(1);
                const isSubPath = x?.subPath?.includes(`/${path}`);
                return (
                  <ListItemButton
                    key={index}
                    onClick={() => navigate(x.path)}
                    sx={{
                      backgroundColor: path === itemPath || isSubPath ? themeColors.primary : "transparent !important",
                      borderRadius: 20,
                      paddingLeft: "14px",
                      color: path === itemPath || isSubPath ? themeColors.white : themeColors.gray,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: open ? "30px !important" : "56px" }}>
                      <Box
                        component={"div"}
                        sx={{
                          "& .svgIcon": {
                            "& path": {
                              stroke: themeColors.white,
                            },
                          },
                          display: "flex",
                        }}
                      >
                        <SVG
                          src={x.svgIcon}
                          fill={path === itemPath || isSubPath ? "transparent" : x?.svgFill}
                          className={path === itemPath || isSubPath ? "svgIcon" : ""}
                        />
                      </Box>
                    </ListItemIcon>
                    <ListItemText
                      primary={x.name}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color: path === itemPath || isSubPath ? themeColors.white : themeColors.black,
                        },
                      }}
                    />
                  </ListItemButton>
                );
              })}

              <ListItemButton onClick={() => setExpand(!expand)}>
                <ListItemIcon sx={{ minWidth: open ? "30px !important" : "56px" }}>
                  <SVG src={filetxt} />
                </ListItemIcon>
                <ListItemText primary={"Static Pages"} />
                {expand ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={expand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {staticPages.map((x: any, index: number) => {
                    const path = window.location.pathname.split("/")[1];
                    const itemPath = x?.path?.substring(1);
                    return (
                      <ListItemButton
                        key={index}
                        onClick={() => navigate(x.path)}
                        sx={{
                          backgroundColor: path === itemPath ? themeColors.primary : "transparent !important",
                          borderRadius: 20,
                          paddingLeft: "14px",
                          color: path === itemPath ? themeColors.white : themeColors.gray,
                          marginLeft: open ? 2 : 0,
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: open ? "30px !important" : "56px" }}>
                          <Box
                            component={"div"}
                            sx={{
                              "& .svgIcon": {
                                "& path": {
                                  stroke: themeColors.white,
                                },
                              },
                              display: "flex",
                            }}
                          >
                            <SVG
                              src={x.svgIcon}
                              fill={path === itemPath ? "transparent" : x?.svgFill}
                              className={path === itemPath ? "svgIcon" : ""}
                            />
                          </Box>
                        </ListItemIcon>
                        <ListItemText
                          primary={x.name}
                          sx={{
                            "& .MuiListItemText-primary": {
                              color: path === itemPath ? themeColors.white : themeColors.black,
                            },
                          }}
                        />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            </>
          )}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: paddingLayout ?? 3 }}>
        <DrawerHeader />
        {childrenWithProps}
      </Box>
    </Box>
  );
};
