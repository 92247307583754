import { Typography, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dropzone from "react-dropzone-uploader";
import { themeColors } from "../../theme/schemes/PureLightTheme";

interface ICustomDropzone {
  totalFile?: number;
  className?: any;
  handleChangeStatus?: any;
  handleSubmit?: any;
}
const useStyles = makeStyles({
  dropzoneContainer: {
    "& .dzu-inputLabel": {
      fontSize: "16px",
      color: "#B3B3B3",
      paddingBottom: "5%",
    },
    "& .dzu-submitButtonContainer": {
      display: "none",
    },
  },
  buttonDropzone: {
    position: "absolute",
    bottom: "15%",
    marginLeft: "auto",
    marginRight: "auto",
    left: 0,
    right: 0,
    textAlign: "center",
    "& button": {
      background: themeColors.blue,
      color: themeColors.white,
    },
  },
});

const CustomDropzone = (props: ICustomDropzone) => {
  const { totalFile, handleSubmit, handleChangeStatus } = props;
  const classes = useStyles();

  return (
    <Box component={"div"} sx={{ width: "100%", position: "relative" }} className={classes.dropzoneContainer}>
      <Box
        component={"div"}
        className={classes.dropzoneContainer}
        position={"absolute"}
        sx={{ width: "92%", height: "100%" }}
        onClick={() => {
          (document.getElementsByClassName("dzu-input")[0] as HTMLElement).click();
        }}
      ></Box>

      {totalFile === 0 && (
        <Box component={"div"} className={classes.buttonDropzone}>
          <Typography variant="h4" sx={{ color: themeColors.lighterGray, mb: "5px" }}>
            or
          </Typography>
          <Button>Select from your device</Button>
        </Box>
      )}
      <Dropzone
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        styles={{ dropzone: { minHeight: 150, maxHeight: 150 } }}
        submitButtonContent={null}
        inputContent="Drag your company’s business licenses for verification"
      />
    </Box>
  );
};

export default CustomDropzone;
