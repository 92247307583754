export const links = {
  LOGIN: "/login",
  ADMIN_LOGIN: "/admin-login",
  FORGOT_PASSWORD: "/forget-password",
  SIGN_UP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  WATCHLIST: "/watchlist",
  DASHBOARD: "/dashboard",
  EMAIL_VERIFICATION: "/email-verification",
  SETUP_PASSWORD: "/setup-password",
  SETUP_INFORMATION: "/setup-information",
  VERIFY_SUCCESS: "/verify-email",
  LIST_USER: "/list-user",
  USER_DETAIL: "/user-detail/:id",
  LIST_NFT: "/list-nft",
  NFT_DETAIL: "/nft-details/:id",
  NFT_DETAIL_PREVIEW: "/nft-preview/:id",
  NFT_DETAILS: "/nft-details",
  WALLET_LIST: "/wallet-list",
  NFT_SERVICE: "/nft-service",
  NFT_SERVICE_DETAILS: "/nft-service-details/:id",
  TERMS: "/terms",
  ABOUT_US: "/about-us",
  FAQ: "/faq",
  WALLET: "/wallet",
  WALLET_DETAILS: "/wallet-details",
  SETTINGS: "/settings",
  MY_NFTS: "/my-nfts",
  SEND_NFT: "/send-nft",
  MINTING_NFT: "/minting-nft",
  LIST_BANNERS: "/banners",
  OFFER_DETAILS: "/offer-details/:id",
  PROCESS_MANAGEMENT: "/process-management"
};
