import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BannerState, FileType } from ".";
import { Loading } from "../../components";
import CustomDateRangePicker from "../../components/date-range-picker";
import ErrorMessage from "../../components/ErrorMessage";
import CustomModal from "../../components/modal";
import WhiteButton from "../../components/WhiteButton";
import { links } from "../../constants/links";
import Banner, { BannerStatus, BannerType } from "../../services/banner";
import Users from "../../services/users";
import { inValidSizeFile } from "../../utils/inValidSizeFile";
import Countries from "./components/Countries";
import UploadFile from "./components/UploadFile";

interface IEditBannerProps {
  bannerStatus?: string;
  bannerDetailId: string;
  setBannerStatus: React.Dispatch<React.SetStateAction<BannerState>>;
  bannerGlobal?: any;
}

const useStyles = makeStyles({
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
    "& > *": {
      display: "block",
    },
    "& .MuiFormControl-root": {
      width: 100 + "% !important",
    },
  },
});

const SelectBox = styled(Select)({
  "& .MuiSelect-select": {
    padding: "12px",
  },
  borderRadius: "40px",
  width: "100%",
});

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "12px",
  },
});

const EditBanner: React.FunctionComponent<IEditBannerProps> = ({
  bannerDetailId,
  setBannerStatus,
  bannerGlobal,
  bannerStatus,
}) => {
  const classes = useStyles();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [bannerDetail, setBannerDetail] = useState<any>({
    name: "",
    startDate: "",
    endDate: "",
    isAll: "",
    country: [],
    type: "paid",
    ctaButton: [],
    coverImage: {
      file: null,
      imagePreviewUrl: "",
    },
    url: "",
    status: "active",
  });

  const [disableEdit, setDisableEdit] = useState(false);
  const [, setSelectedDate] = useState<any[]>([null, null]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const navigate = useNavigate();

  window.onpopstate = function () {
    if (bannerStatus !== BannerState.VIEW) {
      navigate(links.LIST_BANNERS);
    }
  };

  const getBannerDetail = async (id: string) => {
    try {
      const res = await Banner.getBannerById(id);
      setBannerDetail({
        ...res.data,
        coverImage: {
          file: null,
          imagePreviewUrl: res.data.coverImage,
        },
        startDate: res.data.startDate || startDate,
        endDate: res.data.endDate || endDate,
      });
    } catch (error: any) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerDetail.ctaButton.includes(event.target.name as never)) {
      const index = bannerDetail.ctaButton.indexOf(event.target.name as never);
      if (index > -1) {
        bannerDetail.ctaButton.splice(index, 1);
      }
    } else {
      bannerDetail.ctaButton.push(event.target.name as never);
    }

    setBannerDetail((prev: any) => ({ ...prev, ctaButton: [...bannerDetail.ctaButton] }));
  };

  useEffect(() => {
    getBannerDetail(bannerDetailId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerDetailId]);

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const res = await Users.uploadFiles({ file: (bannerDetail.coverImage as FileType).file });
      setIsLoading(false);
      return res.data[0].link;
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleEditBanner = async () => {
    let linkImage = "";
    try {
      setIsLoading(true);
      if (bannerDetail.coverImage.file) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        linkImage = await handleUpload();
        await Banner.updateBanner(bannerDetailId, { ...bannerDetail, coverImage: linkImage });
      } else {
        const cloneBannerDetail = JSON.parse(JSON.stringify(bannerDetail));
        delete cloneBannerDetail.coverImage;

        await Banner.updateBanner(bannerDetailId, { ...cloneBannerDetail });
      }

      setIsLoading(false);
      toast.success("Update banner successfully");
      setBannerStatus(BannerState.VIEW);
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    const { name, startDate, endDate, countries, isAll, type, ctaButton, coverImage, url, status, amount, country } =
      bannerDetail;

    const isCorrectType = () => {
      if (type === "paid") {
        if (amount) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    };

    if (
      url.trim().length > 0 &&
      name.trim().length > 0 &&
      startDate &&
      endDate &&
      ctaButton.length &&
      (country?.length || countries?.length || isAll) &&
      isCorrectType() &&
      status &&
      coverImage
    ) {
      setDisableEdit(false);
    } else {
      setDisableEdit(true);
    }
  }, [bannerDetail]);
  return (
    <Box component={"div"}>
      {isLoading && <Loading />}
      <Typography variant="h3">Edit banner</Typography>
      <Box component={"div"} mt={2} width={"60%"} minWidth={"60%"}>
        <Box component={"div"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
            Banner name <span style={{ color: "red" }}>*</span>
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Enter banner name"
            inputProps={{ maxLength: 255 }}
            value={bannerDetail?.name}
            onChange={(e) =>
              setBannerDetail({
                ...bannerDetail,
                name: e.target.value,
              })
            }
          />
          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Display time <span style={{ color: "red" }}>*</span>
            </Typography>

            <Box component={"div"} width="100%">
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate || bannerGlobal?.startDate}
                endDate={endDate || bannerGlobal?.endDate}
                defaultValue={[new Date(bannerGlobal?.startDate), new Date(bannerGlobal?.endDate)]}
                onChange={(date) => {
                  setBannerDetail((prev: any) => ({
                    ...prev,
                    startDate: moment(date[0]).toISOString(),
                    endDate: moment(date[1]).toISOString(),
                  }));
                  setSelectedDate(date);
                }}
                className={classes.dateRangePicker}
                sx={{ display: "block" }}
              />
            </Box>
          </Box>

          <Countries
            bannerState={BannerState.EDIT}
            isAll={bannerDetail.isAll}
            selectedCountries={bannerDetail.country}
            setCountries={({ countries, isAll = true }) =>
              setBannerDetail((prev: any) => ({ ...prev, countries, isAll }))
            }
          />

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner type
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              inputProps={{ "aria-label": "Without label" }}
              placeholder="Select banner type"
              value={bannerDetail.type}
              onChange={(e) => setBannerDetail({ ...bannerDetail, type: e.target.value as BannerType })}
            >
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unPaid">Unpaid</MenuItem>
            </SelectBox>
          </Box>

          {bannerDetail?.type === "paid" && (
            <Box component={"div"} mt={3}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
                Paid amount (USD) <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                type="number"
                fullWidth
                placeholder="Enter paid amount"
                inputProps={{ maxLength: 255 }}
                value={bannerDetail?.amount}
                onChange={(e) =>
                  setBannerDetail({
                    ...bannerDetail,
                    amount: +e.target.value as number,
                  })
                }
              />
            </Box>
          )}

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              CTA button
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bannerDetail.ctaButton.includes("buyNow")}
                    onChange={handleChangeCheckbox}
                    name="buyNow"
                  />
                }
                label="Buy now"
                sx={{ mr: 5 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bannerDetail.ctaButton.includes("share")}
                    onChange={handleChangeCheckbox}
                    name="share"
                  />
                }
                label="Share"
              />
            </Box>
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Image
              <span style={{ color: "red" }}>*</span>
            </Typography>

            <UploadFile
              fileUpload={{ file: null, imagePreviewUrl: bannerDetail?.coverImage?.imagePreviewUrl }}
              setFileUpload={(file) => {
                // eslint-disable-next-line no-debugger

                setBannerDetail((prev: any) => ({ ...prev, coverImage: file as FileType }));
              }}
            />

            {(bannerDetail.coverImage as FileType)?.file &&
              inValidSizeFile((bannerDetail.coverImage as FileType).file as File) && (
                <ErrorMessage msg="Maximum upload file size is 10MB" />
              )}
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner URL
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <CustomTextField
              fullWidth
              placeholder="http://, https://...."
              inputProps={{ maxLength: 150 }}
              value={bannerDetail.url}
              onChange={(e) =>
                setBannerDetail({
                  ...bannerDetail,
                  url: e.target.value,
                })
              }
            />
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Status <span style={{ color: "red" }}>*</span>
            </Typography>

            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={bannerDetail.status}
                onChange={(e) => {
                  setBannerDetail({
                    ...bannerDetail,
                    status: e.target.value as BannerStatus,
                  });
                }}
              >
                <FormControlLabel value="active" control={<Radio />} label="Active" />
                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box component={"div"} mt={3} display="flex" justifyContent="center">
            <WhiteButton
              label="Cancel"
              sx={{ paddingLeft: "40px", paddingRight: "40px", marginRight: "10px" }}
              onClick={() => setShowModalCancel(true)}
            />
            <Button
              variant="contained"
              sx={{ paddingLeft: "40px", paddingRight: "40px" }}
              disabled={
                disableEdit ||
                !!(
                  (bannerDetail.coverImage as FileType)?.file &&
                  inValidSizeFile((bannerDetail.coverImage as FileType).file as File)
                )
              }
              onClick={handleEditBanner}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      <ErrorMessage msg={error} />
      <CustomModal open={showModalCancel} onClose={() => setShowModalCancel(false)}>
        <Box component={"div"} textAlign="center">
          <Typography variant="h3" sx={{ mb: 1 }}>
            Cancel editing?
          </Typography>
          <Typography sx={{ fontSize: 16, mb: 3 }}>Are you sure you want to cancel without saving editing?</Typography>
          <Button variant="contained" onClick={() => setShowModalCancel(false)} sx={{ width: "140px", marginRight: 2 }}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "140px" }}
            onClick={() => {
              setShowModalCancel(false);
              setBannerStatus(BannerState.DETAIL);
            }}
          >
            Confirm
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default EditBanner;
