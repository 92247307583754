import { Box, Button, List, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Loading } from "../../components";
import ErrorMessage from "../../components/ErrorMessage";
import WhiteButton from "../../components/WhiteButton";
import { MintStatus } from "../../const";
import { links } from "../../constants/links";

const StyledListItem = styled.li`
  display: flex;
  margin-bottom: 16px;
  color: #616161;
  .item-title {
    font-weight: bold;
    min-width: 200px;
    color: #424242;
  }
  span {
    display: block;
    font-size: 14px;
  }
`;

interface IReviewNFTInfo {
  handleNext: () => void;
  handleBack: (index?: number) => void;
  setEstimateGas: React.Dispatch<any>;
  estimateGas: any;
  setADAPrice: React.Dispatch<React.SetStateAction<number>>;
  ADAprice: number;
  handleMintData: (type: MintStatus, payloadMint?: any) => Promise<unknown>;
  stepLoading: boolean;
  mintedFailError: any;
}

const ReviewNFTInfo: React.FunctionComponent<IReviewNFTInfo> = ({
  handleNext,
  handleBack,
  estimateGas,
  ADAprice,
  handleMintData,
  stepLoading,
  mintedFailError,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const onNFTWithoutMintingClick = () => {
    handleMintData(MintStatus.Created).then(() => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate(links.MY_NFTS, { state: { nftTab: 3 } });
      }, 2000);
    });
  };

  return (
    <Box component={"div"}>
      {(estimateGas.isLoading || !ADAprice) && <Loading />}
      {stepLoading && <Loading />}
      {loading && <Loading />}

      <Box component={"div"} sx={{ maxWidth: 560 }}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Review information
        </Typography>
        <Typography sx={{ mb: 3 }}>
          You are requesting to mint this NFT. Once the request is sent, you will not be able to change it. Please
          review all information before pressing Next.
        </Typography>
        <List sx={{ p: 0, mb: 3 }}>
          <StyledListItem>
            <Typography className="item-title">Processing time</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.processingTime} seconds</Typography>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Typography className="item-title">Estimated minting fee</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.estFee} ADA</Typography>
              <Typography component="span">≈ ${(estimateGas.data?.estFee * ADAprice).toFixed(2)} </Typography>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Typography className="item-title">Service fee</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.systemFee} ADA</Typography>
              <Typography component="span">≈ ${(estimateGas.data?.systemFee * ADAprice).toFixed(2)}</Typography>
            </Box>
          </StyledListItem>
        </List>
        <ErrorMessage msg={mintedFailError} />

        <Box component={"div"} sx={{ maxWidth: 350, marginLeft: "auto", marginRight: "auto" }}>
          <Box
            component={"div"}
            sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "1rem", mb: "1rem" }}
          >
            <WhiteButton onClick={() => handleBack(1)} label="Back to editting" />
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          </Box>
          {/* WITHOUT MINTING */}
          <WhiteButton onClick={onNFTWithoutMintingClick} label="Create NFT without minting" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewNFTInfo;
