import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, FormControl, Grid, MenuItem, Select, TableHead, Typography } from "@mui/material";
import WhiteButton from "../../components/WhiteButton";
import CustomDateRangePicker from "../../components/date-range-picker";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import ServicesManagment from "../../services/services-managment";
import Categories from "../../services/categories";
import CustomRangePagination from "../../components/RangePagination";
import useResponsive from "../../utils/responsive";

const sortcolumn = [
  {
    id: "category",
    label: "Category",
    width: "15%",
  },
  {
    id: "subCategory",
    label: "Sub-category",
    width: "15%",
  },
  {
    id: "serviceType",
    label: "Service type",
    width: "15%",
  },
  {
    id: "serviceBrand",
    label: "Service brand",
    width: "15%",
  },
  {
    id: "createdDate",
    label: "Created date",
    width: "15%",
  },
  {
    id: "action",
    label: "Action",
    width: "10%",
  },
];

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
  },
  svgIcon: {
    color: themeColors.primary,
    cursor: "pointer",
  },
});

export default function NFTService() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [listNFTService, setListNFTService] = useState<any>([]);
  const [meta, setMeta] = useState<any>([]);
  const [category, setCategory] = useState("all");
  const [subCategory, setSubCategory] = useState("all");
  const [serviceType, setServiceType] = useState("all");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<any[]>([null, null]);
  const { isTabletOrMobile } = useResponsive();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const getListService = async (params?: any) => {
    const selectedCategory = categoryList.find((x: any) => {
      return x?.name === category;
    });
    const selectedSubCategory = subCategoryList.find((x: any) => {
      return x?.name === subCategory;
    });

    const res = await ServicesManagment.getListService({
      page: params?.page ?? page,
      limit: params?.rowsPerPage ?? rowsPerPage,
      ...(serviceType && serviceType !== "all" && { serviceType: serviceType }),
      ...(selectedCategory && selectedCategory.id !== "all" && { categoryId: selectedCategory.id }),
      ...(selectedSubCategory && selectedSubCategory.id !== "all" && { subCategoryId: selectedSubCategory.id }),
      startDate: selectedDate[0] ? moment(selectedDate[0]).toISOString() : undefined,
      endDate: selectedDate[1] ? moment(selectedDate[1]).toISOString() : undefined,
    });
    if (res.statusCode === 200) {
      setListNFTService(res.data.items);
      setMeta(res.data.meta);
    }
  };

  const getCategory = async () => {
    const res = await Categories.getCategories();
    if (res.statusCode === 200) {
      setCategoryList([{ id: "all", name: "All" }, ...res.data]);
      setCategory("all");
    }
  };
  const getSubCategory = async (item?: any) => {
    const res = await Categories.getCategories({ mainCategoryId: item ? item : undefined });
    if (res.statusCode === 200) {
      setSubCategoryList(res.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getListService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleCategoryChange = (e: string) => {
    const selectedCategory = categoryList.find((x: any) => {
      return x.name === e;
    });

    setCategory(e);
    if (e === "All") {
      setSubCategoryList([]);
      setSubCategory("all");
      return;
    }

    getSubCategory(selectedCategory?.id === "all" ? "" : selectedCategory?.id);
  };

  const onClearFilter = async () => {
    setSelectedDate([null, null]);
    setCategory("all");
    setSubCategory("all");
    setServiceType("all");
    setRowsPerPage(20);
    const res = await ServicesManagment.getListService({
      page: 0,
      limit: 20,
      startDate: null,
      endDate: null,
    });
    if (res.statusCode === 200) {
      setListNFTService(res.data.items);
      setMeta(res.data.meta);
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>NFT Service</span>
      <Box component={"div"} marginTop={4}>
        <Grid marginBottom={3}>
          <Box component={"div"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
            <h2>List of NFT Services</h2>

            <Button
              sx={{ fontSize: 16 }}
              variant="contained"
              onClick={() => {
                navigate("/nft-service-details/create", { state: { serviceState: "create" } });
              }}
              startIcon={<AddIcon />}
            >
              New service brand
            </Button>
          </Box>
        </Grid>
        <Grid container alignItems={"center"}>
          <Grid item xs={2}>
            <Grid item xs={11}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Category &nbsp;</Typography>
              <FormControl fullWidth>
                <Select
                  value={category}
                  onChange={(e: any) => handleCategoryChange(e.target.value)}
                  sx={{
                    borderRadius: 20,
                    height: "40px",
                  }}
                  renderValue={(value) => (value !== "all" ? value : "All")}
                >
                  {categoryList.length > 0 &&
                    categoryList.map((x: any, index: number) => {
                      return (
                        <MenuItem key={index} value={x.name}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item xs={11}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Sub-category &nbsp;</Typography>
              <FormControl fullWidth>
                <Select
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  sx={{
                    borderRadius: 20,
                    height: "40px",
                  }}
                  renderValue={(value) => (value !== "all" ? value : "All")}
                >
                  {subCategoryList.length > 0 &&
                    subCategoryList.map((x: any, index: number) => {
                      return (
                        <MenuItem key={index} value={x.name}>
                          {x.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={2}>
            <Grid item xs={11}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }}>Service type &nbsp;</Typography>
              <FormControl fullWidth>
                <Select
                  value={serviceType}
                  onChange={(e) => setServiceType(e.target.value)}
                  sx={{
                    borderRadius: 20,
                    height: "40px",
                  }}
                  renderValue={(value) => (value !== "all" ? value.charAt(0).toUpperCase() + value.slice(1) : "All")}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"insurance"}>Insurance</MenuItem>
                  <MenuItem value={"finance"}>Finance </MenuItem>
                  <MenuItem value={"service"}>Service</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item>
            <Box component={"div"}>
              <Typography sx={{ fontWeight: "bold" }}>Created date &nbsp;</Typography>
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                className={classes.dateRangePicker}
              />
            </Box>
          </Grid>

          <Grid item sx={{ margin: "0 1% 0 1%" }}>
            <Box component={"div"}>
              <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
              <WhiteButton label="Clear all filters" onClick={onClearFilter} width="150px" sx={{ fontSize: 16 }} />
            </Box>
          </Grid>

          <Grid item>
            <Box component={"div"}>
              <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
              <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getListService}>
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box component={"div"} mt={5}>
          <TableContainer style={{ maxHeight: isTabletOrMobile ? "35vh" : "40vh" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {sortcolumn.map((column) => (
                    <TableCell key={column.id} style={{ width: column.width }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listNFTService?.length > 0 &&
                  listNFTService.map((row: any, index: number) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell>{row.categoryName}</TableCell>
                        <TableCell>{row.subCategoryName}</TableCell>
                        <TableCell>{row?.serviceType}</TableCell>
                        <TableCell>{row?.serviceBrand}</TableCell>
                        <TableCell>{moment(row.createdAt).format("MM-DD-YYYY")}</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          <Box component={"div"} display="flex">
                            <VisibilityOutlinedIcon
                              className={classes.svgIcon}
                              sx={{ marginRight: 2 }}
                              onClick={() =>
                                navigate(`/nft-service-details/${row.id}`, { state: { serviceState: "view" } })
                              }
                            />
                            <BorderColorOutlinedIcon
                              className={classes.svgIcon}
                              onClick={() =>
                                navigate(`/nft-service-details/${row.id}`, { state: { serviceState: "edit" } })
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomRangePagination
            onPageChange={handleChangePage}
            meta={meta}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
          />
        </Box>
      </Box>
    </Box>
  );
}
