import axiosClient from "../../api/axiosClient";
import { getPage } from "../../utils/utils";

const Items = {
  getListNFTsAdmin(params?: any): Promise<any> {
    const page = getPage(params);

    const url = `/items/admin?page=${page ?? 1}&limit=${params?.limit ?? 5}&${`${
      params?.status && `status=${params?.status}`
    }`}&search=${params?.search}&${`${params?.startDate && `startDate=${params?.startDate}`}`}&${`${
      params?.endDate && `endDate=${params?.endDate}`
    }`}`;
    return axiosClient.get(url);
  },
  getNFTById(params?: any): Promise<any> {
    const url = `/items/${params.id}`;
    return axiosClient.get(url);
  },
  getListNFTs(params?: any): Promise<any> {
    const { isMinting } = params;

    const page = getPage(params);

    const url = `/items?page=${page ?? 1}&limit=${params?.limit ?? 5}&${`${
      params?.status &&
      `status=${params?.status}&${`${
        params?.walletAddress && `walletAddress=${params?.walletAddress}`
      }`}&isMinting=${isMinting}`
    }`}`;
    return axiosClient.get(url);
  },
  sendNFT(params?: any): Promise<any> {
    const url = "/items/send-nft";
    return axiosClient.post(url, params);
  },
  getListOffer(params?: any): Promise<any> {
    const page = getPage(params);
    const url = `/offers?page=${page ?? 1}&limit=${
      params?.limit ?? 20
    }&${`${`walletAddress=${params?.walletAddress}`}`}&offerType=${params?.offerType}`;
    return axiosClient.get(url);
  },
  estimateFee: (params: any): Promise<any> => {
    // eslint-disable-next-line max-len
    const url = `/items/estimate-gas-send-nft?walletAddress=${params?.walletAddress}&receiveAddress=${params?.receiveAddress}&itemIds=${params?.itemIds}`;
    return axiosClient.get(url);
  },

  getMintbatchList(params?: any): Promise<any> {
    const page = getPage(params);
    const url = `/items/mint-batch-monitor?page=${page ?? 1}&limit=${params?.limit ?? 5}`;
    return axiosClient.get(url);
  },
  uploadMintBatch(params?: any): Promise<any> {
    const url = "/items/mint-batch";
    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("walletAddress", params.walletAddress);
    return axiosClient.post(url, formData);
  },
  getNFTImage(id?: any): Promise<any> {
    const url = `/items/${id}/image `;
    return axiosClient.get(url);
  },
};
export default Items;
