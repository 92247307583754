import { Box, Button, Card, Container, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomTextField from "../../../components/CustomTextField";
import backgroundImage from "../../../assets/images/Rectangle 2206.png";
import headerImage from "../../../assets/images/Asset 1 1.png";
import DVOLDLogo from "../../../assets/logos/DVOLD_def 1.png";
import DVOLD from "../../../assets/logos/Frame 625843.png";
import "react-dropzone-uploader/dist/styles.css";
import successIcon from "../../../assets/icons/check-one.svg";
import SVG from "react-inlinesvg";
import { useLocation, useNavigate } from "react-router-dom";
import Authenticate from "../../../services/authenticate";
import Users from "../../../services/users";
import { MAXIMUM_TEXT_FIELD_LENGTH } from "../../../const";
import CustomDropzone from "../../../components/CustomDropzone";
import ErrorMessage from "../../../components/ErrorMessage";
import { PhoneNumberInput } from "../../../components/phoneNumberInput";

export const MainContent = styled(Box)(
  () => `
      background-image: url("${backgroundImage}");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      overflow: auto
    `,
);

export const Header = styled(Box)(
  () => `
      background-image: url("${headerImage}");
      width: 100%;
      height: 10vh;
      overflow: auto
    `,
);

export const HeaderLogoContainer = styled(Box)(
  () => `
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
);

function SetupInformation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isComplete, setIsComplete] = useState(false);
  const [docs, setDocs] = useState<any[]>([]);
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [errMsg, setErrMsg] = useState("");
  const [totalFile, setTotalFile] = useState(0);
  const [account, setAccount] = useState({
    firstName: "",
    lastName: "",
    position: "",
    phoneNumber: "",
    phoneNumberPrefix: "",
    phoneNumberSuffix: "",
    licenses: "",
  });

  const onNextClick = async () => {
    const phoneString = (document.getElementById("phoneInput") as HTMLInputElement)?.value;
    if (
      account.firstName.length > MAXIMUM_TEXT_FIELD_LENGTH ||
      account.lastName.length > MAXIMUM_TEXT_FIELD_LENGTH ||
      account.position.length > MAXIMUM_TEXT_FIELD_LENGTH ||
      phoneString.length > MAXIMUM_TEXT_FIELD_LENGTH
    ) {
      setErrMsg("Max length 255 characters");
      return;
    }

    const dialCode = phoneString?.split(" ")[0];
    const phoneNumer = phoneString?.substring(phoneString.indexOf(" ") + 1);

    const res = await Authenticate.initInfo({
      token: location.state.token,
      firstName: account.firstName,
      lastName: account.lastName,
      phone: phoneNumer,
      position: account.position,
      docs: docs,
      dialCode: dialCode,
    });
    if (res.statusCode === 200) {
      setIsComplete(true);
    }
  };

  useEffect(() => {
    setAccount({ ...account, phoneNumber: `${account.phoneNumberPrefix}${account.phoneNumberSuffix}` });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.phoneNumberPrefix, account.phoneNumberSuffix]);

  const onHandleUpload = async (item: any) => {
    const res = await Users.uploadDocs({ file: item.file, token: location.state.token });
    if (res.statusCode === 200) {
      let newDocs = [...docs, res?.data[0]];
      setDocs(newDocs);
    }
  };

  const handleChangeStatus = (file: any, allFiles: any) => {
    if (allFiles === "done") {
      onHandleUpload(file);
      setTotalFile(totalFile + 1);
    } else if (allFiles === "removed") {
      setTotalFile(totalFile - 1);
    }
  };

  const handleSubmit = (files: any, allFiles: any) => {
    allFiles.forEach((f: any) => f.remove());
  };

  const phoneNumberChange = (telephone?: string) => {
    setPhoneNumber(telephone);
  };

  const successScreen = () => {
    return (
      <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
        <SVG src={successIcon} />
        <Typography
          variant="h3"
          sx={{
            mb: 2,
            mt: 2,
          }}
        >
          Sign up
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Your profile has been created successfully.
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          It is being reviewed by DVOLD admin.
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Once the review process is completed, you will receive an email
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          notification and could start using your corporate’s features.
        </Typography>
        <Button variant="contained" sx={{ marginTop: 4 }} onClick={() => navigate("/login")}>
          Back to Sign in
        </Button>
      </Box>
    );
  };

  return (
    <>
      <MainContent>
        <Header>
          <HeaderLogoContainer>
            <img src={DVOLDLogo} alt="" style={{ marginRight: 10 }} />
            <img src={DVOLD} alt="" />
          </HeaderLogoContainer>
        </Header>
        <Box component={"div"} display={"flex"} height="100vh">
          <Container maxWidth="sm" sx={{ display: isComplete ? "flex" : "block", alignItems: "center" }}>
            <Card
              sx={{
                px: 4,
                pt: 5,
                pb: 3,
                mt: 5,
                mb: 5,
              }}
            >
              {!isComplete ? (
                <Box component={"div"}>
                  <Box
                    component={"div"}
                    display={"flex"}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent={"center"}
                    justifyItems="center"
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        mb: 2,
                      }}
                    >
                      Sign up
                    </Typography>
                  </Box>
                  <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
                    Please provide a little more information about yourself
                  </Typography>
                  <br />
                  <CustomTextField
                    required
                    label="First name:"
                    value={account.firstName}
                    placeholder="Enter your first name"
                    onChange={(e: string) => {
                      setAccount({ ...account, firstName: e });
                      setErrMsg("");
                    }}
                  />
                  <CustomTextField
                    required
                    label="Last name"
                    value={account.lastName}
                    placeholder="Enter your last name"
                    onChange={(e: string) => {
                      setAccount({ ...account, lastName: e });
                      setErrMsg("");
                    }}
                  />
                  <CustomTextField
                    required
                    label="Position"
                    value={account.position}
                    placeholder="Enter your position"
                    onChange={(e: string) => {
                      setAccount({ ...account, position: e });
                      setErrMsg("");
                    }}
                  />
                  <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
                    <Box component={"div"} display="flex">
                      Phone number &nbsp;
                      <div style={{ color: "red" }}>*</div>
                    </Box>
                  </Typography>
                  <PhoneNumberInput
                    id="phoneInput"
                    onChange={(telephone) => {
                      phoneNumberChange(telephone);
                      setErrMsg("");
                    }}
                    value={phoneNumber}
                  />
                  <Typography sx={{ fontWeight: "bold" }} mb={1} mt={2} variant="h4">
                    <Box component={"div"} display="flex">
                      Business licenses&nbsp;
                      <div style={{ color: "red" }}> *</div>
                    </Box>
                  </Typography>
                  <CustomDropzone
                    handleChangeStatus={handleChangeStatus}
                    handleSubmit={handleSubmit}
                    totalFile={totalFile}
                  />
                  <ErrorMessage msg={errMsg} />
                  <Box component={"div"} display={"flex"} justifyContent="center" mt={3}>
                    <Button
                      variant="contained"
                      sx={{ width: 160 }}
                      disabled={!account.firstName || !account.lastName || !account.position || !phoneNumber}
                      onClick={onNextClick}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              ) : (
                successScreen()
              )}
            </Card>
          </Container>
        </Box>
      </MainContent>
    </>
  );
}

export default SetupInformation;
