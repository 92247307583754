import { Box, Button, Typography } from "@mui/material";

import DVOLDLogo from "../../assets/logos/DVOLD_def 1.png";
import DVOLD from "../../assets/logos/Frame 625843.png";
import useResponsive from "../../utils/responsive";

const LadingPage = () => {
  const { isSmallMobile } = useResponsive();
  return (
    <>
      <Box
        component={"div"}
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        height="100vh"
        flexDirection={"column"}
      >
        <Box component={"div"} display={"flex"} alignItems={"center"}>
          <img src={DVOLDLogo} alt="" style={{ marginRight: 10 }} />
          <img src={DVOLD} alt="" style={{ background: "gray" }} />
        </Box>
        <Typography variant="h5" mt={2} textAlign="center">
          Install it now on your favorite Android or iOS device using one of these links:
        </Typography>
        {isSmallMobile ? (
          <Box component={"div"} display={"flex"} flexDirection="column">
            <Button variant="contained" sx={{ width: "200px", marginTop: 2, marginBottom: 2 }}>
              Download for android
            </Button>
            <Button variant="contained" sx={{ width: "200px" }}>
              Download for IOS
            </Button>
          </Box>
        ) : (
          <Box component={"div"} mt={2}>
            <Button variant="contained" sx={{ marginRight: "5px", width: "200px" }}>
              Download for android
            </Button>
            <Button variant="contained" sx={{ width: "200px" }}>
              Download for IOS
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default LadingPage;
