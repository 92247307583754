import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import CustomTextField from "../../../components/CustomTextField";
import { useState } from "react";
import Authenticate from "../../../services/authenticate";
import { validationPatterns } from "../../../validation/pattern";
import { themeColors } from "../../../theme/schemes/PureLightTheme";
import { localStorageHelper } from "../../../helpers";

function Register() {
  const navigate = useNavigate();

  const [registerAccount, setRegisterAccount] = useState(
    localStorageHelper.getItem("registerAccount", { isObject: true }) || {
      companyName: "",
      email: "",
    },
  );

  const [error, setError] = useState<string | null>(null);

  const onNextClick = async () => {
    try {
      const res = await Authenticate.sendVerify({
        corporateName: registerAccount.companyName,
        email: registerAccount.email,
      });
      if (res.statusCode === 200) {
        localStorageHelper.setItem("registerAccount", registerAccount);
        navigate("/email-verification", { state: { email: registerAccount.email } });
      }
    } catch (error) {
      setError("Email already exists");
    }
  };

  return (
    <>
      <AuthenticateLayout>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography
            variant="h2"
            sx={{
              mb: 1,
            }}
          >
            Sign up
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal">
            Welcome to DVOLD! Please sign up to access exclusive features for
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" marginBottom={3}>
            corporate users.
          </Typography>
        </Box>
        <CustomTextField
          label="Which company are you from?"
          placeholder="Enter your company"
          value={registerAccount.companyName}
          onChange={(e: string) => setRegisterAccount({ ...registerAccount, companyName: e })}
        />
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography variant="h4" color="text.secondary" fontWeight="normal">
            Please provide your company’s email to sign up for a corporate
          </Typography>
          <Typography variant="h4" color="text.secondary" fontWeight="normal" marginBottom={3}>
            account.
          </Typography>
        </Box>
        <CustomTextField
          label="Email"
          placeholder="Enter your email"
          value={registerAccount.email}
          onChange={(e: string) => setRegisterAccount({ ...registerAccount, email: e })}
        />
        <Box
          component={"div"}
          display={"flex"}
          flexDirection="column"
          alignItems="center"
          sx={{ marginBottom: "12px" }}
        >
          <Typography variant="h4" sx={{ color: themeColors.error }} fontWeight="normal">
            {error}
          </Typography>
        </Box>

        <Box component={"div"} display={"flex"} justifyContent="center">
          <Button
            variant="contained"
            sx={{ width: 160 }}
            disabled={
              !validationPatterns.email.test(registerAccount.email) || registerAccount.companyName === "" || !!error
            }
            onClick={onNextClick}
          >
            Next
          </Button>
        </Box>
      </AuthenticateLayout>
    </>
  );
}

export default Register;
