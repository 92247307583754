import axiosClient from "../../api/axiosClient";

export type BannerType = "all" | "paid" | "unPaid" | undefined;
export type BannerStatus = "all" | "active" | "Inactive" | undefined;
interface IQueryBanner {
  page?: number;
  limit?: number;
  type?: BannerType;
  status?: BannerStatus;
  search?: string;
  startDate?: string;
  endDate?: string;
}

const Banner = {
  getCountries(): Promise<any> {
    const url = "/banners/countries";
    return axiosClient.get(url);
  },
  getBanners(query: IQueryBanner): Promise<any> {
    const { page = 1, limit = 10, type = "all", status = "all" } = query;
    const url = "/banners";
    const restrictedQS = `?page=${page}&limit=${limit}&type=${type}&status=${status}`;
    const searchQS = query.search ? `&search=${query.search}` : "";
    const startDateQS = query.startDate ? `&startDate=${query.startDate}` : "";
    const endDateQS = query.endDate ? `&endDate=${query.endDate}` : "";
    const queryString = restrictedQS + searchQS + startDateQS + endDateQS;

    return axiosClient.get(`${url}${queryString}`);
  },
  getBannersAdmin(query: IQueryBanner): Promise<any> {
    const { page = 1, limit = 10, type = "all", status = "all" } = query;
    const url = "/banners/admin";
    const restrictedQS = `?page=${page}&limit=${limit}&type=${type}&status=${status}`;
    const searchQS = query.search ? `&search=${query.search}` : "";
    const startDateQS = query.startDate ? `&startDate=${query.startDate}` : "";
    const endDateQS = query.endDate ? `&endDate=${query.endDate}` : "";
    const queryString = restrictedQS + searchQS + startDateQS + endDateQS;

    return axiosClient.get(`${url}${queryString}`);
  },
  getBannerById(id: string): Promise<any> {
    const url = `/banners/${id}`;
    return axiosClient.get(url);
  },
  createBanner(data: any): Promise<any> {
    const url = "/banners";
    return axiosClient.post(url, data);
  },
  updateBanner(id: string, data: any): Promise<any> {
    const url = `/banners/${id}`;
    return axiosClient.put(url, data);
  },
  deleteBanner(id: string): Promise<any> {
    const url = `/banners/${id}`;
    return axiosClient.delete(url);
  },
};

export default Banner;
