import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableHead,
  TextField,
  Typography,
  styled,
  FormControl,
} from "@mui/material";
import { SearchOutlined } from "@material-ui/icons";
import WhiteButton from "../../components/WhiteButton";
import { ReactComponent as ViewIcon } from "../../assets/icons/view.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/nft-detail/edit.svg";
import CustomDateRangePicker from "../../components/date-range-picker";
import moment from "moment";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import Banner, { BannerStatus, BannerType } from "../../services/banner";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import ErrorMessage from "../../components/ErrorMessage";
import { BannerState } from ".";
import CustomRangePagination from "../../components/RangePagination";
import useResponsive from "../../utils/responsive";
import CancelIcon from "@material-ui/icons/Cancel";
import colors from "../../theme/colors";

interface IListBannersProps {
  setBannerDetailId: React.Dispatch<React.SetStateAction<string>>;
  setBannerStatus: React.Dispatch<React.SetStateAction<BannerState>>;
  setDeleteBanner: React.Dispatch<
    React.SetStateAction<{
      id: string;
      approved: boolean;
    }>
  >;
  isDeleteBanner: boolean;
  setBannerGlobal: React.Dispatch<any>;
}

const SelectBox = styled(Select)({
  "& .MuiSelect-select": {},
  borderRadius: "40px",
  width: "120px",
});

const commonColumn = [
  {
    id: "bannerName",
    label: "Banner name",
    width: "10%",
    sort: true,
  },
  {
    id: "bannerType",
    label: "Banner type",
    width: "7%",
    sort: true,
    maxWidth: "1px",
  },
  {
    id: "country",
    label: "Country",
    width: "10%",
    sort: true,
  },
  {
    id: "status",
    label: "Status",
    width: "10%",
    sort: false,
  },
  {
    id: "createdDate",
    label: "Created date",
    width: "10%",
    sort: true,
  },
  {
    id: "startDate",
    label: "Start date",
    width: "10%",
    sort: true,
  },
  {
    id: "endDate",
    label: "End date",
    width: "10%",
    sort: true,
  },
  {
    id: "numberOfClicks",
    label: "Number of clicks",
    width: "5%",
    sort: true,
  },
  {
    id: "action",
    label: "Action",
    width: "5%",
    sort: false,
  },
];

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
  },
  svgIcon: {
    "& path": {
      stroke: themeColors.primary,
    },
  },
});

interface IBannersList {
  id: string;
  name: string;
  type: BannerType;
  bannerCountries: any[];
  status: BannerStatus;
  createdDate: string;
  startDate: any;
  endDate: any;
  clickCount: number;
  ctaButton: string[];
  [k: string]: any;
}

const ListBanners: React.FunctionComponent<IListBannersProps> = (props) => {
  const { setBannerStatus, setBannerDetailId, setDeleteBanner, isDeleteBanner, setBannerGlobal } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [meta, setMeta] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [bannerType, setBannerType] = useState<BannerType | string>("all");
  const [status, setStatus] = useState<BannerStatus | string>("all");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<any[]>([null, null]);
  const [, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [listBanners, setListBanner] = useState<IBannersList[]>([]);
  const { isTabletOrMobile, isDesktopOrLaptop } = useResponsive();

  const getListBanners = async () => {
    try {
      setIsLoading(true);
      const res = await Banner.getBannersAdmin({
        search: search,
        page: page + 1,
        limit: rowsPerPage,
        startDate: selectedDate[0] ? moment(selectedDate[0]).toISOString() : undefined,
        endDate: selectedDate[1] ? moment(selectedDate[1]).toISOString() : undefined,
        type: (bannerType as BannerType) ? (bannerType as BannerType) : "all",
        status: status ? (status as BannerStatus) : "all",
      });
      setMeta(res?.data?.meta);
      setListBanner(res?.data?.items);
    } catch (error: any) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    getListBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, isDeleteBanner]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  const onClearFilter = async () => {
    setSearch("");
    setEndDate("");
    setStartDate("");
    setSelectedDate([null, null]);
    setBannerType("all");
    setStatus("all");
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleBannerChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void = (event) => {
    setBannerType(event.target.value as BannerType);
  };

  const handleStatusChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void = (event) => {
    setStatus(event.target.value as BannerStatus);
  };

  const renderCountry = (row: IBannersList) => {
    const { country, isAll } = row;
    if (isAll) return "All";

    let text = "";
    const counterCountries = country.length;
    const limitCountry = 2;
    if (counterCountries <= limitCountry) {
      country.forEach((item: any) => {
        text += `, ${item.name}`;
      });
      text = text.substring(2);
    } else {
      const sliceCountries = country.slice(0, limitCountry);
      sliceCountries.forEach((item: any) => {
        text += `, ${item.name}`;
      });
      text = text.substring(2) + " ...";
    }
    return text;
  };

  return (
    <Box component={"div"} marginTop={4}>
      <Grid marginBottom={3}>
        <Box component={"div"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
          <h2>List of banners</h2>
          <Button variant="contained" onClick={() => setBannerStatus(BannerState.CREATE)}>
            <Box component={"div"} display="flex" justifyContent="center" gap={0.5} paddingY={0.3}>
              <PlusIcon />
              <Typography variant="h4">Create new banner</Typography>
            </Box>
          </Button>
        </Box>
      </Grid>
      <Grid container alignItems={"center"} gap={4}>
        <Grid item xs={isDesktopOrLaptop ? 2 : 3}>
          <Typography sx={{ fontWeight: "bold" }}>Search &nbsp;</Typography>
          <TextField
            label={search === "" ? "Banner name" : ""}
            fullWidth
            sx={{
              marginTop: 1,
              "& label": {
                marginLeft: 4,
                "&.Mui-focused": {
                  color: "#B3B3B3",
                },
              },
            }}
            InputLabelProps={{ shrink: false }}
            InputProps={{
              startAdornment: (
                <IconButton
                  sx={{
                    cursor: "pointer",
                    paddingX: 0,
                  }}
                >
                  <SearchOutlined />
                </IconButton>
              ),
              endAdornment: (
                <>
                  {search ? (
                    <IconButton
                      onClick={() => setSearch("")}
                      sx={{
                        color: colors.lightGrey,
                        cursor: "pointer",
                        paddingX: 0,
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </>
              ),
            }}
            size="small"
            onChange={(e) => {
              handleSearchChange(e);
            }}
            value={search}
          ></TextField>
        </Grid>

        {/* Banner */}
        <Grid item xs={isDesktopOrLaptop ? 1 : 2}>
          <Typography sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>Banner Type &nbsp;</Typography>
          <FormControl
            size="small"
            sx={{
              marginTop: 1,
              "& label": {
                marginLeft: 4,
                "&.Mui-focused": {
                  color: "#B3B3B3",
                },
              },
            }}
          >
            <SelectBox
              value={bannerType}
              defaultValue="all"
              onChange={handleBannerChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unPaid">Unpaid</MenuItem>
            </SelectBox>
          </FormControl>
        </Grid>

        {/* Status */}
        <Grid item xs={isDesktopOrLaptop ? 1 : 2}>
          <Typography sx={{ fontWeight: "bold" }}>Status &nbsp;</Typography>
          <FormControl
            size="small"
            sx={{
              marginTop: 1,
              "& label": {
                marginLeft: 4,
                "&.Mui-focused": {
                  color: "#B3B3B3",
                },
              },
            }}
          >
            <SelectBox
              value={status}
              defaultValue="all"
              onChange={handleStatusChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </SelectBox>
          </FormControl>
        </Grid>

        <Grid item>
          <Box component={"div"}>
            <Typography sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}>Created date &nbsp;</Typography>
            <CustomDateRangePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              className={classes.dateRangePicker}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box component={"div"}>
            <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
            <WhiteButton label="Clear all filters" onClick={onClearFilter} width="150px" sx={{ fontSize: 16 }} />
          </Box>
        </Grid>
        <Grid item>
          <Box component={"div"}>
            <Typography sx={{ fontWeight: "bold" }}>&nbsp;</Typography>
            <Button sx={{ width: "150px", fontSize: 16 }} variant="contained" onClick={getListBanners}>
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box component={"div"} mt={5}>
        {listBanners?.length ? (
          <CustomRangePagination
            onPageChange={handleChangePage}
            meta={meta}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
          />
        ) : null}
        <TableContainer style={{ maxHeight: isTabletOrMobile ? "30vh" : "40vh" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {commonColumn.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ width: column.width, maxWidth: column?.maxWidth || column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {listBanners?.map((row, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell>{row?.name}</TableCell>
                    <TableCell>{capitalizeFirstLetter(row?.type?.toLowerCase())}</TableCell>
                    <TableCell>{renderCountry(row)}</TableCell>
                    <TableCell>
                      <Box
                        component={"div"}
                        sx={{
                          padding: "4px 8px",
                          background: `${row?.status === "active" ? themeColors.lightGreen : "#FFE3E0"}`,
                          display: "inline-block",
                          borderRadius: "20px",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          style={{ color: `${row?.status === "active" ? "#149973" : "#D73021"}` }}
                        >
                          {row?.status}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{moment(row?.createdAt).format("MM-DD-YYYY")}</TableCell>
                    <TableCell>{moment(row?.startDate).format("MM-DD-YYYY")}</TableCell>
                    <TableCell>{moment(row?.endDate).format("MM-DD-YYYY")}</TableCell>
                    <TableCell>{row?.clickCount}</TableCell>
                    <TableCell>
                      <Box component={"div"} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <ViewIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setBannerStatus(BannerState.DETAIL);
                            setBannerDetailId(row.id);
                          }}
                        />
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setBannerStatus(BannerState.EDIT);
                            setBannerDetailId(row.id);
                            setBannerGlobal(row);
                          }}
                        />
                        <DeleteIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => setDeleteBanner({ id: row.id, approved: true })}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!listBanners.length && (
          <Box
            component={"div"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="40vh"
            maxHeight="50vh"
          >
            <Typography variant="body1"> No results match your criteria. Try again with other filters! </Typography>
          </Box>
        )}
        {listBanners.length ? (
          <CustomRangePagination
            onPageChange={handleChangePage}
            meta={meta}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
          />
        ) : null}
      </Box>
      <ErrorMessage msg={error} />
    </Box>
  );
};

export default ListBanners;
