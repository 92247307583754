import { Box, Button, Typography } from "@mui/material";
import { useLayoutEffect } from "react";
import styled from "styled-components";

const ITEM_ARRAY = 8;

const StyledListPhrase = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const StyledItemPhrase = styled.div`
  width: 105px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  font-weight: 600;
  color: #616161;
`;

interface IProps {
  handleFillData: () => void;
  setWidthModal: React.Dispatch<React.SetStateAction<number>>;
  seedPhraseInfo: any;
}

const RecoveryPhraseData = ({ handleFillData, setWidthModal, seedPhraseInfo }: IProps) => {
  const { seed_phrase: seedPhrase = [] } = seedPhraseInfo;

  useLayoutEffect(() => {
    setWidthModal(1100);
  }, [setWidthModal]);

  const transformArray = (arr: any[]) => {
    const result = [];
    for (let i = 0; i < arr.length; i += ITEM_ARRAY) {
      result.push(arr.slice(i, i + ITEM_ARRAY));
    }
    return result;
  };

  return (
    <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
      <Typography variant="h3" sx={{ mb: 1 }}>
        Recorvery Phrase
      </Typography>
      <br />
      <Typography sx={{ mb: 3 }}>Write down your back up phrase in a piece of paper.</Typography>
      <Typography sx={{ mb: 3 }} align="center">
        These 24 words are the only way to restore your DVOLD wallet. Please keep them somewhere secret and secure.
      </Typography>
      <StyledListPhrase>
        {Array.isArray(seedPhrase) &&
          !!seedPhrase.length &&
          transformArray(seedPhrase).map((phraseLine, index) => (
            <Box component={"div"} key={index} display="flex" gap={2}>
              {phraseLine.map((phrase, index) => (
                <StyledItemPhrase key={phrase}>{`${index + 1}.${phrase}`}</StyledItemPhrase>
              ))}
            </Box>
          ))}
      </StyledListPhrase>
      <Button variant="contained" onClick={handleFillData}>
        I have written everything down
      </Button>
    </Box>
  );
};

export default RecoveryPhraseData;
