import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Stepper from "../../components/Stepper";
import ChooseCategory from "./ChooseCategory";
import ConfirmNFTTransaction from "./ConfirmNFTTransaction";
import NftInfo, { IDocConfig } from "./NftInfo";
import ReviewNFTInfo from "./ReviewNFTInfo";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import MintingNft from "../../services/minting-nft";
import { Loading } from "../../components";
import Users from "../../services/users";
import { useWalletContext } from "../../contexts/wallet-provider";
import { MintStatus } from "../../const";
import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../../constants/links";

interface IMintingNFT {
  [k: string]: any;
}

export interface SelectedCategory {
  mainCategory: any;
  subCategory: any;
}

export enum TypeAttribute {
  DOCS = "docs",
  INFO = "info",
}
export interface IAttributeResponse {
  categoryId?: string;
  id?: string;
  placeholder?: string;
  isRequired: boolean;
  name: string;
  type: TypeAttribute;
  value: string;
  [k: string]: any;
}

export interface IDataInfo {
  [k: string]: IAttributeResponse[];
}

export enum MintType {
  INIT = "init",
  SUCCESS = "success",
  FAIL = "fail",
}

const MintingNFT: React.FunctionComponent<IMintingNFT> = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { selectedWallet } = useWalletContext();
  useEffect(() => {
    if (!selectedWallet) {
      navigate(links.WALLET);
    }
  }, [selectedWallet, navigate]);

  const [activeStep, setActiveStep] = React.useState(state?.currentStep || 0);
  const [stepLoading, setStepLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory>({
    mainCategory: null,
    subCategory: null,
  });
  const [infoNFT, setInfoNFT] = useState({
    isLoading: false,
    error: null,
    data: null as unknown as IDataInfo,
  });

  const [fileUpload, setFileUpload] = useState({
    file: null,
    imagePreviewUrl: "",
  });
  const [file3DUpload, setFile3DUpload] = useState({
    file: null,
    link: "",
  });
  const [estimateGas, setEstimateGas] = useState<any>({
    isLoading: false,
    error: null,
    data: null,
  });

  const [ADAPrice, setADAPrice] = useState(0);

  const [spendingPass, setSpendingPass] = useState({
    value: "",
    showPass: false,
  });

  const [infoFieldsConfig, setInfoFieldsConfig] = useState<any>(null);
  const [docsConfig, setDocsConfig] = useState<IDocConfig[] | null>(null);
  const [mintedStatus, setMintedStatus] = useState<any>({
    type: MintType.INIT,
    msg: "",
  });

  const getEstimateGas = useCallback(() => {
    setEstimateGas((prev: any) => ({ ...prev, isLoading: true }));
    MintingNft.estimateGas({ walletAddress: selectedWallet?.address })
      .then((res) => {
        setEstimateGas((prev: any) => ({
          ...prev,
          isLoading: false,
          data: res.data,
        }));
      })
      .catch((err) => {
        setEstimateGas((prev: any) => ({
          ...prev,
          isLoading: false,
          error: err,
        }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEstimateGas]);

  const getADAPrice = useCallback(() => {
    MintingNft.getPriceADA()
      .then((res) => {
        setADAPrice(res.data.price);
      })
      .catch(() => {});
  }, [setADAPrice]);

  useEffect(() => {
    return () => {
      setActiveStep(0);
    };
  }, []);

  useEffect(() => {
    getEstimateGas();
    getADAPrice();
  }, [getADAPrice, getEstimateGas, activeStep]);

  const typeOfAttributes = (attributeResponse: IAttributeResponse[]) => {
    const docsTypeInfos = attributeResponse.filter((info) => info.type === TypeAttribute.DOCS);
    const infoTypeInfo = attributeResponse.filter((info) => info.type === TypeAttribute.INFO);

    return {
      docs: docsTypeInfos,
      info: infoTypeInfo,
    };
  };

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const getInfoNftByCategoryId = useCallback(async (categoryId: string) => {
    if (categoryId) {
      try {
        setInfoNFT({
          isLoading: true,
          error: null,
          data: null as unknown as IDataInfo,
        });
        const res = await MintingNft.getAttributeNFTByCategoryId(categoryId);
        setInfoNFT({
          isLoading: false,
          error: null,
          data: typeOfAttributes(res.data) as unknown as IDataInfo,
        });
      } catch (error: any) {
        setInfoNFT({
          isLoading: false,
          error: error.response.data.message || "Something went wrong",
          data: null as unknown as IDataInfo,
        });
      }
    }
  }, []);

  useEffect(() => {
    getInfoNftByCategoryId(selectedCategory.mainCategory?.id);
  }, [selectedCategory.mainCategory, getInfoNftByCategoryId]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = (index?: number) => {
    if (typeof index === "number" && index < steps.length) setActiveStep(index);
    else setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const getFilesAvailable = async () => {
    let array = [];
    const docsFile = docsConfig && docsConfig.map((doc: any) => doc.file);

    if (fileUpload.file) {
      array.push({
        key: "coverImage",
        value: await Users.uploadFiles({ file: fileUpload.file }),
      });
    }
    if (docsFile) {
      array.push({
        key: "docs",
        value: await Users.uploadMultipleDocs({
          files: docsFile as any,
          token: localStorage.getItem("access_token") as string,
        }),
      });
    }
    return Promise.all(array);
  };

  const getFileInfo = () => {
    return new Promise<any>((resolve, reject) => {
      let coverImage: any;
      let docs: any;
      let cover3dImage: any;
      getFilesAvailable()
        .then(async (res) => {
          res.forEach(async (file: any) => {
            if (file.key === "coverImage") {
              coverImage = file.value;
            }
            if (file.key === "docs") {
              docs = file.value;
            }
            if (file3DUpload.link) {
              cover3dImage = file3DUpload.link;
            }
          });

          resolve({ coverImage, docs, cover3dImage });
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleMintData = (type: MintStatus, payloadMint?: any) => {
    // eslint-disable-next-line no-async-promise-executor
    const uploadAttributePromise = new Promise<any>(async (resolve, reject) => {
      const nameField = infoFieldsConfig?.find((item: any) => item.name === "name");
      const descriptionField = infoFieldsConfig?.find((item: any) => item.name === "description");
      setMintedStatus({ type: MintType.INIT, msg: "" });

      if (!payloadMint) {
        try {
          const { coverImage, docs, cover3dImage } = await getFileInfo();
          const infoKeyFormat = infoFieldsConfig
            .filter((item: any) => {
              if (item.id) return item;
            })
            .map((item: any) => {
              return { key: item.id, value: item.value };
            });

          const docsKeyFormat =
            docsConfig &&
            (docsConfig.map((item: any, index: number) => {
              const docLink =
                docs && !docs.data
                  ? item?.value
                  : (Object.values(docs.data).find((doc: any) => doc.order === index + 1) as any)?.link || item?.value;
              return { key: item.id, value: docLink };
            }) as {
              key: any;
              value: any;
            }[]);

          let attributes: any = [];
          if (docsKeyFormat && infoKeyFormat) {
            attributes = [
              ...infoKeyFormat,
              ...(docsKeyFormat as {
                key: any;
                value: any;
              }[]),
            ];
          }
          if (!docsKeyFormat && infoKeyFormat) {
            attributes = [...infoKeyFormat];
          }
          if (docsKeyFormat && !infoKeyFormat) {
            attributes = [...docsKeyFormat];
          }

          attributes = attributes.filter((item: any) => {
            if (item.value) return item;
          });
          const payload: any = {
            categoryId: selectedCategory?.mainCategory?.id || state?.item?.item?.categoryId,
            subCategory: selectedCategory?.subCategory?.id || state?.item?.item?.subCategory,
            name: nameField.value,
            description: descriptionField.value,
            attributes,
            coverImage: coverImage?.data[0]?.link || state?.item?.item?.coverImage,
            walletPassword: spendingPass.value,
            walletAddress: selectedWallet?.address || state?.item?.walletAddress,
            status: type,
            image3d: cover3dImage || state?.item?.item?.cover3DImage,
          };

          if (state?.isEdit) {
            if (type === MintStatus.Minting) {
              await MintingNft.updateNft(state?.item?.item?.id, {
                ...payload,
                status: MintStatus.InDraft,
              });

              const createdMintRes = await MintingNft.createMintNft({ ...payload, status: type });
              resolve(createdMintRes);
            } else {
              const updateRes = await MintingNft.updateNft(state?.item?.item?.id, payload);
              resolve(updateRes);
            }
          } else {
            const createMintRes = await MintingNft.createMintNft(payload);
            resolve(createMintRes);
          }
          setMintedStatus({ type: MintType.SUCCESS, msg: "" });
        } catch (error: any) {
          reject(error?.response?.data?.message);
        }
      } else {
        try {
          const payload = {
            item_id: payloadMint?.item?.id,
            walletPassword: spendingPass?.value,
            walletAddress: payloadMint?.item.walletAddress,
          };
          const createMintRes = await MintingNft.requestMintNFT(payload);
          setMintedStatus({ type: MintType.SUCCESS, msg: "" });
          resolve(createMintRes);
        } catch (error: any) {
          reject(error?.response?.data?.message);
        }
      }
    });

    const handleAfterSuccess = () => {
      return new Promise((resolve, reject) => {
        try {
          setStepLoading(true);
          setMintedStatus({ type: MintType.INIT, msg: "" });

          uploadAttributePromise
            .then((res) => {
              resolve(res);
              return res;
            })
            .catch((error) => {
              setMintedStatus({ type: MintType.FAIL, msg: error });
            })
            .finally(() => {
              setStepLoading(false);
            });
        } catch (error) {
          reject(error);
        }
      });
    };

    return handleAfterSuccess();
  };

  const steps = [
    {
      label: "Choose category",
      Component: (
        <ChooseCategory
          handleNext={handleNext}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
      ),
    },
    {
      label: "NFT information",
      Component: (
        <NftInfo
          handleNext={handleNext}
          infoAttribute={infoNFT?.data || state?.item}
          setFileUpload={setFileUpload}
          fileUpload={fileUpload}
          file3DUpload={file3DUpload}
          setFile3DUpload={setFile3DUpload}
          setInfoFieldsConfig={setInfoFieldsConfig}
          setDocsConfig={setDocsConfig}
          infoFieldsConfig={infoFieldsConfig}
          docsConfig={docsConfig}
          handleMintData={handleMintData}
          stepLoading={stepLoading}
          mintedFailError={mintedStatus.msg}
          isEdit={!!state?.isEdit}
        />
      ),
    },
    {
      label: "Review information",
      Component: (
        <ReviewNFTInfo
          handleNext={handleNext}
          handleBack={handleBack}
          setEstimateGas={setEstimateGas}
          estimateGas={estimateGas}
          setADAPrice={setADAPrice}
          ADAprice={ADAPrice}
          handleMintData={handleMintData}
          stepLoading={stepLoading}
          mintedFailError={mintedStatus.msg}
        />
      ),
    },
    {
      label: "Confirm transaction",
      Component: (
        <ConfirmNFTTransaction
          handleNext={handleNext}
          isFinal={isLastStep}
          setSpendingPass={setSpendingPass}
          spendingPass={spendingPass}
          estimateGas={estimateGas}
          ADAPrice={ADAPrice}
          handleMintData={handleMintData}
          stepLoading={stepLoading}
          mintedStatus={mintedStatus}
          payload={!state?.isEdit ? state?.item : null}
        />
      ),
    },
  ];
  window.onpopstate = function () {
    navigate(links.MY_NFTS);
  };

  return (
    <Box component={"div"}>
      <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
        Wallet&ensp; <>&gt;&ensp;</>{" "}
        <Typography variant="subtitle1" component="span" style={{ color: themeColors.gray }}>
          Minting
        </Typography>
      </Typography>
      <Typography variant="h3">Mint NFT</Typography>
      <Stepper
        steps={steps}
        activeStep={activeStep || 0}
        setActiveStep={setActiveStep}
        allStepsCompleted={allStepsCompleted}
        completedSteps={completedSteps}
        handleReset={handleReset}
        handleComplete={handleComplete}
        handleBack={handleBack}
        handleNext={handleNext}
        completed={completed}
        totalSteps={totalSteps}
      />
      {stepLoading && <Loading />}
    </Box>
  );
};

export default MintingNFT;
