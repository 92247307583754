import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import BoughtItems from "../../../components/BoughtNTFItem";
import { LeftNFTDetails } from "../../../components/LeftNFTDetails";
import { MintStatus, WalletDetailsTabs } from "../../../const";
import { useLocation } from "react-router-dom";
import Items from "../../../services/items";
import CustomRangePagination from "../../../components/RangePagination";

const MyMintingNFTs = ({ title, walletDetails }: { title: string; walletDetails: any }) => {
  const [page, setPage] = React.useState(0);
  const [meta, setMeta] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [listMintingNFT, setListMintingNft] = useState<any>([]);
  const location = useLocation();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const getListMintingNFT = async () => {
    const res = await Items.getListNFTs({
      page: page,
      limit: rowsPerPage,
      status: MintStatus.Minting,
      walletAddress: location?.state?.walletDetails?.address,
      isMinting: true,
    });
    if (res.statusCode === 200) {
      setListMintingNft(res.data.items);
      setMeta(res.data.meta);
    }
  };

  useEffect(() => {
    getListMintingNFT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  return (
    <Grid container>
      <Grid item xs={2} pr={2}>
        <LeftNFTDetails title={title} walletDetails={walletDetails} />
      </Grid>
      <Grid item xs={10}>
        <CustomRangePagination
          onPageChange={handleChangePage}
          meta={meta}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
        />

        <Box component={"div"} display="flex" flexWrap={"wrap"}>
          {listMintingNFT.map((item: any, index: number) => {
            return <BoughtItems key={index} itemDetails={item} hasStatus={true} mintTab={WalletDetailsTabs.minting} />;
          })}
        </Box>
        {listMintingNFT.length > 0 && (
          <CustomRangePagination
            onPageChange={handleChangePage}
            meta={meta}
            page={page}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setPage={setPage}
          />
        )}
      </Grid>
    </Grid>
  );
};
export default MyMintingNFTs;
