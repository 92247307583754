import { useNavigate } from "react-router-dom";
import successIcon from "../../../assets/icons/check-one.svg";
import { Box, Button, IconButton, InputAdornment, styled, TextField, Typography } from "@mui/material";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { validationPatterns } from "../../../validation/pattern";
import Authenticate from "../../../services/authenticate";
import { themeColors } from "../../../theme/schemes/PureLightTheme";
import ErrorMessage from "../../../components/ErrorMessage";
import { MAXIMUM_TEXT_FIELD_LENGTH } from "../../../const";

const CustomButton = styled(Button)({
  " &.Mui-disabled": {
    background: "#B3B3B3",
    color: "white",
  },
});

const PasswordVadilation = styled(Typography)({
  fontSize: "16px !important",
});

function SetupPassword() {
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState("");
  const [password, setPassword] = useState("");
  const [comfirmPassword, setConfirmPassword] = useState("");
  const token = window.location.href.split("=").pop();
  const [errorMsg, setErrorMsg] = useState("");
  const verifyToken = async () => {
    const res = await Authenticate.verifyToken({ token: token });
    if (res.statusCode === 200) {
      setStep(res.data.step);
    }
  };
  const onNextClick = async () => {
    if (password !== comfirmPassword) {
      setErrorMsg("Confirm password does not match");
      return;
    }

    const res = await Authenticate.verifyToken({ token: token });
    if (res.statusCode === 200) {
      const response = await Authenticate.createPassword({
        password: password,
        rePassword: comfirmPassword,
        token: token,
      });
      if (response.statusCode === 200) {
        navigate("/setup-information", { state: { token: token } });
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      setIsShow(true);
    }, 3000);
  }, []);

  const SuccessScreen = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <SVG src={successIcon} />
          <Typography
            variant="h3"
            sx={{
              mb: 1,
              mt: 2,
            }}
          >
            Congratulations!
          </Typography>
        </Box>
        <Typography variant="body1" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          We have successfully verified your account.
        </Typography>
        <br />
        <Box component={"div"} sx={{ width: 555 }}>
          <Typography variant="body1" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
            You will be transferred to the next page in a few moments. If you are not automatically transferred, please
            press the <b>“Next”</b> button below.
          </Typography>
        </Box>
        <Box component={"div"} display={"flex"} justifyContent="center">
          <CustomButton
            variant="contained"
            onClick={() => setIsShow(true)}
            sx={{ marginTop: 3, marginBottom: 3, color: "white", width: 160 }}
          >
            Next
          </CustomButton>
        </Box>
      </>
    );
  };

  useEffect(() => {
    verifyToken();
    if (step === "init-information") {
      navigate("/setup-information", { state: { token: token } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  return (
    <>
      <AuthenticateLayout>
        {isShow ? (
          <>
            <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                }}
              >
                Sign up
              </Typography>
            </Box>

            <Typography variant="body1" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
              Please set up a password for your account
            </Typography>
            <br />
            <PasswordVadilation sx={{ fontWeight: "bold" }}>Password</PasswordVadilation>
            <TextField
              label={password === "" ? "Enter your password" : ""}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              fullWidth
              sx={{
                marginTop: 1,
                "& label": {
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
                width: 560,
              }}
              InputLabelProps={{ shrink: false }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                if (e.target.value.length > MAXIMUM_TEXT_FIELD_LENGTH) {
                  setErrorMsg("Max length 255 characters");
                } else {
                  setErrorMsg("");
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
            ></TextField>
            <PasswordVadilation sx={{ fontWeight: "bold", mt: 2 }}>Confirm password</PasswordVadilation>
            <TextField
              label={comfirmPassword === "" ? "Re-Enter your password" : ""}
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              sx={{
                marginTop: 1,
                "& label": {
                  "&.Mui-focused": {
                    color: "#B3B3B3",
                  },
                },
              }}
              InputLabelProps={{ shrink: false }}
              value={comfirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrorMsg("");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
            ></TextField>
            <ErrorMessage msg={errorMsg} />
            <Box component={"div"} marginTop={2} marginBottom={2}>
              <Box
                component={"div"}
                display="flex"
                alignItems="center"
                color={password.length >= 8 ? themeColors.primary : themeColors.gray}
              >
                <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
                <PasswordVadilation>At least 8 characters.</PasswordVadilation>
              </Box>
              <Box
                component={"div"}
                display="flex"
                alignItems="center"
                color={validationPatterns.mixture.test(password) ? themeColors.primary : themeColors.gray}
              >
                <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
                <PasswordVadilation>A mixture of numbers, uppercase and lowercase letters.</PasswordVadilation>
              </Box>
              <Box
                component={"div"}
                display="flex"
                alignItems="center"
                color={validationPatterns.specialCharacter.test(password) ? themeColors.primary : themeColors.gray}
              >
                <PasswordVadilation marginRight={1}>✓</PasswordVadilation>
                <PasswordVadilation>Include of at least one special character,e.g., ! @ # ? ]</PasswordVadilation>
              </Box>
            </Box>

            <Box component={"div"} display={"flex"} justifyContent="center">
              <Button
                variant="contained"
                sx={{ width: 160 }}
                disabled={
                  !validationPatterns.specialCharacter.test(password) ||
                  !validationPatterns.mixture.test(password) ||
                  password.length < 8 ||
                  password === "" ||
                  comfirmPassword === ""
                }
                onClick={onNextClick}
              >
                Next
              </Button>
            </Box>
          </>
        ) : (
          <SuccessScreen />
        )}
      </AuthenticateLayout>
    </>
  );
}

export default SetupPassword;
