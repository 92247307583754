/* eslint-disable react/no-unknown-property */
/* eslint-disable react/no-unescaped-entities */
import { Box, Button, Grid, Typography } from "@mui/material";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import SVG from "react-inlinesvg";
import EditIcon from "../../assets/icons/nft-detail/edit.svg";
import DoneIcon from "../../assets/icons/done.svg";
import FileIcon from "../../assets/icons/nft-detail/file.svg";
import ClipIcon from "../../assets/icons/nft-detail/clip.svg";
import styled from "styled-components";
import Items from "../../services/items";
import { useEffect, useState } from "react";
import { MintStatus, WalletDetailsTabs } from "../../const";
import { CreatedStatus, InDraftStatus, MintedStatus, MintingStatus } from "../../components/mintStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WhiteButton from "../../components/WhiteButton";
import { links } from "../../constants/links";
import { useWalletContext } from "../../contexts/wallet-provider";
import moment from "moment";
import CheckCircleIcon from "../../assets/icons/nft-detail/check-circle.svg";
import { NFT3Drender } from "../../utils/nft3dRender";
import MintingNft from "../../services/minting-nft";
import _ from "lodash";

const StyledDetailImage = styled.div`
  position: relative;
  margin-right: 2.5rem;
  .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #247cf3;
    font-size: 14px;
    background: #e1eeff;
    padding: 4px 12px;
    border-radius: 100px;
  }
  .preview-image {
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 55px 8px 0;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .list-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
  }
  .action-item {
    padding: 0;
  }
`;

const StyledDetailEventTitle = styled.h4`
  font-size: 20px;
  margin: 25px 0 5px;
`;

const StyledDetailEventItem = styled.div`
  border-bottom: 1px solid ${themeColors.lighterGray};
  padding: 15px 0;
  font-weight: bold;
  font-size: 16px;
  color: ${themeColors.black};
  a {
    font-weight: bold;
    font-size: 16px;
    color: ${themeColors.black};
  }
`;

const CustomButton = styled(Button)`
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 1px;
  height: 40px;
  font-style: normal;
`;
const NFTDetail = () => {
  const [nftDetails, setNFTDetails] = useState<any>();
  const params = useParams();
  const tab = new URLSearchParams(window.location.search);
  tab.get("tab");
  const [docs, setDocs] = useState([]);
  const [events, setEvents] = useState([]);
  const location = useLocation();
  const { state } = useLocation();
  const [payloadMint, setPayloadMint] = useState<any>(null);
  const { selectedWallet } = useWalletContext();
  const navigate = useNavigate();
  const [attribute, setAtribute] = useState([]);
  const getNFTDetails = async () => {
    const res = await Items.getNFTById({ id: params.id });

    if (res.statusCode === 200) {
      setPayloadMint(res.data);
      setNFTDetails(res.data.item);
      setAtribute(res.data.info);
      setDocs(res.data.docs);
      setEvents(res.data.events);
      const response = await MintingNft.getAttributeNFTByCategoryId(res.data.item?.categoryId);
      if (_.get(response, "statusCode") === 200) {
        let info: any[] = [];
        response.data.map((x: any) => {
          if (x.type === "info") {
            info.push(x);
          }
        });
        let newInfo: any[] = [];
        let infoDiff = _.differenceWith(info, res.data.info, function (o1: any, o2: any) {
          return o1["id"] === o2["id"];
        });
        newInfo = res.data.info.concat(infoDiff);
        setPayloadMint({ ...res.data, info: newInfo });
      }
    }
  };
  const redirectTab = (routerTab: any) => {
    if (routerTab === WalletDetailsTabs.minted) return 1;
    else if (routerTab === WalletDetailsTabs.minting) return 2;
    else if (routerTab === WalletDetailsTabs.nonMinted) return 3;
    else return 0;
  };

  useEffect(() => {
    getNFTDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.onpopstate = function () {
    tab.get("tab");
    if (tab.get("tab") ?? location?.state?.mintTab) {
      const routerTab = tab.get("tab") ?? location?.state?.mintTab;
      navigate(links.MY_NFTS, { state: { nftTab: redirectTab(routerTab) } });
    }
  };
  history.pushState({}, "");

  const statusRender = (e: string) => {
    if (e === MintStatus.InDraft) {
      return <InDraftStatus />;
    }
    if (e === MintStatus.Created) {
      return <CreatedStatus />;
    }
    if (e === MintStatus.Minting) {
      return <MintingStatus />;
    }
    return <MintedStatus />;
  };

  const onSendNFT = () => {
    navigate(links.SEND_NFT, { state: { address: "", nftDetails: nftDetails } });
  };

  useEffect(() => {
    if (state?.isSendNFT === true && nftDetails?.status !== MintStatus.Minted) {
      const interval = setInterval(() => {
        if (nftDetails?.status !== MintStatus.Minted) {
          getNFTDetails();
        }
      }, 6000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box component={"div"}>
      <Box component={"div"} display={"flex"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Wallet&ensp; <>&gt;&ensp;</> Wallet details&ensp; <>&gt;&ensp;</>
        </Typography>
        <Typography variant="subtitle1" style={{ color: themeColors.gray }}>
          Name
        </Typography>
      </Box>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h3">
          {nftDetails?.name}{" "}
          {(location?.state?.minTab === WalletDetailsTabs.minted || tab.get("tab") === WalletDetailsTabs.minted) && (
            <SVG src={DoneIcon} />
          )}{" "}
        </Typography>
        <Box component={"div"}></Box>
        {(location?.state?.minTab === WalletDetailsTabs.nonMinted || tab.get("tab") === WalletDetailsTabs.nonMinted) &&
          !state?.isSendNFT && (
            <Box component={"div"}>
              <WhiteButton
                onClick={() =>
                  navigate(links.MINTING_NFT, {
                    state: { currentStep: 1, item: JSON.parse(JSON.stringify(payloadMint)), isEdit: true },
                  })
                }
                label="Edit"
                startIcon={<SVG src={EditIcon} />}
              />
              &ensp;
              <CustomButton
                variant="contained"
                onClick={() =>
                  navigate(links.MINTING_NFT, {
                    state: { currentStep: 3, item: JSON.parse(JSON.stringify(payloadMint)) },
                  })
                }
              >
                Send NFT
              </CustomButton>
            </Box>
          )}
        {(location?.state?.minTab === WalletDetailsTabs.minted || tab.get("tab") === WalletDetailsTabs.minted) && (
          <Box component={"div"}>
            <CustomButton variant="contained" sx={{ width: 100 }} onClick={onSendNFT}>
              Send NFT
            </CustomButton>
          </Box>
        )}
      </Box>
      <Typography sx={{ mb: 2 }}>{nftDetails?.description}</Typography>
      <Box component={"div"} sx={{ maxWidth: "80%" }}>
        <Grid container mb={4}>
          <Grid item xs={5}>
            <StyledDetailImage>
              <Box component={"div"} className="preview-image">
                {nftDetails?.image3d ? (
                  nftDetails?.image3d.includes(".mp4") ? (
                    <Box component={"div"}>
                      <video controls autoPlay style={{ maxWidth: "100%" }}>
                        <source src={nftDetails?.image3d} type="video/mp4" />
                      </video>
                    </Box>
                  ) : (
                    NFT3Drender({
                      // eslint-disable-next-line max-len
                      link: nftDetails?.image3d,
                    })
                  )
                ) : (
                  <img src={nftDetails?.coverImage} alt={nftDetails?.name} />
                )}
                <Box component={"div"} position={"absolute"} top={0} left={0}>
                  {statusRender(nftDetails?.status)}
                </Box>
              </Box>
            </StyledDetailImage>
            {state?.isSendNFT ? (
              <Box component={"div"} sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ fontSize: "18px", px: 8 }}
                  onClick={() =>
                    navigate(links.SEND_NFT, {
                      state: { walletAddress: selectedWallet.address, itemID: params.id },
                    })
                  }
                  disabled={nftDetails?.status !== MintStatus.Minted}
                >
                  Send NFT
                </Button>
              </Box>
            ) : (
              !!nftDetails &&
              nftDetails.status !== MintStatus.Minted &&
              nftDetails.status !== MintStatus.Minting && (
                <Box component={"div"} sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{ fontSize: "18px", px: 8 }}
                    onClick={() =>
                      navigate(links.MINTING_NFT, {
                        state: { currentStep: 1, item: JSON.parse(JSON.stringify(payloadMint)), isEdit: true },
                      })
                    }
                  >
                    Mint NFT
                  </Button>
                </Box>
              )
            )}
          </Grid>
          <Grid item xs={7} mt={3} sx={{ fontSize: 16 }}>
            <Grid container mb={2}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Policy ID
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                <span style={{ color: themeColors.lightBlue }}>
                  {nftDetails?.policyId ?? "Will be generated after submiting the request"}
                </span>
              </Grid>
            </Grid>

            <Grid container mb={2}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Creator
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                {nftDetails?.corporate?.name}
                <SVG src={CheckCircleIcon} style={{ margin: "0px 5px -2px 5px" }} />(
                <span style={{ color: themeColors.lightBlue }}>
                  {`${nftDetails?.createdWalletAddress?.slice(0, 6)}...${nftDetails?.createdWalletAddress?.slice(-6)}`}
                </span>
                )
              </Grid>
            </Grid>

            <Grid container mb={2}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Owner
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                (
                <span style={{ color: themeColors.lightBlue }}>
                  {`${nftDetails?.walletAddress?.slice(0, 6)}...${nftDetails?.walletAddress?.slice(-6)}`}
                </span>
                )
              </Grid>
            </Grid>

            <Grid container mb={2}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Created date
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                {moment(nftDetails?.createdAt).format("MM/DD/YYYY")}
              </Grid>
            </Grid>

            <Grid container mb={2}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Brand
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                {nftDetails?.corporate?.name}
              </Grid>
            </Grid>

            {attribute.map((x: any, index: number) => {
              return (
                <Grid container mb={2} key={index}>
                  <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                    {x.name}
                  </Grid>
                  <Grid item sx={{ pl: 1 }}>
                    {x.value}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <StyledDetailEventTitle>Docs</StyledDetailEventTitle>
        {docs.length > 0 &&
          docs.map((doc: any, index: number) => {
            return (
              <StyledDetailEventItem key={index}>
                <Box component={"div"} display="flex" justifyContent="space-between" alignItems="center">
                  <Box component={"div"} display="flex" columnGap="5px">
                    <SVG src={FileIcon} />
                    <a href={doc.value} target="_blank" rel="noreferrer">
                      {doc.name}
                    </a>
                  </Box>
                  <SVG src={ClipIcon} />
                </Box>
              </StyledDetailEventItem>
            );
          })}
        <StyledDetailEventTitle>Event</StyledDetailEventTitle>
        {events.length > 0 &&
          events.map((event: any, index: number) => {
            return (
              <StyledDetailEventItem key={index}>
                <Box component={"div"} display="flex" justifyContent="space-between" alignItems="center">
                  <Box component={"div"} display="flex" columnGap="5px">
                    <SVG src={FileIcon} />
                    <a href={event.value} target="_blank" rel="noreferrer">
                      {event.name}
                    </a>
                  </Box>
                  <SVG src={ClipIcon} />
                </Box>
              </StyledDetailEventItem>
            );
          })}
      </Box>
    </Box>
  );
};
export default NFTDetail;
