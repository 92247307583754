import { Typography, TextField, Box, StandardTextFieldProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MAXIMUM_TEXT_FIELD_LENGTH } from "../../const";

interface ICustomTextField extends StandardTextFieldProps {
  label: string;
  value?: any;
  onChange?: any;
  placeholder?: any;
  type?: string;
  required?: boolean;
  className?: any;
}

const useStyles = makeStyles(() => ({
  textfield: {
    "& label": {
      "&.Mui-focused": {
        color: "#B3B3B3",
      },
    },
    "& .Mui-disabled": {
      // background: "#E0E0E0",
    },
  },
  textInput: {
    fontSize: 16,
  },
  longTextInput: {
    "& .MuiInputBase-input": {
      display: "inline-block",
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      textOverflow: "ellipsis",
    },
  },
}));

const CustomTextField = (props: ICustomTextField) => {
  const classes = useStyles();
  const { label, value, onChange, placeholder, type, required, className } = props;

  return (
    <Box component={"div"} sx={{ marginBottom: 3 }}>
      <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
        <Box component={"div"} display="flex">
          {label} &nbsp;
          <div style={{ color: "red" }}>{required && "*"}</div>
        </Box>
      </Typography>
      <TextField
        className={`${classes.textfield} ${classes.longTextInput} ${className}`}
        {...props}
        label={value === "" ? placeholder : ""}
        fullWidth
        InputLabelProps={{ shrink: false, style: { fontSize: 16 } }}
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value)}
        type={type ? "password" : "text"}
        inputProps={{ style: { fontSize: 16 }, maxLength: MAXIMUM_TEXT_FIELD_LENGTH }}
      ></TextField>
    </Box>
  );
};

export default CustomTextField;
