import { validationPatterns } from "../validation/pattern";
import { toast } from "react-toastify";

export interface Props {
  children: React.ReactNode;
}
const MAX_MODAL_FILE_SIZE = 20*1048576;
export const MAX_NFT_IMAGE_SIZE = 10*1048576;

export const emailValidation = (email: string) => {
  const regEx = validationPatterns.email;
  if(email){
    if (regEx.test(email)) {
     return("Valid Email");
   } else if (!regEx.test(email)) {
     return("Invalid email");
   }
}};

export const copyAddressToClipboard = (clipboard: string, toastMsg: string) => {
  navigator.clipboard.writeText(clipboard);
  toast(toastMsg);
};

export const getPage = (params: any) => {
  return params?.page === 0 ? 1 : params?.page + 1;
};

export const uploadFileSize = (size: number) => {
  if(size > MAX_MODAL_FILE_SIZE){
    return false;
  }
  return true;
};

export const nftModalType = [".glb"];

export const isNFTModelType = (params: any) => {
  if(!nftModalType.some((substring: string) => params.includes(substring))){
    return false;
  }return true;
};
