import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useLayoutEffect, useState } from "react";
import Banner from "../../../services/banner";
import styled from "styled-components";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { BannerState } from "..";
import CancelIcon from "@mui/icons-material/Cancel";
import colors from "../../../theme/colors";

type Country = {
  id?: string;
  code: string;
  name: string;
  isSelected: boolean;
};

type DataCountries = {
  isSearch: boolean;
  listSearch: Country[];
  list: Country[];
  value: string;
};

const StyledSpecific = styled.div`
  border: 1px solid #c7c7c7;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 24px;
  .item {
    padding: 20px;
  }
  .left {
    border-right: 1px solid #c7c7c7;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #616161;
    margin-bottom: 16px;
  }
  .search {
    margin-bottom: 28px;
  }
  .list {
    height: 400px;
    overflow: auto;
    &-item {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #424242;
      margin-bottom: 8px;
      &:hover {
        opacity: 0.8;
      }
      &.selected {
        height: 0;
        opacity: 0;
        visibility: hidden;
        margin-bottom: 0;
      }
    }
  }
`;

const StyledSearch = styled(TextField)({
  marginBottom: "10px",
  "& .MuiInputBase-input": {
    padding: "12px",
  },
});

const StyledCountryCount = styled.span`
  color: #518ce0;
  text-decoration: underline;
  margin: 6px 0 0 0;
  cursor: pointer;
`;

interface ICountries {
  bannerState: BannerState;
  isAll?: boolean;
  selectedCountries?: any[];
  setCountries?: ({ countries, isAll }: any) => void;
}

const Countries = ({ bannerState, isAll, selectedCountries = [], setCountries }: ICountries) => {
  const [countrySelect, setCountrySelect] = useState({
    type: "",
    specificShow: false,
    specificConfirm: true,
  });
  const [dataCountries, setDataCountries] = useState<DataCountries>({
    isSearch: false,
    listSearch: [],
    list: [],
    value: "",
  });
  const [dataSelectedCountries, setDataSelectedCountries] = useState<DataCountries>({
    isSearch: false,
    listSearch: [],
    list: [],
    value: "",
  });

  useLayoutEffect(() => {
    if (selectedCountries.length > 0) {
      setDataSelectedCountries((prev) => ({ ...prev, list: selectedCountries }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  const renderEmptyCountries = () => {
    if (dataCountries.isSearch && dataCountries.listSearch.length === 0) {
      return (
        <Box
          component={"div"}
          style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
        >
          <Typography variant="h6">No countries found</Typography>
        </Box>
      );
    }
  };

  const renderEmptySelectedCountries = () => {
    if (dataSelectedCountries.isSearch && dataSelectedCountries.listSearch.length === 0) {
      return (
        <Box
          component={"div"}
          style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
        >
          <Typography variant="h6">No countries found</Typography>
        </Box>
      );
    }
  };

  const fetchCountries = async () => {
    try {
      const res = await Banner.getCountries();
      if (res.data.length > 0) {
        const countries = res.data.map((country: any) => ({
          id: country.id,
          code: country.code,
          name: country.name,
          isSelected: false,
        }));
        setDataCountries({ ...dataCountries, list: countries });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleSelectCountry = (country: any) => {
    let findIndex = -1;
    const newSelectedCountries = [...dataSelectedCountries.list];
    dataCountries.list.forEach((item, index) => {
      if (item.name === country.name) {
        findIndex = index;
        newSelectedCountries.push(item);
      }
    });
    // Update countries list
    const updatedCountries = [...dataCountries.list];
    updatedCountries[findIndex].isSelected = true;
    setDataCountries({
      ...dataCountries,
      list: updatedCountries,
    });

    // Update selected countries
    setDataSelectedCountries({
      ...dataSelectedCountries,
      list: newSelectedCountries,
    });
  };

  const handleUnSelectCountry = (country: any) => {
    // Update countries list
    const findIndex = dataCountries.list.findIndex((item) => item.name === country.name);
    const updatedCountries = [...dataCountries.list];
    updatedCountries[findIndex].isSelected = false;
    setDataCountries({
      ...dataCountries,
      list: updatedCountries,
    });

    // Update selected countries
    const findIndexSelected = dataSelectedCountries.list.findIndex((item) => item.name === country.name);
    const updateSelectedCountries = [...dataSelectedCountries.list];
    updateSelectedCountries.splice(findIndexSelected, 1);
    setDataSelectedCountries({
      ...dataSelectedCountries,
      list: updateSelectedCountries,
    });

    if (dataSelectedCountries.isSearch) {
      const findIndexSelected = dataSelectedCountries.listSearch.findIndex((item) => item.name === country.name);
      const updateSelectedCountries = [...dataSelectedCountries.listSearch];
      updateSelectedCountries.splice(findIndexSelected, 1);
      setDataSelectedCountries({
        ...dataSelectedCountries,
        isSearch: true,
        listSearch: updateSelectedCountries,
      });
    }
  };

  const handleSearchCountries = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 0) {
      const filteredCountries = dataCountries.list.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase()),
      );
      setDataCountries({
        ...dataCountries,
        isSearch: true,
        listSearch: filteredCountries,
        value,
      });
    } else {
      setDataCountries({
        ...dataCountries,
        isSearch: false,
        listSearch: [],
        value,
      });
    }
  };

  const handleSearchSelectedCountries = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 0) {
      const filteredCountries = dataSelectedCountries.list.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase()),
      );
      setDataSelectedCountries({
        ...dataSelectedCountries,
        isSearch: true,
        listSearch: filteredCountries,
        value,
      });
    } else {
      setDataSelectedCountries({
        ...dataSelectedCountries,
        isSearch: false,
        listSearch: [],
        value,
      });
    }
  };

  const handleConfirm = () => {
    setCountrySelect({
      ...countrySelect,
      specificShow: false,
      specificConfirm: true,
    });

    const selectedCountriesId = dataSelectedCountries.list.map((country) => country.id);
    setCountries && setCountries({ countries: selectedCountriesId, isAll: false });
  };

  const renderCountryCount = () => {
    if (!countrySelect.specificConfirm) return null;
    const counter = dataSelectedCountries.list.length;
    return (
      <StyledCountryCount
        onClick={() =>
          setCountrySelect({
            ...countrySelect,
            specificShow: !countrySelect.specificShow,
          })
        }
      >
        {counter} {counter > 1 ? "Countries" : "Country"}
      </StyledCountryCount>
    );
  };

  useEffect(() => {
    fetchCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof isAll == "boolean") {
      setCountrySelect({
        ...countrySelect,
        type: isAll ? "all" : "specific",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAll]);

  const listSelectedCountries = dataSelectedCountries.isSearch
    ? dataSelectedCountries.listSearch
    : dataSelectedCountries.list;
  const listCountries = (dataCountries.isSearch ? dataCountries.listSearch : dataCountries.list).filter((country) => {
    const isExist = listSelectedCountries.find((item) => item.id === country.id);
    return !isExist;
  });

  return (
    <Box component={"div"} mt={3}>
      <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
        Country <span style={{ color: "red" }}>*</span>
      </Typography>
      <FormControl>
        <RadioGroup
          row
          value={countrySelect.type}
          onChange={(e) => {
            if (e.target.value === "specific") {
              const selectedCountriesId = dataSelectedCountries.list.map((country) => country.id);
              setCountries && setCountries({ countries: selectedCountriesId, isAll: false });
              setCountrySelect({
                type: e.target.value,
                specificShow: true,
                specificConfirm: false,
              });
            } else {
              setCountries && setCountries({ countries: [], isAll: true });

              setCountrySelect({
                type: e.target.value,
                specificShow: false,
                specificConfirm: false,
              });
            }
          }}
        >
          <FormControlLabel value="all" control={<Radio disabled={bannerState === BannerState.DETAIL} />} label="All" />
          <FormControlLabel
            value="specific"
            control={<Radio disabled={bannerState === BannerState.DETAIL} />}
            label="Specific"
          />
          {listSelectedCountries.length ? renderCountryCount() : null}
        </RadioGroup>
      </FormControl>
      {countrySelect.type === "specific" && !!countrySelect.specificShow && (
        <StyledSpecific>
          <Box component={"div"} className="item left">
            <Typography className="title">Countries list</Typography>
            <Box component={"div"} className="search">
              <StyledSearch
                value={dataCountries.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {dataCountries.isSearch ? (
                        <InputAdornment position="end">
                          {}
                          <CancelIcon
                            sx={{
                              color: colors.lightGrey,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setDataCountries((prev) => ({ ...prev, isSearch: false, listSearch: [], value: "" }))
                            }
                          />
                        </InputAdornment>
                      ) : null}
                    </>
                  ),
                }}
                placeholder="Search..."
                fullWidth
                onChange={handleSearchCountries}
              />
              {bannerState !== BannerState.DETAIL && (
                <Box component={"div"} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ paddingLeft: "40px", paddingRight: "40px", opacity: 0, visibility: "hidden" }}
                  >
                    Confirm
                  </Button>
                </Box>
              )}
            </Box>
            <Box component={"div"} className="list" sx={{ position: "relative" }}>
              {renderEmptyCountries()}
              {listCountries.map((country: any) => (
                <Box
                  component={"div"}
                  key={country.name}
                  className={`list-item ${country.isSelected ? "selected" : ""}`}
                  style={{ cursor: bannerState === BannerState.DETAIL ? "default" : "pointer" }}
                  onClick={() => (bannerState === BannerState.DETAIL ? false : handleSelectCountry(country))}
                >
                  {country.name}
                </Box>
              ))}
            </Box>
          </Box>
          <Box component={"div"} className="item">
            <Typography className="title">Selected countries</Typography>
            <Box component={"div"} className="search">
              <StyledSearch
                value={dataSelectedCountries.value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {dataSelectedCountries.isSearch ? (
                        <InputAdornment position="end">
                          <CancelIcon
                            sx={{
                              color: colors.lightGrey,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setDataSelectedCountries((prev) => ({
                                ...prev,
                                isSearch: false,
                                listSearch: [],
                                value: "",
                              }))
                            }
                          />
                        </InputAdornment>
                      ) : null}
                    </>
                  ),
                }}
                placeholder="Search..."
                fullWidth
                onChange={handleSearchSelectedCountries}
              />
              {bannerState !== BannerState.DETAIL && (
                <Box component={"div"} display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ paddingLeft: "40px", paddingRight: "40px" }}
                    onClick={handleConfirm}
                    disabled={listSelectedCountries.length === 0}
                  >
                    Confirm
                  </Button>
                </Box>
              )}
            </Box>
            <Box component={"div"} className="list" sx={{ position: "relative" }}>
              {renderEmptySelectedCountries()}
              {listSelectedCountries.map((country: any) => (
                <Box
                  component={"div"}
                  key={country.name}
                  className="list-item"
                  style={{ cursor: bannerState === BannerState.DETAIL ? "default" : "pointer" }}
                  onClick={() => (bannerState === BannerState.DETAIL ? false : handleUnSelectCountry(country))}
                >
                  {country.name}
                </Box>
              ))}
            </Box>
          </Box>
        </StyledSpecific>
      )}
    </Box>
  );
};

export default Countries;
