import { useLocation, useNavigate } from "react-router-dom";
import mailIcon from "../../../assets/icons/mail-send 1.svg";
import { Box, Button, styled, Typography } from "@mui/material";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import Authenticate from "../../../services/authenticate";

const CustomButton = styled(Button)({
  " &.Mui-disabled": {
    background: "#B3B3B3",
    color: "white",
  },
});

function EmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (countdown <= 0) {
      return;
    }
    const timer = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countdown]);

  const resendClick = async () => {
    const res = await Authenticate.resendVerify({ email: location.state.email });
    if (res.statusCode === 200) {
      setCountdown(300);
    }
  };

  return (
    <>
      <AuthenticateLayout>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <SVG src={mailIcon} />
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Thank you for joining us on DVOLD!
          </Typography>
        </Box>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          A link has been sent to <b>{location.state.email}</b> to verify your
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          account. If you have not received the email after a few minutes
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          , please check your spam folder.
        </Typography>
        <Box component={"div"} display={"flex"} justifyContent="center">
          <CustomButton
            variant="contained"
            disabled={countdown !== 0}
            onClick={resendClick}
            sx={{ marginTop: 3, marginBottom: 3, color: "white" }}
          >
            Resend verification email {countdown !== 0 && `(${countdown}s)`}
          </CustomButton>
        </Box>
        <Box component={"div"} display={"flex"} justifyContent="center" color={"#518CE0"} sx={{ cursor: "pointer" }}>
          <u aria-hidden="true" onClick={() => navigate("/login")}>
            Back to Sign in
          </u>
        </Box>
      </AuthenticateLayout>
    </>
  );
}

export default EmailVerification;
