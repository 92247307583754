import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import { Avatar, styled, Typography, Dialog, Box } from "@mui/material";
import { useDialogContext } from "../../contexts/dialog-provider";

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-paper {
      overflow: visible;
    }
`,
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.success.main};
    color: ${theme.palette.success.contrastText};
    width: ${theme.spacing(12)};
    height: ${theme.spacing(12)};
    box-shadow: ${theme.colors.shadows.success};
    top: -${theme.spacing(6)};
    position: absolute;
    left: 50%;
    margin-left: -${theme.spacing(6)};

    .MuiSvgIcon-root {
      font-size: ${theme.typography.pxToRem(45)};
    }
`,
);

export const AvatarFailure = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.error.main};
    color: ${theme.palette.error.contrastText};
    width: ${theme.spacing(12)};
    height: ${theme.spacing(12)};
    box-shadow: ${theme.colors.shadows.error};
    top: -${theme.spacing(6)};
    position: absolute;
    left: 50%;
    margin-left: -${theme.spacing(6)};

    .MuiSvgIcon-root {
      font-size: ${theme.typography.pxToRem(45)};
    }
`,
);

interface ICustomDialogProps {
  children?: React.ReactNode;
  disableClose?: boolean;
}

const CustomDialog = ({ children, disableClose = false }: ICustomDialogProps) => {
  const { isOpen, setIsOpen, message, isSuccess, isFailure } = useDialogContext();
  return (
    <DialogWrapper
      open={isOpen}
      maxWidth="sm"
      fullWidth
      keepMounted
      onClose={() => (disableClose ? null : setIsOpen(false))}
    >
      <Box
        component={"div"}
        sx={{
          px: 4,
          pb: 4,
          pt: 10,
        }}
      >
        {isFailure && (
          <AvatarFailure>
            <ClearTwoToneIcon />
          </AvatarFailure>
        )}
        {isSuccess && (
          <AvatarSuccess>
            <CheckTwoToneIcon />
          </AvatarSuccess>
        )}

        {/*<Collapse in={true}>*/}
        {/*  <Alert*/}
        {/*    action={*/}
        {/*      <IconButton*/}
        {/*        aria-label="close"*/}
        {/*        color="inherit"*/}
        {/*        size="small"*/}
        {/*        onClick={() => {*/}
        {/*          setIsOpenAlert(false);*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <CloseIcon fontSize="inherit" />*/}
        {/*      </IconButton>*/}
        {/*    }*/}
        {/*    severity="info"*/}
        {/*  >*/}
        {/*    {message}*/}
        {/*    /!*{t("The sign up instructions have been sent to your email")}*!/*/}
        {/*  </Alert>*/}
        {/*</Collapse>*/}

        <Typography
          align="center"
          sx={{
            py: 4,
            px: 10,
          }}
          variant="h3"
        >
          {message}
        </Typography>
        {children && children}
      </Box>
    </DialogWrapper>
  );
};

export default CustomDialog;
