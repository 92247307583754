import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import { OfferStatus } from "../../const";
import CustomModal from "../modal";
import { useState } from "react";
import WhiteButton from "../WhiteButton";
import warning from "../../assets/icons/WarningCircle (1).svg";
import SVG from "react-inlinesvg";
import moment from "moment";
import { ApprovedStatus, CancelledStatus, ExpiredStatus, RejectedStatus, WaitingStatus } from "../offersStatus";
import priceTag from "../../assets/icons/priceTag.svg";
import Offers from "../../services/offers";
import { toast } from "react-toastify";
import useResponsive from "../../utils/responsive";
import { useNavigate } from "react-router-dom";

enum OfferType {
  BuyDeposited = "Buy deposited",
  BuyOffer = "Buy offer",
  FreeOffer = "Free offer",
  Mint = "Mint",
}

interface IOfferItems {
  itemDetails?: any;
  hasStatus?: boolean;
  mintTab?: string;
  type: string;
  getListOffer?: any;
}

const useStyles = makeStyles({
  detailsContainer: {
    border: `1px solid ${themeColors.lighterGray1}`,
    borderRadius: 16,
    padding: 10,
    marginTop: 20,
    marginRight: 20,
    position: "relative",
  },
  img: {
    height: 160,
    width: "100%",
    borderRadius: 16,
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OffersItems = ({ itemDetails, mintTab, type, getListOffer }: IOfferItems) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { isDesktopOrLaptop } = useResponsive();

  const statusRender = (e: string) => {
    if (e === OfferStatus.Canceled) {
      return <CancelledStatus />;
    }
    if (e === OfferStatus.Approved) {
      return <ApprovedStatus />;
    }
    if (e === OfferStatus.Rejected) {
      return <RejectedStatus />;
    }
    if (e === OfferStatus.Expired) {
      return <ExpiredStatus />;
    }
    return <WaitingStatus />;
  };

  const onAccept = async () => {
    const res = await Offers.rejectApproveOffer({ id: itemDetails.id, status: "approved" });
    if (res.statusCode === 200) {
      getListOffer();
      setShowModal(false);
      toast("Accept offer successfully.");
    }
  };

  const cancelRequest = async () => {
    if (type === "sent") {
      const res = await Offers.cancelContinueOffer({ id: itemDetails.id });
      if (res.statusCode === 200) {
        getListOffer();
        setShowModal(false);
        toast("Your request has been cancelled");
      }
    } else {
      const res = await Offers.rejectApproveOffer({ id: itemDetails.id, status: "rejected" });
      if (res.statusCode === 200) {
        getListOffer();
        setShowModal(false);
        toast("Reject offer successfully.");
      }
    }
  };

  const renderOfferType = (offerType: string, status: string) => {
    if (offerType === "buyOffer") {
      return OfferType.BuyDeposited;
    } else if (offerType === "makeOffer") {
      return OfferType.BuyOffer;
    } else if (offerType === "requestOffer") {
      if (status === "minted") {
        return OfferType.FreeOffer;
      } else if (status === "created") {
        return OfferType.Mint;
      }
    } else {
      return "";
    }
  };

  return (
    <Box
      component={"div"}
      className={classes.detailsContainer}
      display="flex"
      flexDirection={"column"}
      justifyContent="space-between"
      sx={{ width: isDesktopOrLaptop ? "20%" : "30%" }}
    >
      <Box component={"div"}>
        <Box
          component={"div"}
          sx={{ position: "relative", cursor: "pointer" }}
          onClick={() => navigate(`/offer-details/${itemDetails?.itemId}`, { state: { type: type } })}
        >
          <img className={classes.img} src={itemDetails?.itemImage} alt="" />
          <Box
            component={"div"}
            position={"absolute"}
            display="flex"
            p={1}
            sx={{
              border: `1px solid ${themeColors.lighterGray1}`,
              width: 110,
              borderRadius: 16,
              top: 0,
              mt: 1,
              background: themeColors.white,
            }}
          >
            <SVG src={priceTag} />
            <Typography variant="h4">&nbsp; {itemDetails?.price} ADA </Typography>
          </Box>
          <Typography variant="h4" mb={1} sx={{ fontSize: 20 }}>
            {itemDetails?.name}
          </Typography>
          <Typography variant="subtitle1" mb={3}>
            {itemDetails?.description}
          </Typography>
        </Box>

        <Typography variant="h4"> {itemDetails.item} </Typography>
        <Typography variant="subtitle1" mt={2} mb={3}>
          {itemDetails.itemDescription}
        </Typography>
      </Box>
      <Box component={"div"} display="flex" flexDirection="column">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5"> Type </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" textTransform={"capitalize"}>
              {renderOfferType(itemDetails?.offerType, itemDetails?.itemOffer?.status)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5"> Sending date </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"> {moment(itemDetails.createdAt).format("DD/MM/YYYY")} </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5"> Sent to </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{ color: themeColors.lightBlue }}>
              {itemDetails?.receiverWallet.slice(0, 6)}...{itemDetails?.receiverWallet.slice(-6)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h5"> Status </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1"> {statusRender(itemDetails.status)}</Typography>
          </Grid>
        </Grid>

        {type === "received" ? (
          <Box component={"div"} display="flex" mt={2}>
            {itemDetails.status === OfferStatus.Waiting && (
              <>
                <Box component={"div"} mr={1} width="100%">
                  <Button variant="outlined" fullWidth color="error" onClick={() => setShowModal(true)}>
                    Reject
                  </Button>
                </Box>
                <Box component={"div"} ml={1} width="100%">
                  <Button variant="contained" fullWidth onClick={onAccept}>
                    Accept
                  </Button>
                </Box>
              </>
            )}
          </Box>
        ) : (
          itemDetails.status === OfferStatus.Waiting && (
            <Box component={"div"} display="flex" mt={2} justifyContent="center">
              <Box component={"div"} mr={1}>
                <Button variant="outlined" fullWidth color="error" onClick={() => setShowModal(true)}>
                  Cancel request
                </Button>
              </Box>
            </Box>
          )
        )}
      </Box>
      {showModal && (
        <CustomModal open={showModal} onClose={() => setShowModal(false)} sx={{ width: 500 }}>
          <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
            <SVG src={warning} />
            <Typography
              variant="h3"
              sx={{
                mb: 2,
                mt: 2,
              }}
            >
              {type === "sent" ? "Cancel" : "Reject"} offer confirmation
            </Typography>
            <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }} mb={2}>
              Are you sure you want to {type === "sent" ? "cancel" : "reject"} this offer?
            </Typography>
            <Box component={"div"} display="flex" justifyContent="center">
              <Button variant="contained" onClick={() => setShowModal(false)} sx={{ width: "150px", mr: 2 }}>
                Cancel
              </Button>
              <WhiteButton onClick={cancelRequest} label="Confirm" width="150px" />
            </Box>
          </Box>
        </CustomModal>
      )}
    </Box>
  );
};
export default OffersItems;
