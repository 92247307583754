/* eslint-disable quotes */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import WhiteButton from "../../components/WhiteButton";
import { localStorageHelper } from "../../helpers";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import Editor from "./Editor";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import renderHTML from "react-render-html";
import StaticService, { PageTypeEnum } from "../../services/static";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import { Loading } from "../../components";
import ErrorMessage from "../../components/ErrorMessage";
import CustomModal from "../../components/modal";
import warning from "../../assets/icons/WarningCircle (1).svg";
import SVG from "react-inlinesvg";

interface IStaticProps {
  provide: {
    type: PageTypeEnum;
    label: string;
    category?: string;
  };
}

const StatusRadio = styled(Radio)(
  () => `
  '& .MuiSvgIcon-root': {
    fontSize: 10px,
  },
    `,
);

export enum PageStatus {
  VIEW = "view",
  EDIT = "edit",
  CREATE = "create",
}

const Static: React.FunctionComponent<IStaticProps> = (props) => {
  const { provide } = props;
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const localStorageKey = React.useMemo(() => `${provide.label}-content`, [provide.label]);

  const getContentFromLocalStorage = () => {
    return localStorageHelper.getItem(localStorageKey) || "";
  };

  const getStaticPage = async () => {
    try {
      setIsLoading(true);
      setError("");
      const res = (await StaticService.getPage(provide.type)) as any;
      setStaticInfo((prev) => ({
        ...prev,
        name: res.data?.name,
        content: res.data?.content,
        status: res.data?.status,
      }));
      setIsLoading(false);
      setError("");
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const [staticInfo, setStaticInfo] = useState({
    name: "",
    content: getContentFromLocalStorage(),
    status: "active" as "active" | "inactive",
    text: "",
  });

  const [pageStatus, setPageState] = React.useState<PageStatus>(PageStatus.VIEW);

  useEffect(() => {
    getStaticPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provide.type]);

  React.useEffect(() => {
    setStaticInfo((prev) => ({ ...prev, content: localStorageHelper.getItem(`${provide.label}-content`) || "" }));
  }, [location.pathname, provide.label]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStaticInfo((prev) => ({ ...prev, status: event.target.value as "active" | "inactive" }));
  };

  const handleSaveContent = (e: any) => {
    setStaticInfo((prev) => ({ ...prev, content: e.content, text: e.text }));
    localStorageHelper.setItem(localStorageKey, e.content);
  };

  const handleSavePage = (e: any) => {
    e.preventDefault();
    setPageState(PageStatus.VIEW);
  };

  const isDisabled = staticInfo.text.trim() === "";
  const renderCreate = () => {
    const handleEdit = async () => {
      try {
        setIsLoading(true);
        await StaticService.uploadPage(provide.type, {
          content: staticInfo.content,
          status: staticInfo.status,
        });

        setIsLoading(false);
        setShowModal(false);
      } catch (error: any) {
        setError(error.response.data.message || "Something went wrong");
      }
    };

    return (
      <>
        <Typography variant="h3" style={{ marginBottom: "0.5rem" }}>
          {provide.category ?? provide.label}
        </Typography>

        <Box
          component={"div"}
          display="flex"
          justifyContent="flexStart"
          alignItems="baseline"
          gap={3}
          style={{ marginBottom: "1.5rem" }}
        >
          <Typography variant="body2" style={{ color: themeColors.black, fontWeight: "bold" }}>
            Category
          </Typography>
          <Typography variant="body2" style={{ color: themeColors.gray }}>
            {provide.category ?? provide.label}
          </Typography>
        </Box>

        <Box component={"div"} style={{ marginBottom: "1.5rem" }}>
          <FormControl>
            <FormLabel>
              <Typography variant="body2" style={{ color: themeColors.black, fontWeight: "bold" }}>
                Status <span style={{ color: "red" }}>*</span>
              </Typography>
            </FormLabel>
            <RadioGroup row value={staticInfo.status} onChange={handleChange}>
              <FormControlLabel
                value="active"
                control={<StatusRadio />}
                label={
                  <Typography variant="body2" style={{ color: themeColors.black }}>
                    Active
                  </Typography>
                }
              />
              <Box component={"div"} width={"15px"} />
              <FormControlLabel
                value="inactive"
                control={<StatusRadio />}
                label={
                  <Typography variant="body2" style={{ color: themeColors.black }}>
                    Inactive
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box component={"div"}>
          <Typography variant="body2" style={{ color: themeColors.black, fontWeight: "bold" }}>
            Content <span style={{ color: "red" }}>*</span>
          </Typography>
          <Editor content={staticInfo.content} setContent={handleSaveContent} />

          <Box
            component={"div"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{ marginTop: "1.5rem" }}
          >
            <WhiteButton onClick={() => setShowModal(true)} label="Cancel" sx={{ padding: "7px 35px" }} />
            <Button
              disabled={isDisabled}
              type="submit"
              onClick={handleEdit}
              variant="contained"
              sx={{ padding: "8px 40px" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const renderView = () => {
    return (
      <>
        {isLoading && <Loading />}
        <Box component={"div"} display="flex" justifyContent="space-between">
          <Typography variant="h3" style={{ marginBottom: "0.5rem" }}>
            {provide.category ?? provide.label}
          </Typography>

          <Button
            variant="contained"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
            onClick={() => setPageState(PageStatus.EDIT)}
          >
            {" "}
            <EditIcon />
            <Typography variant="body1" sx={{ fontWeight: "bold", color: themeColors.white }}>
              Edit
            </Typography>
          </Button>
        </Box>

        <Box
          component={"div"}
          display="flex"
          justifyContent="flexStart"
          alignItems="baseline"
          gap={3}
          style={{ marginBottom: "1.5rem" }}
        >
          <Typography variant="body2" style={{ color: themeColors.black, fontWeight: "bold" }}>
            Category
          </Typography>
          <Typography variant="body2" style={{ color: themeColors.gray }}>
            {provide.category ?? provide.label}
          </Typography>
        </Box>

        <Box component={"div"} style={{ marginBottom: "1.5rem" }}>
          <Box component={"div"} display="flex" alignItems="center" gap={5}>
            <Typography variant="body2" style={{ color: themeColors.black, fontWeight: "bold" }}>
              Status
            </Typography>
            <Box
              component={"div"}
              sx={{
                padding: "8px 12px",
                background: `${staticInfo.status === "active" ? themeColors.lightGreen : "#FFE3E0"}`,
                display: "inline-block",
                borderRadius: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ color: `${staticInfo.status === "active" ? "#149973" : "#D73021"}` }}
              >
                {capitalizeFirstLetter(staticInfo.status)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box component={"div"}>
          <Box component={"div"}>{renderHTML(staticInfo.content)}</Box>
        </Box>
      </>
    );
  };

  const renderContent = () => {
    switch (pageStatus) {
      case PageStatus.CREATE:
        return renderCreate();
      case PageStatus.VIEW:
        return renderView();
      case PageStatus.EDIT:
        return renderCreate();
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSavePage}>
      <Typography variant="subtitle1" style={{ color: themeColors.lightGray, marginBottom: "1.5rem" }}>
        Static pages&ensp; <>&gt;&ensp;</>{" "}
        <Typography variant="subtitle1" component="span" style={{ color: themeColors.gray }}>
          {provide.label}
        </Typography>
      </Typography>

      {renderContent()}

      <Box component={"div"}>
        <ErrorMessage msg={error} />
      </Box>
      {showModal && (
        <CustomModal open={showModal} onClose={() => setShowModal(false)} sx={{ width: 500 }}>
          <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
            <SVG src={warning} />
            <Typography
              variant="h3"
              sx={{
                mb: 2,
                mt: 2,
              }}
            >
              Cancel editing
            </Typography>
            <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }} mb={2}>
              Are you sure you want to cancel the editing?
            </Typography>
            <Box component={"div"} display="flex" justifyContent="center">
              <Button variant="contained" onClick={() => setShowModal(false)} sx={{ width: "150px", mr: 2 }}>
                Cancel
              </Button>
              <WhiteButton
                onClick={() => {
                  setShowModal(false);
                  setPageState(PageStatus.VIEW);
                  getStaticPage();
                }}
                label="Confirm"
                width="150px"
              />
            </Box>
          </Box>
        </CustomModal>
      )}
    </form>
  );
};

export default Static;
