import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { IPaletteOptions, OverrideOptions } from "@material-ui/core/styles/createTheme";
import { Overrides } from "@material-ui/core/styles/overrides";
import colors from "./colors";

export const commonBoxShadow = "0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)";
export const commonLetterSpacing = "-0.387234px";
export const letterSpacing2 = "-0.829787px";

declare module "@material-ui/core/styles/createTheme" {
  interface IPaletteOptions extends PaletteOptions {
    danger: any;
    dark: any;
    white: any;
    light: any;
    darkGrey: any;
  }

  interface OverrideOptions extends Overrides {
    MuiPaginationItem: any;
  }
}

const palette: IPaletteOptions = {
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
  },
  success: {
    main: colors.success,
  },
  info: {
    main: colors.info,
  },
  warning: {
    main: colors.warning,
  },
  danger: {
    main: colors.danger,
  },
  dark: {
    main: colors.dark,
  },
  light: {
    main: colors.light,
  },
  white: {
    main: colors.white,
  },
  darkGrey: {
    main: colors.darkGrey,
  },
};

const overrides: OverrideOptions = {
  MuiButton: {
    root: {
      borderRadius: 8,
      boxShadow: commonBoxShadow,
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "19px",
      letterSpacing: commonLetterSpacing,
      padding: "10px 23px 11px 24px",
    },
    containedPrimary: {
      color: colors.white,
    },
    contained: {
      "&:active": {
        opacity: 0.85,
      },
    },
    outlined: {
      padding: "10px 23px 11px 24px",
    },
    sizeSmall: {
      padding: "6px 24px 7px 23px",
    },
    sizeLarge: {
      padding: "14px 41px",
    },
    outlinedSizeSmall: {
      padding: "6px 24px 7px 23px",
    },
    outlinedSizeLarge: {
      padding: "14px 41px",
    },
    text: {
      padding: "10px 12px 11px 12px",
      boxShadow: "none",
    },
  },
  MuiIconButton: {
    root: {
      // boxShadow: commonBoxShadow,
      borderRadius: 8,
      padding: 9,
    },
    sizeSmall: {
      padding: 9,
    },
  },
  MuiTablePagination: {
    root: {
      "&:last-child": {
        paddingTop: 36,
      },
    },
    selectRoot: {
      marginLeft: 0,
    },
    select: {
      borderRadius: 8,
      padding: "14px 30px 14px 12px !important",
      border: "1px solid #B4B8C5",
      marginLeft: 0,
      "&:focus": {
        // backgroundColor: 'unset',
        borderRadius: 8,
      },
    },
    spacer: {
      flex: "none",
    },
    toolbar: {
      paddingLeft: 0,
    },
  },
  MuiTableCell: {
    root: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: "19px",
      letterSpacing: commonLetterSpacing,
      fontFamily: "Roboto",
    },
    head: {
      fontSize: 14,
      fontWeight: "bold",
      lineHeight: "16px",
      letterSpacing: commonLetterSpacing,
    },
    stickyHeader: {
      backgroundColor: colors.lightGrey,
    },
  },
  MuiTableSortLabel: {
    icon: {
      color: `${colors.dark} !important`,
      fontSize: 25,
    },
    active: {
      color: colors.dark,
    },
  },
  MuiPaginationItem: {
    root: {
      color: colors.darkGrey,
      fontWeight: 600,
      fontSize: 14,
      "&$selected": {
        border: `1px solid ${colors.primary}`,
        backgroundColor: "none",
      },
      "&$disabled": {
        backgroundColor: colors.light,
        color: colors.grey,
      },
    },
    page: {
      borderRadius: 4,
    },
    disabled: {},
    selected: {},
  },
  MuiTypography: {
    // root: {
    //   fontSize: 14,
    //   fontWeight: 'normal',
    //   lineHeight: '19px',
    //   letterSpacing: commonLetterSpacing,
    //   color: colors.dark,
    // },
    body1: {
      fontSize: 14,
      fontWeight: "normal",
      lineHeight: "19px",
      letterSpacing: commonLetterSpacing,
      color: colors.dark,
    },
    body2: {
      fontWeight: 600,
      letterSpacing: "-0.829787px",
      fontSize: "30px !important",
      lineHeight: "41px",
      paddingBottom: 10,
    },
  },
  MuiStepButton: {
    root: {
      padding: "0px",
    },
  },
};

export const themeOptions: ThemeOptions = {
  typography: {
    fontFamily: "Roboto",
  },
  props: {
    MuiButton: {
      variant: "contained",
      color: "primary",
      size: "medium",
    },
  },
};
const theme = createTheme({ ...themeOptions, palette, overrides });
export default theme;
