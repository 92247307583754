import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phoneInputStyleOverride.css";

export interface IPhoneNumberInputProps {
  onChange: (phoneNumber: string) => any;
  defaultValue?: string;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  placeholderText?: string;
  onCountryChange?: any;
  id?: string;
  value?: string;
  className?: string;
  required?: boolean;
}

const useStyles = makeStyles(() => ({
  input: {
    "& input": {
      height: 50,
      borderRadius: 40,
      border: "1px solid #c4c4c4",
    },
  },
}));

const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (props) => {
  const classes = useStyles();
  const { onChange, label, disabled, onCountryChange, id, value, className, required } = props;

  return (
    <Box component={"div"} sx={{ marginBottom: 3 }}>
      <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
        <Box component={"div"} display="flex">
          {label} &nbsp;
          <div style={{ color: "red" }}>{required && "*"}</div>
        </Box>
      </Typography>
      <PhoneInput
        className={`${classes.input} ${className}`}
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        international
        onCountryChange={onCountryChange}
      />
    </Box>
  );
};

export default PhoneNumberInput;
