import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { BannerState, IBannerFields } from ".";
import CustomDateRangePicker from "../../components/date-range-picker";
import SVG from "react-inlinesvg";
import bin from "../../assets/icons/delete.svg";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import Countries from "./components/Countries";
import Banner from "../../services/banner";
import ErrorMessage from "../../components/ErrorMessage";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import styledComponent from "styled-components";
import { links } from "../../constants/links";
import { useNavigate } from "react-router-dom";

interface IDetailBannerProps {
  bannerDetailId: string;
  bannerStatus?: string;
  setBannerStatus: React.Dispatch<React.SetStateAction<BannerState>>;
  setDeleteBanner: React.Dispatch<
    React.SetStateAction<{
      id: string;
      approved: boolean;
    }>
  >;
  setBannerGlobal?: any;
}

const useStyles = makeStyles({
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
    "& > *": {
      display: "block",
    },
    "& .MuiFormControl-root": {
      width: 100 + "% !important",
    },
  },
});

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "12px",
  },
});

const StyledDateRangePicker = styledComponent(CustomDateRangePicker)`
  .calenderIcon { display: none; }
  .cancelIcon { display: none; }
`;

const DetailBanner: React.FunctionComponent<IDetailBannerProps> = ({
  bannerDetailId,
  setBannerStatus,
  bannerStatus,
  setDeleteBanner,
  setBannerGlobal,
}) => {
  const classes = useStyles();
  const [error, setError] = useState("");
  const [bannerDetail, setBannerDetail] = useState<IBannerFields | null>(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const navigate = useNavigate();

  const getBannerDetail = async (id: string) => {
    try {
      const res = await Banner.getBannerById(id);
      setBannerDetail(res.data);
    } catch (error: any) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };
  useEffect(() => {
    getBannerDetail(bannerDetailId);
  }, [bannerDetailId]);

  window.onpopstate = function () {
    if (bannerStatus !== BannerState.VIEW) {
      navigate(links.LIST_BANNERS);
    }
  };

  return (
    <Box component={"div"}>
      <Box component={"div"} width={"60%"} minWidth={"60%"}>
        <Box component={"div"} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h3">Banner detail</Typography>
          <Box component={"div"} display="flex" justifyContent="center">
            <Button
              onClick={() => setDeleteBanner({ id: bannerDetailId as string, approved: true })}
              variant="outlined"
              color="error"
              sx={{ width: "140px", cursor: "pointer" }}
              startIcon={<SVG src={bin} />}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setBannerStatus(BannerState.EDIT);
                setBannerGlobal(bannerDetail);
              }}
              sx={{ width: "140px", marginLeft: 2 }}
              startIcon={<BorderColorOutlinedIcon />}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box component={"div"} mt={2}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
            Banner name <span style={{ color: "red" }}>*</span>
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Enter banner name"
            inputProps={{ maxLength: 150 }}
            value={bannerDetail?.name}
            disabled
          />
          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Display time <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} width="100%">
              <StyledDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                value={[new Date(bannerDetail?.startDate as string), new Date(bannerDetail?.endDate as string)]}
                className={classes.dateRangePicker}
                sx={{ display: "block" }}
                disable
              />
            </Box>
          </Box>

          <Countries
            bannerState={BannerState.DETAIL}
            isAll={bannerDetail?.isAll}
            selectedCountries={bannerDetail?.country}
          />

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner type
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <CustomTextField
              fullWidth
              placeholder="Select banner type"
              inputProps={{ maxLength: 150 }}
              value={capitalizeFirstLetter(bannerDetail?.type?.toLowerCase())}
              disabled
            />
          </Box>

          {bannerDetail?.type === "paid" && (
            <Box component={"div"} mt={3}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
                Paid amount (USD) <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                type="number"
                fullWidth
                placeholder="Enter paid amount"
                inputProps={{ maxLength: 255 }}
                value={bannerDetail?.amount}
                disabled
              />
            </Box>
          )}

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              CTA button
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!bannerDetail && bannerDetail.ctaButton.includes("buyNow")}
                    name="buyNow"
                    disabled
                  />
                }
                label="Buy now"
                sx={{ mr: 5 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!bannerDetail && bannerDetail.ctaButton.includes("share")}
                    name="share"
                    disabled
                  />
                }
                label="Share"
              />
            </Box>
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Image
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} sx={{ position: "relative", width: "10rem", height: "10rem" }}>
              <img src={bannerDetail?.coverImage} style={{ width: "100%", height: "100%", borderRadius: "1rem" }} />
            </Box>
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner URL
              <span style={{ color: "red" }}>*</span>
            </Typography>
            <CustomTextField
              fullWidth
              placeholder="http://, https://...."
              inputProps={{ maxLength: 150 }}
              value={bannerDetail?.url}
              disabled
            />
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Status <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl>
              <RadioGroup row value={bannerDetail && bannerDetail.status}>
                <FormControlLabel value="active" control={<Radio />} label="Active" disabled />
                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" disabled />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <ErrorMessage msg={error} />
    </Box>
  );
};

export default DetailBanner;
