import { Box, Button, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { SelectedCategory } from ".";
import MintingNft from "../../services/minting-nft";

const StyledSelectPlaceholder = styled.div`
  color: #b3b3b3;
`;

interface IChooseCategory {
  handleNext: () => void;
  setSelectedCategory: React.Dispatch<React.SetStateAction<SelectedCategory>>;
  selectedCategory: SelectedCategory;
}

const SelectMenuProps = {
  PaperProps: {
    sx: {
      borderRadius: 0,
      "& .MuiList-root": { padding: 0 },
      "& .MuiMenuItem-root:hover": { background: "#D1F2E2 !important" },
      "& .MuiMenuItem-root:first-of-type": { borderTop: "none" },
      "& .MuiMenuItem-root": {
        padding: "10px 15px",
        borderTop: "1px solid #ccc",
        borderRadius: 0,
        fontSize: "16px !important",
        color: "#242424 !important",
      },
      "& .Mui-selected": {
        color: "#0F6A4B !important",
        fontWeight: "bold",
        background: "transparent !important",
      },
    },
  },
};

const ChooseCategory: React.FunctionComponent<IChooseCategory> = ({
  handleNext,
  setSelectedCategory,
  selectedCategory,
}) => {
  const [categories, setCategories] = useState({
    data: [] as any,
    loading: false,
    error: "",
  });
  const [category, setCategory] = useState({
    data: [] as any,
    loading: false,
    error: "",
  });

  const getCategories = async () => {
    try {
      setCategories((prev) => ({ ...prev, loading: true }));
      const res = await MintingNft.getCategories();
      setCategories((prev) => ({ ...prev, loading: false, data: res.data }));
    } catch (error: any) {
      setCategories((prev) => ({ ...prev, error: error?.response?.data?.message || "Something went wrong" }));
    }
  };

  const getCategory = async (categoryId: string) => {
    try {
      setCategory((prev) => ({ ...prev, loading: true }));
      const res = await MintingNft.getCategory(categoryId);
      setCategory((prev) => ({ ...prev, loading: false, data: res.data }));
    } catch (error: any) {
      setCategory((prev) => ({ ...prev, error: error.response.data.message || "Something went wrong" }));
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory.mainCategory?.id) {
      getCategory(selectedCategory.mainCategory?.id);
    }
  }, [selectedCategory.mainCategory]);

  const handleChangeMainCategory = (value: any) => {
    setSelectedCategory({
      mainCategory: value,
      subCategory: "",
    });
  };

  const handleChangeSubCategory = (value: string) => {
    setSelectedCategory({ ...selectedCategory, subCategory: value });
  };

  return (
    <Box component={"div"}>
      <Box component={"div"} sx={{ maxWidth: 560 }}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Category
        </Typography>
        <Typography sx={{ mb: 3 }}>Which category of NFT do you want to mint?</Typography>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 0.5 }}>Category</Typography>
          <Select
            displayEmpty
            value={selectedCategory.mainCategory}
            onChange={(e) => handleChangeMainCategory(e.target.value)}
            renderValue={
              !selectedCategory.mainCategory
                ? () => <StyledSelectPlaceholder>Choose a category</StyledSelectPlaceholder>
                : undefined
            }
            sx={{ borderRadius: "40px" }}
            MenuProps={SelectMenuProps}
          >
            {Array.isArray(categories.data) &&
              categories.data.length > 0 &&
              categories.data.map((item: any) => (
                <MenuItem key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: 16, fontWeight: "bold", mb: 0.5 }}>Sub-Category</Typography>
          <Select
            displayEmpty
            value={selectedCategory.subCategory}
            onChange={(e) => handleChangeSubCategory(e.target.value)}
            renderValue={
              !selectedCategory.subCategory
                ? () => <StyledSelectPlaceholder>Choose a sub-category</StyledSelectPlaceholder>
                : undefined
            }
            sx={{ borderRadius: "40px" }}
            MenuProps={SelectMenuProps}
          >
            {Array.isArray(category.data) &&
              category.data.length > 0 &&
              category.data.map((item: any) => (
                <MenuItem key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box component={"div"} display="flex" justifyContent="center">
          <Button
            variant="contained"
            sx={{ width: 160 }}
            disabled={!selectedCategory.mainCategory || !selectedCategory.subCategory}
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChooseCategory;
