import { Box, Button, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import colors from "../../../theme/colors";
import { themeColors } from "../../../theme/schemes/PureLightTheme";
import SmallWallet from "../../../assets/icons/small-wallet.svg";
import CopyImg from "../../../assets/images/copy.png";
import SVG from "react-inlinesvg";
import Wallet from "../../../services/wallet";
import link from "../../../assets/icons/link 1.svg";
import qrCode from "../../../assets/icons/qr_code_gray.svg";
import CustomModal from "../../../components/modal";
import warningLogo from "../../../assets/icons/WarningCircle.svg";
import WhiteButton from "../../../components/WhiteButton";
import { Loading } from "../../../components";
import { useNavigate } from "react-router-dom";
import { links } from "../../../constants/links";
import { useWalletContext } from "../../../contexts/wallet-provider";
import DVOLDLogo from "../../../assets/logos/DVOLD_def 1.png";
import DVOLD from "../../../assets/logos/Frame.png";
import QRCode from "react-qr-code";
import { copyAddressToClipboard } from "../../../utils/utils";
import { ToastMessage } from "../../../const";
import Styled from "styled-components";

interface IListWalletProps {
  walletList: any[];
  showModal: boolean;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getWalletList: () => Promise<void>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const WhiteSpace = styled(Box)({
  width: 150,
  background: "#ffffff",
  color: themeColors.primary,
  borderRadius: "4.2rem",
  border: `1px solid ${colors.lightGrey}`,
  padding: "0.5rem 1rem",
  "&:hover": {
    background: "transparent",
  },
  marginRight: 16,
});

const StyledIcon = Styled(SVG)`
  cursor: pointer;
  path {
    stroke: transparent;
  }
`;

const ListWallet: React.FunctionComponent<IListWalletProps> = ({
  isLoading,
  showModal,
  walletList,
  setIsLoading,
  getWalletList,
  setShowModal,
}) => {
  const { setSelectedWallet } = useWalletContext();
  const [showQR, setShowQR] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>("");
  const navigate = useNavigate();

  const deleteWallet = async () => {
    setIsLoading(true);
    const res = await Wallet.deleteWallet({ id: selectedItem?.id });
    if (res.statusCode === 200) {
      getWalletList();
    }
  };

  const renderModalContent = () => {
    return (
      <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
        <SVG src={warningLogo} />
        <Typography
          variant="h3"
          sx={{
            mb: 2,
            mt: 2,
          }}
        >
          Confirm disconnect wallet
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Are you sure you want to remove this wallet from the DVOLD app?
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          You will lose access to your wallet on the DVOLD application.
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }} mb={2}>
          The only way to restore your wallet is using the complete Recovery phrase.
        </Typography>
        <Box component={"div"} display="flex" justifyContent="center">
          <Button variant="contained" onClick={() => setShowModal(false)} sx={{ width: "150px", mr: 2 }}>
            Close
          </Button>
          <WhiteButton onClick={deleteWallet} label="Confirm" width="150px" />
        </Box>
      </Box>
    );
  };

  const handleSaveSelectedWallet = (item: any) => {
    setSelectedWallet(item as any);
    navigate(links.MY_NFTS, { state: { walletDetails: item } });
  };
  const renderQRContent = () => {
    return (
      <Box component={"div"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Wallet&ensp; <>&gt;&ensp;</>My FTs
        </Typography>
        <Box component={"div"} display={"flex"} justifyContent="center" alignItems={"center"} mt={2}>
          <img src={DVOLDLogo} alt="" style={{ marginRight: 10 }} />
          <img src={DVOLD} alt="" />
        </Box>
        <Box
          component={"div"}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
          flexDirection="column"
          mt={2}
        >
          <QRCode value={selectedItem.address} size={400} />
          <Typography variant="subtitle1" sx={{ width: 400, overflowWrap: "break-word", mt: 3 }}>
            {selectedItem.address}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box component={"div"} sx={{ marginTop: "5rem", p: 3 }}>
      {isLoading && <Loading />}
      <Typography variant="subtitle1" sx={{ color: themeColors.black, fontWeight: "bold" }}>
        DVOLD Wallet ({walletList.length})
      </Typography>
      <Box component={"div"} display="flex" flexWrap={"wrap"} gap={2}>
        {walletList.map((item: any, index: number) => {
          const shortAddress = `${item.address.slice(0, 6)}...${item.address.slice(-6)}`;
          return (
            <Box component={"div"} key={index} sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              <Box component={"div"}>
                <WhiteSpace sx={{ width: "35vw", display: "flex", justifyContent: "space-between" }}>
                  <Box
                    component={"div"}
                    sx={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}
                    onClick={() => handleSaveSelectedWallet(item)}
                  >
                    <Box component={"div"}>
                      <StyledIcon src={SmallWallet} />
                    </Box>

                    <Typography variant="subtitle1" sx={{ color: `${colors.lightGrey2} !important` }}>
                      {item.name}
                    </Typography>
                  </Box>
                  <Box component={"div"} sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <Typography variant="subtitle1" sx={{ color: themeColors.lightBlue, fontSize: 16 }}>
                      {shortAddress}
                    </Typography>
                    <StyledIcon
                      src={link}
                      onClick={() => {
                        setShowModal(true);
                        setSelectedItem(item);
                      }}
                    />
                    <StyledIcon
                      src={qrCode}
                      onClick={() => {
                        setShowQR(true);
                        setSelectedItem(item);
                      }}
                    />
                    <img
                      src={CopyImg}
                      onClick={() => copyAddressToClipboard(item.address, ToastMessage.WalletCoppied)}
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </WhiteSpace>
              </Box>
            </Box>
          );
        })}
      </Box>
      <CustomModal open={showModal} onClose={() => setShowModal(false)}>
        {renderModalContent()}
      </CustomModal>
      <CustomModal open={showQR} onClose={() => setShowQR(false)} sx={{ width: "60%", height: "90%" }}>
        {renderQRContent()}
      </CustomModal>
    </Box>
  );
};

export default ListWallet;
