import { useMediaQuery } from "react-responsive";

export const screen = {
  width: {
    desktop: 1440,
    smallDesktop: 1024,
    tablet: 768,
    mobile: 480,
    smallMobile: 400
  },
};

const useResponsive = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${screen.width.desktop}px)`,
  });
  const isSmallDesktop = useMediaQuery({
    query: `(max-width: ${screen.width.smallDesktop}px)`,
  });
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${screen.width.desktop}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${screen.width.desktop}px) and (min-width: ${screen.width.tablet}px)`,
  });
  const isMobile = useMediaQuery({
    query: `(max-width: ${screen.width.mobile}px)`,
  });
  const isSmallMobile = useMediaQuery({
    query: `(max-width: ${screen.width.smallMobile}px)`,
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return {
    isDesktopOrLaptop,
    isSmallDesktop,
    isMobile,
    isTabletOrMobile,
    isTablet,
    isPortrait,
    isRetina,
    isSmallMobile
  };
};

export default useResponsive;
