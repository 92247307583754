import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Card,
  Link,
  Typography,
  Container,
  Alert,
  Dialog,
  Collapse,
  Button,
  Avatar,
  IconButton,
  styled,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import CustomDialog from "../../../components/Dialog";

const MainContent = styled(Box)(
  () => `
`,
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`,
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`,
);

function ResetPassword() {
  const { t } = useTranslation();

  const [openAlert, setOpenAlert] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <CustomDialog disableClose />
      <MainContent>
        <Container maxWidth="sm">
          <Card
            sx={{
              mt: 3,
              p: 4,
            }}
          >
            <Box component={"div"}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                }}
              >
                {t("Reset Password")}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3,
                }}
              >
                {t("Enter the new password.")}
              </Typography>
            </Box>
          </Card>
          <Box component={"div"} mt={3} textAlign="right">
            <Typography component="span" variant="subtitle2" color="text.primary" fontWeight="bold">
              {t("Want to try to sign in again?")}
            </Typography>{" "}
            <Link component={RouterLink} to="/login">
              <b>Click here</b>
            </Link>
          </Box>
        </Container>
      </MainContent>

      <DialogWrapper open={openDialog} maxWidth="sm" fullWidth keepMounted onClose={handleCloseDialog}>
        <Box
          component={"div"}
          sx={{
            px: 4,
            pb: 4,
            pt: 10,
          }}
        >
          <AvatarSuccess>
            <CheckTwoToneIcon />
          </AvatarSuccess>

          <Collapse in={openAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="info"
            >
              {t("The password reset instructions have been sent to your email")}
            </Alert>
          </Collapse>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 10,
            }}
            variant="h3"
          >
            {t("Check your email for further instructions")}
          </Typography>

          <Button fullWidth size="large" variant="contained" onClick={handleCloseDialog} href="/login">
            {t("Continue to login")}
          </Button>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default ResetPassword;
