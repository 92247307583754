import React from "react";
import { Box, SxProps, Theme, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IsLoading {
  sx?: SxProps<Theme>;
  isDefaultPosition?: boolean;
}

const useStyles = makeStyles({
  backgroundContent: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  defaultPosition: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9999,
  },
});

export const Loading: React.FunctionComponent<IsLoading> = ({ sx, isDefaultPosition = true }) => {
  const classes = useStyles();

  return (
    <Box
      component={"div"}
      className={isDefaultPosition ? classes.defaultPosition : ""}
      sx={{
        backgroundColor: "rgba(255,255,255,0.95)",
        ...sx,
      }}
    >
      <div className={classes.backgroundContent}>
        <CircularProgress color="secondary" />
      </div>
    </Box>
  );
};
