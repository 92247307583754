import { styled, Typography } from "@mui/material";

import { themeColors } from "../../theme/schemes/PureLightTheme";

const ErrMessage = styled(Typography)({
  color: themeColors.error,
  fontStyle: "italic",
});

function ErrorMessage({ msg }: { msg: string }) {
  return <ErrMessage>{msg}</ErrMessage>;
}

export default ErrorMessage;
