import * as React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ErrorMessage from "../../components/ErrorMessage";
import Users from "../../services/users";
import ImageResize from "quill-image-resize-module-react";
Quill.register("modules/imageResize", ImageResize);

interface IEditorProps {
  content: any;
  setContent: React.Dispatch<React.SetStateAction<any>>;
}

const Editor: React.FunctionComponent<IEditorProps> = ({ content, setContent }) => {
  const quillObj = React.useRef<any>(null);
  const [, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState("");

  const handleImage = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file: any = input.files && input.files[0];

      await handleUploadFile(file, quillObj.current);
    };
  };

  const handleOnChange = (content: any, delta: any, source: any, editor: any) => {
    const text = editor.getText(content);
    setContent({ content: content, text: text });
  };
  const handleUploadFile = async (uploadFileObj: any, quillObj: any) => {
    try {
      const range = quillObj?.getEditorSelection();
      setIsLoading(true);
      setIsError("");
      const res = await Users.uploadFiles({ file: uploadFileObj });
      quillObj?.getEditor().insertEmbed(range.index, "image", res.data[0].link);
      setIsLoading(false);
      setIsError("");
    } catch (error: any) {
      setIsLoading(false);
      setIsError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const module = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [] }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link", "image", "video"],
          ["clean"],
          [{ color: [] }, { background: [] }],
          [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
          // [],
        ],
        handlers: {
          image: handleImage,
        },
      },

      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <ReactQuill
        ref={(el) => (quillObj.current = el)}
        theme="snow"
        value={content}
        onChange={handleOnChange}
        style={{
          backgroundColor: "#fff",
          color: "#000",
        }}
        placeholder="Write something..."
        modules={module}
      />
      <ErrorMessage msg={isError} />
    </>
  );
};

export default Editor;
