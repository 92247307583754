import { Box, Card, styled } from "@mui/material";
import backgroundImage from "../../assets/images/Rectangle 2206.png";
import headerImage from "../../assets/images/Asset 1 1.png";
import DVOLDLogo from "../../assets/logos/DVOLD_def 1.png";
import DVOLD from "../../assets/logos/Frame 625843.png";

const MainContent = styled(Box)(
  () => `
    background-image: url("${backgroundImage}");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  `,
);

const Header = styled(Box)(
  () => `
    background-image: url("${headerImage}");
    width: 100%;
    height: 10vh;
    position: absolute
  `,
);

const HeaderLogoContainer = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
);

const AuthenticateLayout = ({ children }: { children: any }) => {
  return (
    <>
      <MainContent position={"relative"}>
        <Header>
          <HeaderLogoContainer>
            <img src={DVOLDLogo} alt="" style={{ marginRight: 10 }} />
            <img src={DVOLD} alt="" />
          </HeaderLogoContainer>
        </Header>
        <Box component={"div"} display={"flex"} justifyContent="center" alignItems={"center"} sx={{ height: "100vh" }}>
          <Card
            sx={{
              px: 4,
              pt: 5,
              pb: 3,
            }}
          >
            {children}
          </Card>
        </Box>
      </MainContent>
    </>
  );
};

export default AuthenticateLayout;
