import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { themeColors } from "../../theme/schemes/PureLightTheme";

interface IWhiteButton {
  onClick?: any;
  label: string | React.ReactElement;
  startIcon?: any;
  endIcon?: any;
  width?: any;
  disabled?: boolean;
  sx?: any;
}

const useStyles = makeStyles({
  btn: {
    color: `${themeColors.primary} !important`,
    "&:hover": {
      background: `${themeColors.white} !important`,
    },
    marginRight: 16,
  },
});

const WhiteButton = (props: IWhiteButton) => {
  const classes = useStyles();
  const { onClick, label, startIcon, endIcon, width, disabled, sx } = props;
  return (
    <>
      <Button
        variant="contained"
        startIcon={startIcon ? startIcon : undefined}
        endIcon={endIcon ? endIcon : undefined}
        // disabled={user.password === "" || user.userName === ""}
        onClick={() => onClick()}
        sx={{
          width: width ? width : "auto",
          cursor: "pointer",
          background: themeColors.white,
          ...sx,
          border: `1px solid ${themeColors.primary} !important`,
        }}
        disabled={disabled}
        className={classes.btn}
      >
        {label}
      </Button>
    </>
  );
};

export default WhiteButton;
