import { Box, Button, TextField, Typography } from "@mui/material";
import { useLayoutEffect, useState } from "react";
import styled from "styled-components";

const StyledTextarea = styled(TextField)`
  margin-bottom: 1rem;
  .MuiInputBase-root {
    border-radius: 16px;
  }
`;

interface IProps {
  setWidthModal?: React.Dispatch<React.SetStateAction<number>>;
  handleFillSecretPhrase: () => void;
  setSecretPhraseInput: React.Dispatch<React.SetStateAction<string>>;
  secretPhraseInput: string;
  errMsg: string;
}

const RecoveryPhraseSecret = ({
  setWidthModal,
  handleFillSecretPhrase,
  setSecretPhraseInput,
  secretPhraseInput,
  errMsg,
}: IProps) => {
  const [countWords, setCountWords] = useState(0);

  useLayoutEffect(() => {
    setWidthModal && setWidthModal(700);
  }, [setWidthModal]);

  const handleChangeSecretPhrase = (value: string) => {
    setSecretPhraseInput(value);
    const wordsLength = value
      .trimEnd()
      .split(" ")
      .filter((word) => word).length;
    setCountWords(wordsLength);
  };

  return (
    <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
      <Typography variant="h3" sx={{ mb: 1 }}>
        Recorvery Phrase
      </Typography>
      <Typography sx={{ mb: 3 }}>Type in the secret Recovery Phrase to restore your wallet on DVOLD:</Typography>
      <StyledTextarea
        fullWidth
        multiline
        rows={5}
        placeholder="Enter typically 12-24 words separated by single spaces"
        value={secretPhraseInput}
        onChange={(e) => handleChangeSecretPhrase(e.target.value)}
      />
      <Button variant="contained" disabled={countWords < 12 || !!errMsg} onClick={handleFillSecretPhrase}>
        Continue
      </Button>
    </Box>
  );
};

export default RecoveryPhraseSecret;
