/* eslint-disable react/no-unescaped-entities */
import { Box, Tab, Tabs, Typography } from "@mui/material";

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import MyFTs from "./my-fts";
import MyMintedNFTs from "./my-minted-nfts";
import MyMintingNFTs from "./my-minting-nfts";
import MyNonMintedNFTs from "./my-non-minted-nfts";
import Offers from "./offers";
import WalletService from "../../services/wallet";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component={"div"} sx={{ p: "27px 0 27px 0" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabLabel = [
  { label: "My FTs" },
  { label: "My minted NFTs" },
  { label: "My minting NFTs" },
  { label: "Draft NFTs" },
  { label: "Offers" },
];

enum LabelEnum {
  MyFTs = "My FTs",
  MyMintedNFTs = "My minted NFTs",
  MyMintingNFTs = "My minting NFTs",
  Draft = "Draft NFTs",
  Offers = "Offers",
}

const WalletTabs = () => {
  const [value, setValue] = useState(0);
  const [walletList, setWalletList] = useState([]);
  const location = useLocation();
  const { state } = location;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (state?.nftTab) {
      setValue(state?.nftTab);
    }
  }, [state]);

  const getWalletList = async () => {
    const res = await WalletService.getListWallet();
    if (res?.statusCode === 200) {
      setWalletList(res.data.wallets);
    }
  };

  useEffect(() => {
    getWalletList();
  }, []);
  return (
    <Box component={"div"} sx={{ width: "100%" }}>
      <Box component={"div"} display={"flex"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Wallet&ensp; <>&gt;&ensp;</> Wallet details&ensp; <>&gt;&ensp;</>
        </Typography>
        <Typography variant="subtitle1" style={{ color: themeColors.gray }}>
          {TabLabel[value].label}
        </Typography>
      </Box>
      <Box component={"div"} sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {TabLabel.map((x: any, index: number) => {
            return <Tab key={index} label={x.label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MyFTs title={TabLabel[0].label} walletDetails={location?.state?.walletDetails} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MyMintedNFTs
          title={TabLabel[1].label}
          walletDetails={location?.state?.walletDetails}
          minted={TabLabel[value].label === LabelEnum.MyMintedNFTs}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MyMintingNFTs title={TabLabel[2].label} walletDetails={location?.state?.walletDetails} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <MyNonMintedNFTs
          title={TabLabel[3].label}
          walletDetails={location?.state?.walletDetails}
          walletList={walletList}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Offers title={TabLabel[4].label} walletDetails={location?.state?.walletDetails} />
      </TabPanel>
    </Box>
  );
};
export default WalletTabs;
