import { Box, Typography } from "@mui/material";
import * as React from "react";
import WhiteButton from "../../../components/WhiteButton";

interface IUploadFileProps {
  fileUpload: {
    file: File | null;
    imagePreviewUrl: string;
  };
  setFileUpload: (
    value: React.SetStateAction<{
      file: null;
      imagePreviewUrl: string;
    }>,
  ) => void;
}

const UploadFile: React.FunctionComponent<IUploadFileProps> = (props) => {
  const { fileUpload, setFileUpload } = props;

  const handleChangeImage = (e: any) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFileUpload({
        file: file,
        imagePreviewUrl: reader.result as string,
      });
    };

    reader.readAsDataURL(file);
  };

  return (
    <Box component={"div"}>
      {fileUpload.file || fileUpload.imagePreviewUrl ? (
        <Box component={"div"} sx={{ position: "relative", width: "10rem", height: "10rem" }}>
          <img src={fileUpload.imagePreviewUrl} style={{ width: "100%", height: "100%", borderRadius: "1rem" }} />
          <input
            type="file"
            style={{
              width: "100%",
              height: "100%",
              opacity: "0",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10,
              cursor: "pointer",
            }}
            onChange={handleChangeImage}
            accept="image/*"
          />
        </Box>
      ) : (
        <>
          <Typography variant="subtitle1">Maximum 10 MB</Typography>
          <Box component={"div"} sx={{ position: "relative" }}>
            <WhiteButton sx={{ cursor: "pointer", mt: 0.5 }} label={<Box component={"div"}>Upload</Box>} />

            <input
              type="file"
              style={{
                width: "100%",
                height: "100%",
                opacity: "0",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10,
                cursor: "pointer",
              }}
              onChange={handleChangeImage}
              accept="image/*"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UploadFile;
