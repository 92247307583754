/* eslint-disable react/no-unescaped-entities */
import { Box, Grid, Typography } from "@mui/material";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import priceTag from "../../assets/icons/priceTag.svg";
import Items from "../../services/items";
import { useEffect, useState } from "react";
import CheckCircleIcon from "../../assets/icons/nft-detail/check-circle.svg";
import ClipIcon from "../../assets/icons/nft-detail/clip.svg";
import FileIcon from "../../assets/icons/nft-detail/file.svg";
import UploadIcon from "../../assets/icons/nft-detail/upload.svg";
import { links } from "../../constants/links";
import { NFT3Drender } from "../../utils/nft3dRender";

const StyledDetailImage = styled.div`
  position: relative;
  margin-right: 2.5rem;
  .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #247cf3;
    font-size: 14px;
    background: #e1eeff;
    padding: 4px 12px;
    border-radius: 100px;
  }
  .preview-image {
    height: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 55px 8px 0;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .list-actions {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
  }
  .action-item {
    padding: 0;
  }
`;

const StyledDetailEventTitle = styled.h4`
  font-size: 20px;
  margin: 25px 0 5px;
`;

const StyledDetailEventItem = styled.div`
  border-bottom: 1px solid ${themeColors.lighterGray};
  padding: 15px 0;
  font-weight: bold;
  font-size: 16px;
  color: ${themeColors.black};
  a {
    font-weight: bold;
    font-size: 16px;
    color: ${themeColors.black};
  }
`;

const OfferDetail = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [nftDetails, setNFTDetails] = useState<any>();
  const [docs, setDocs] = useState([]);
  const [events, setEvents] = useState([]);
  const [attribute, setAtribute] = useState([]);

  const getNFTDetails = async () => {
    const res = await Items.getNFTById({ id: params.id });
    if (res.statusCode === 200) {
      setNFTDetails(res.data.item);
      setAtribute(res.data.info);
      setDocs(res.data.docs);
      setEvents(res.data.events);
    }
  };

  useEffect(() => {
    getNFTDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.onpopstate = function () {
    navigate(links.MY_NFTS, { state: { nftTab: 4 } });
  };
  history.pushState({}, "");
  return (
    <Box component={"div"}>
      <Box component={"div"} display={"flex"}>
        <Typography variant="subtitle1" style={{ color: themeColors.lightGray }}>
          Wallet&ensp; <>&gt;&ensp;</> Wallet details&ensp; <>&gt;&ensp;</>Offers&ensp; <>&gt;&ensp;</>{" "}
          {location?.state?.type === "sent" ? "Sent offers" : "Receieved offers"}
          &ensp; <>&gt;&ensp;</>
        </Typography>
        <Typography variant="subtitle1" style={{ color: themeColors.gray }}>
          {nftDetails?.name}
        </Typography>
      </Box>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="h3">{nftDetails?.name}</Typography>
      </Box>
      <Typography sx={{ mb: 2 }}>{nftDetails?.description}</Typography>
      <Box component={"div"} sx={{ maxWidth: "80%" }}>
        <Grid container mb={4}>
          <Grid item xs={5}>
            <StyledDetailImage>
              <Box component={"div"} className="preview-image">
                {nftDetails?.image3d ? (
                  nftDetails?.image3d.includes(".mp4") ? (
                    <Box component={"div"}>
                      <video controls>
                        <source src={nftDetails?.image3d} type="video/mp4" />
                      </video>
                    </Box>
                  ) : (
                    NFT3Drender({
                      link: nftDetails?.image3d,
                    })
                  )
                ) : (
                  <img src={nftDetails?.coverImage} alt={nftDetails?.name} />
                )}
                <Box
                  component={"div"}
                  p={1}
                  position={"absolute"}
                  bottom={0}
                  left={0}
                  display="flex"
                  sx={{
                    border: `1px solid ${themeColors.lighterGray1}`,
                    borderRadius: 16,
                    mt: 1,
                    background: themeColors.white,
                  }}
                >
                  <SVG src={priceTag} />
                  <Typography variant="h4">&nbsp; {nftDetails?.price ?? 0} ADA </Typography>
                </Box>
              </Box>
            </StyledDetailImage>
          </Grid>
          <Grid item xs={7} mt={3} sx={{ fontSize: 16 }}>
            <Grid container mb={1}>
              <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                Owner
              </Grid>
              <Grid item sx={{ pl: 1 }}>
                {`${nftDetails?.user?.firstName ?? ""} ${nftDetails?.user?.lastName ?? ""}`}
                <SVG src={CheckCircleIcon} style={{ margin: "0px 5px -2px 5px" }} />(
                <span style={{ color: themeColors.lightBlue }}>
                  {`${nftDetails?.walletAddress.slice(0, 6)}...${nftDetails?.walletAddress.slice(-6)}`}
                </span>
                )
              </Grid>
            </Grid>
            {attribute.map((x: any, index: number) => {
              return (
                <Grid container mb={1} key={index}>
                  <Grid item xs={2} sx={{ fontWeight: "bold" }}>
                    {x.name}
                  </Grid>
                  <Grid item sx={{ pl: 1 }}>
                    {x.value}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <StyledDetailEventTitle>Docs</StyledDetailEventTitle>
        {docs.length > 0 &&
          docs.map((doc: any, index: number) => {
            return (
              <StyledDetailEventItem key={index}>
                <Box component={"div"} display="flex" justifyContent="space-between" alignItems="center">
                  <Box component={"div"} display="flex" columnGap="5px">
                    <SVG src={FileIcon} />
                    <a href={doc.value} target="_blank" rel="noreferrer">
                      {doc.name}
                    </a>
                  </Box>
                  <SVG src={UploadIcon} />
                </Box>
              </StyledDetailEventItem>
            );
          })}
        <StyledDetailEventTitle>Event</StyledDetailEventTitle>
        {events.length > 0 &&
          events.map((event: any, index: number) => {
            return (
              <StyledDetailEventItem key={index}>
                <Box component={"div"} display="flex" justifyContent="space-between" alignItems="center">
                  <Box component={"div"} display="flex" columnGap="5px">
                    <SVG src={FileIcon} />
                    <a href={event.value} target="_blank" rel="noreferrer">
                      {event.name}
                    </a>
                  </Box>
                  <SVG src={ClipIcon} />
                </Box>
              </StyledDetailEventItem>
            );
          })}
      </Box>
    </Box>
  );
};
export default OfferDetail;
