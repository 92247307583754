import React from "react";
import { LocalizationProvider } from "@material-ui/pickers";
import MomentUtils from "@material-ui/pickers/adapter/moment";
import ReactDOM from "react-dom";
import App from "./App";
import { AppProvider } from "./contexts/app-provider";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import theme from "./theme";
import ThemeProvider from "./theme/ThemeProvider";
import { UserProvider } from "./contexts/user-provider";
import { DialogProvider } from "./contexts/dialog-provider";
import { WalletProvider } from "./contexts/wallet-provider";
import { StylesProvider } from "@material-ui/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <DialogProvider>
                <UserProvider>
                  <WalletProvider>
                    <App />
                  </WalletProvider>
                </UserProvider>
              </DialogProvider>
            </LocalizationProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
