import axiosClient from "../../api/axiosClient";

export enum PageTypeEnum {
  TERMS = "terms-conditions",
  ABOUT_US = "about-us",
  FAQ = "faq",
}

interface IUpdatePage {
  content: string;
  status: "active" | "inactive";
}

const Static = {
  getPage: (page: PageTypeEnum) => {
    const url = `/pages?type=${page}`;
    return axiosClient.get(url);
  },
  uploadPage: (type: PageTypeEnum, info: IUpdatePage) => {
    const url = `pages/${type}`;
    return axiosClient.put(url, info);
  },
};

export default Static;
