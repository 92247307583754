import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SVG from "react-inlinesvg";
import copy from "../../assets/icons/copy.svg";
import coverImage from "../../assets/images/Frame 626001.png";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import cardanoIcon from "../../assets/images/Group 19.png";
import moment from "moment";
import { copyAddressToClipboard } from "../../utils/utils";
import { ToastMessage } from "../../const";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

import MintingNft from "../../services/minting-nft";
import { useWalletContext } from "../../contexts/wallet-provider";
import { links } from "../../constants/links";
import Wallet from "../../services/wallet";
interface ILeftNTFDetails {
  title: string;
  price?: string;
  hasMintBtn?: boolean;
  walletDetails: any;
  isNT?: boolean;
}
const useStyles = makeStyles({
  qrCode: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  walletAddress: {
    color: themeColors.lightBlue,
    marginRight: 10,
    fontSize: 16,
  },
  copyIcon: {
    height: 20,
    width: 20,
    cursor: "pointer",
  },
  mintNFT: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
    fontSize: "16px !important",
    fontWeight: 400,
  },
  subTitle: {
    fontSize: "20px !important",
  },
  img: {
    marginRight: 10,
    height: 40,
  },
});

export const LeftNFTDetails = ({ title, price, hasMintBtn = false, walletDetails, isNT = false }: ILeftNTFDetails) => {
  const classes = useStyles();
  const [ada, setAda] = useState(0);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const { selectedWallet } = useWalletContext();

  const renderCardanoPercentage = (value: number) => {
    if (value < 0) {
      return (
        <Typography variant="subtitle1" sx={{ color: themeColors.error }}>
          &ensp;{value}%
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle1" sx={{ color: "#3abb3a" }}>
        &ensp;{value}%
      </Typography>
    );
  };
  const getExchangeAda = async () => {
    const res = await MintingNft.getPriceADA();
    if (_.get(res, "statusCode") === 200) {
      setAda(res.data.price);
    }
  };

  useEffect(() => {
    getExchangeAda();
    getADABalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getADABalance = async () => {
    const res = await Wallet.getWalletBalance({ id: selectedWallet.id });
    if (res.statusCode === 200) {
      setWalletBalance(res?.data);
    }
  };

  return (
    <>
      <Typography variant="h3" mb={2}>
        {title}
      </Typography>
      <Box
        component={"div"}
        display="flex"
        justifyContent={"center"}
        position="relative"
        alignItems={"center"}
        flexDirection="column"
      >
        <img src={coverImage} alt="" />
        <Typography variant="h4" className={classes.subTitle} mt={2}>
          {walletDetails?.name ?? selectedWallet.name}
        </Typography>
        {isNT && (
          <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
            Joined {moment(walletDetails?.createdAt).format("MMMM YYYY")}
          </Typography>
        )}
        <Box component={"div"} display={"flex"} alignItems="center" mt={1}>
          <span className={classes.walletAddress}>
            {(walletDetails?.address ?? selectedWallet.address).slice(0, 6)}...
            {(walletDetails?.address ?? selectedWallet.address).slice(-6)}
          </span>
          <SVG
            className={classes.copyIcon}
            src={copy}
            onClick={() =>
              copyAddressToClipboard(walletDetails?.address ?? selectedWallet.address, ToastMessage.WalletCoppied)
            }
          />
        </Box>
        {price && (
          <Box component={"div"} display="flex" justifyContent={"space-between"} mt={2} width="100%">
            <Box component={"div"} display="flex">
              <img className={classes.img} src={cardanoIcon} alt="" />
              <Box component={"div"} display={"flex"} flexDirection="column">
                <Typography variant="h4">Cardano </Typography>
                <Box component={"div"} display="flex">
                  <Typography variant="subtitle1">$0.49 </Typography>
                  {renderCardanoPercentage(-0.82)}
                </Box>
              </Box>
            </Box>
            <Box component={"div"} display={"flex"} flexDirection="column">
              <Typography variant="h4">
                {walletBalance[0]?.quantity.toFixed(2)} {walletBalance[0]?.unit}{" "}
              </Typography>
              <Typography variant="subtitle1">&ensp;≈ ${(ada * walletBalance[0]?.quantity).toFixed(2)}</Typography>
            </Box>
          </Box>
        )}

        {hasMintBtn && (
          <Link to={links.MINTING_NFT}>
            <Button variant="contained" fullWidth className={classes.mintNFT}>
              Mint NFT
            </Button>
          </Link>
        )}
      </Box>
    </>
  );
};
