const colors = {
  white: "#fff",
  black: "#000",
  primary: "#4AA96C",
  secondary: "#979AA6",
  success: "#82d616",
  info: "#17c1e8",
  warning: "#f53939",
  danger: "#ea0606",
  dark: "#06080E",
  light: "#F0F1F2",
  grey: "#B4B8C5",
  lightGrey: "#C7C7C7",
  lighterGray1: "#E0E0E0",
  lighterGrayLowOpacity: "rgba(224, 224, 224, 0.6)",
  darkGrey: "#5C575C",
  primaryLight: "rgba(74, 169, 108, 0.5)",
  lightGrey2: "#4B4B4B",
  secondaryLight: "#0F6A4B",
  darkGreen: "#548574",
};

export default colors;
