import * as React from "react";
import Box from "@mui/material/Box";
import MuiStepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import colors from "../../theme/colors";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import done from "../../assets/icons/done.svg";
import SVG from "react-inlinesvg";

const useStyles = makeStyles(() =>
  createStyles({
    stepper: {
      "& .MuiStepper-root": {
        backgroundColor: "transparent",
        display: "flex",
        paddingBottom: 0,
        marginBottom: 0,
      },
      "& .MuiStep-root": {
        padding: 0,
      },
      "& .MuiStepButton-root": {
        margin: 0,
        marginRight: "1.5rem",
        borderRadius: "0",
        borderBottom: `1px solid ${colors.lighterGray1}`,
        transition: "all 0.3s ease",
        paddingLeft: "0",
        paddingBottom: "1rem",

        "& :first-of-type": {
          marginLeft: 0,
        },
      },
      "& .MuiStepLabel-label": {
        clear: "both",
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingLeft: "0",
      },
      "& .MuiStepLabel-iconContainer": {
        display: "none",
      },
      "& .MuiStepConnector-root": {
        display: "none",
      },
    },
    toggleBorder: {
      "& .MuiStepButton-root": {
        borderBottom: `1px solid ${colors.secondaryLight}`,
        transition: "all 0.4s ease",
      },
    },
  }),
);

interface IStepperProps {
  steps: {
    label: string;
    Component: JSX.Element;
  }[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  allStepsCompleted: () => boolean;
  completedSteps: () => number;
  handleReset: () => void;
  handleComplete: () => void;
  handleBack: () => void;
  handleNext: () => void;
  completed: {
    [k: number]: boolean;
  };
  totalSteps: () => number;
}

const Stepper: React.FunctionComponent<IStepperProps> = (props) => {
  const { steps, activeStep, allStepsCompleted, handleReset, completed } = props;
  const classes = useStyles();

  // const handleStep = (step: number) => () => {
  //   setActiveStep(step);
  // };

  return (
    <Box component={"div"} className={classes.stepper} sx={{ width: "100%" }}>
      <MuiStepper nonLinear activeStep={activeStep}>
        {steps.map(({ label }, index) => (
          <Step className={index === activeStep ? classes.toggleBorder : ""} key={label} completed={completed[index]}>
            <StepButton disableRipple sx={{ textAlign: "left", cursor: "default" }} color="inherit">
              <Box component={"div"} sx={{ display: "inline-flex", alignItems: "center", gap: "0.1rem" }}>
                <Typography
                  component="span"
                  sx={{
                    width: "100%",
                    fontWeight: "bold",
                    color: index <= activeStep ? themeColors.black : colors.lighterGray1,
                  }}
                >
                  STEP {index + 1}
                </Typography>
                {index < activeStep && <SVG src={done}></SVG>}
              </Box>
              <Typography sx={{ width: "100%", color: index <= activeStep ? themeColors.gray : colors.lighterGray1 }}>
                {label}
              </Typography>
            </StepButton>
          </Step>
        ))}
      </MuiStepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
            <Box component={"div"} sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box component={"div"} sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>{steps[activeStep].Component}</Typography>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default Stepper;
