import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Box, Button, IconButton, InputAdornment, List, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styledComponent from "styled-components";
import CustomModal from "../../components/modal";
import successIcon from "../../assets/icons/check-one.svg";
import SVG from "react-inlinesvg";
import { styled } from "@mui/material/styles";
import { Loading } from "../../components";
import { useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import ErrorMessage from "../../components/ErrorMessage";
import { MintStatus } from "../../const";
import { MintType } from ".";

const StyledListItem = styledComponent.li`
  display: flex;
  margin-bottom: 16px;
  color: #616161;
  .item-title {
    font-weight: bold;
    width: 200px;
    color: #424242;
  }
  span {
    display: block;
    font-size: 14px;
  }
`;

const LineBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "1.2rem",
  width: "100%",

  "& :first-of-type": {
    width: "50%",
  },
}));

interface IConfirmNFTransaction {
  handleNext: () => void;
  isFinal: () => boolean;
  spendingPass: {
    value: string;
    showPass: boolean;
  };
  setSpendingPass: Dispatch<
    SetStateAction<{
      value: string;
      showPass: boolean;
    }>
  >;
  estimateGas: any;
  ADAPrice: number;
  handleMintData: (type: MintStatus, payloadMint?: any) => Promise<unknown>;
  stepLoading: boolean;
  mintedStatus: {
    type: MintType;
    msg: string;
  };
  payload: any;
}

const ConfirmNFTTransaction: React.FunctionComponent<IConfirmNFTransaction> = ({
  isFinal,
  spendingPass,
  setSpendingPass,
  estimateGas,
  ADAPrice,
  handleMintData,
  stepLoading,
  payload,
  mintedStatus,
}) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [mintData, setMintData] = useState<any>();

  const handleMintFinal = () => {
    handleMintData(MintStatus.Minting, payload).then((res: any) => {
      if (res?.statusCode === 200) {
        setMintData(res?.data);
      }
      setTimeout(() => {}, 2000);
    });

    if (mintedStatus.type === MintType.SUCCESS) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (mintedStatus.type === MintType.SUCCESS && isFinal()) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [mintedStatus.type, isFinal]);

  return (
    <Box component={"div"}>
      {(estimateGas.isLoading || !ADAPrice) && <Loading />}
      <Box component={"div"} sx={{ maxWidth: 560 }}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          Review information
        </Typography>
        <Typography sx={{ mb: 3 }}>Please provide your spending password to confirm this transaction:</Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: 16, mb: 0.5 }}>Password</Typography>
        <TextField
          placeholder="Spending password"
          id="spending-password"
          name="spending-password"
          type={spendingPass.showPass ? "text" : "password"}
          value={spendingPass.value}
          onChange={(e) => setSpendingPass({ ...spendingPass, value: e.target.value })}
          fullWidth
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSpendingPass({ ...spendingPass, showPass: !spendingPass.showPass })}
                  edge="end"
                >
                  {spendingPass.showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <List sx={{ p: 0, mb: 3 }}>
          <StyledListItem>
            <Typography className="item-title">Processing time</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.processingTime} seconds</Typography>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Typography className="item-title">Estimated minting fee</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.estFee} ADA</Typography>
              <Typography component="span">≈ ${(estimateGas.data?.estFee * ADAPrice).toFixed(2)}</Typography>
            </Box>
          </StyledListItem>
          <StyledListItem>
            <Typography className="item-title">Service fee</Typography>
            <Box component={"div"}>
              <Typography>{estimateGas.data?.systemFee} ADA</Typography>
              <Typography component="span">≈ ${(estimateGas.data?.systemFee * ADAPrice).toFixed(2)}</Typography>
            </Box>
          </StyledListItem>
        </List>
        <ErrorMessage msg={mintedStatus.msg} />
        <Box component={"div"} onClick={handleMintFinal} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" sx={{ width: 160 }} disabled={spendingPass.value.length === 0}>
            Next
          </Button>
        </Box>
      </Box>

      {isFinal() && (
        <CustomModal
          disableBackdropClick
          open={showModal}
          onClose={() => setShowModal(false)}
          sx={{ padding: "2.5rem 4rem" }}
        >
          {stepLoading && <Loading />}
          <Box component={"div"} sx={{ textAlign: "center" }}>
            <SVG src={successIcon} />
          </Box>
          <Typography sx={{ marginBottom: "1.2rem", textAlign: "center" }} variant="h3">
            NFT minting request submitted
          </Typography>
          <Box component={"div"}>
            <LineBox>
              <Typography variant="h4">Processing time</Typography>
              <Box component={"div"} width="50%">
                <Typography variant="subtitle1">{estimateGas.data?.processingTime} seconds</Typography>
              </Box>
            </LineBox>
            <LineBox>
              <Typography variant="h4">Estimate minting fee</Typography>
              <Box component={"div"} width="50%">
                <Typography variant="subtitle1">${estimateGas?.data?.estFee?.toFixed(2)}ADA</Typography>
                <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="span">
                  ≈ ${(estimateGas.data?.estFee * ADAPrice).toFixed(2)}
                </Typography>
              </Box>
            </LineBox>
            <LineBox>
              <Typography variant="h4">Service fee</Typography>
              <Box component={"div"} width="50%">
                <Typography variant="subtitle1">{estimateGas.data?.systemFee} ADA</Typography>
                <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="span">
                  ≈ ${estimateGas.data?.systemFee * ADAPrice}
                </Typography>
              </Box>
            </LineBox>
            <LineBox>
              <Typography variant="h4">Contract ID</Typography>
              <Box component={"div"} width="50%">
                <Typography variant="subtitle1">
                  {mintData?.contractId?.length > 15
                    ? `${mintData?.contractId?.slice(0, 6)}...${mintData?.contractId?.slice(-6)}`
                    : mintData?.contractId}
                </Typography>
              </Box>
            </LineBox>
          </Box>

          <Box component={"div"} display="flex" justifyContent="center">
            <Button onClick={() => navigate(links.WALLET)} variant="contained">
              Back to wallet
            </Button>
          </Box>
        </CustomModal>
      )}
    </Box>
  );
};

export default ConfirmNFTTransaction;
