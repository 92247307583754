import { nftModalType } from "./utils/utils";

export const I18N_FALLBACK_LNG = ["en"];
export const I18N_WHITELIST = ["en", "vi"];

export const ApproveStatus = {
  Approved: "approved",
  Rejected: "rejected",
  Pending: "requested",
  Deactivated: "no-corporate",
};

export enum MintStatus {
  InDraft = "in_draft",
  Created = "created",
  Minting = "minting",
  Minted = "minted",
}

export enum OfferStatus {
  Waiting = "waiting",
  Canceled = "canceled",
  Expired = "expired",
  Rejected = "rejected",
  Approved = "approved"
}

export const role = {
  admin: "admin",
  corporate: "corporate",
  user: "user",
};

export type Order = "asc" | "desc";

export const WalletDetailsTabs = {
  minted: "minted",
  minting: "minting",
  nonMinted: "non-minted",
  offers: "offers"
};

export const MAXIMUM_TEXT_FIELD_LENGTH = 255;

export const ToastMessage = {
  WalletCoppied: "Wallet address copied to clipboard",
  LinkCoppied: "Link copied to clipboard",
  UploadFileSuccess: "Upload file succesfully",
  SomeThingWentWrong: "Something went wrong"
};

export const generateLinkType = `${nftModalType.toString()},.docx,.doc,.pdf,.jpg,.jpeg,.png`;
