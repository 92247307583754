import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { localStorageHelper } from "../../helpers";
import { useUser } from "../../contexts/user-provider";
import { links } from "../../constants/links";
import { ROLE } from "../config";

const preventGetInWithAccountRoute = [links.LOGIN, links.SIGN_UP, links.ADMIN_LOGIN];

export const RestrictedRoute = ({
  children,
  isPrivate,
  layout: Layout,
  title,
  role,
}: IRestrictedRoute): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, setUser } = useUser();

  useEffect(() => {
    if(!!user && preventGetInWithAccountRoute.includes(location.pathname)){
      navigate(links.WALLET);
    }
  }, [location.pathname, navigate, user]);

  useEffect(() => {
    if (user) {
      if (role?.includes(ROLE.ADMIN) && role?.includes(ROLE.CORPORATE)) {
        if (user.role !== ROLE.ADMIN && user.role !== ROLE.CORPORATE) {
          navigate(links.LOGIN);
        } else {
          return;
        }
      } else {
        if (role?.includes(ROLE.ADMIN) && !role?.includes(ROLE.CORPORATE)) {
          if (user.role !== ROLE.ADMIN) {
            navigate(links.WALLET);
          }
        }
        if (role?.includes(ROLE.CORPORATE) && !role?.includes(ROLE.ADMIN)) {
          if (user.role !== ROLE.CORPORATE) {
            navigate(links.LIST_USER);
          }
        }
      }
    }
  }, [navigate, role, user, isPrivate]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("account")!));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Component = Layout ? <Layout>{children}</Layout> : children;

  useEffect(() => {
    if (typeof title === "string") {
      document.title = title;
    }
    // eslint-disable-next-line
  }, []);

  // return <Route path={path} element={<>{children}</>} />;
  return (localStorageHelper.isLogin() && isPrivate) || !isPrivate ? Component : (window.location.href = "/login");
};

interface IRestrictedRoute {
  children?: any;
  restricted?: boolean;
  isPrivate?: boolean;
  layout?: any;
  path?: any;
  title?: string;
  role?: ROLE[];
}
