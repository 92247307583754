import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  approvedBtn: {
    color: "#149973",
    borderRadius: "20px",
    background: "#D0FFDD",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  pendingBtn: {
    color: "#E06339",
    borderRadius: "20px",
    background: "#FFE4D0",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  rejectedBtn: {
    color: "#D73021",
    borderRadius: "20px",
    background: "#FFE3E0",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  deactivatedBtn: {
    color: "#3E5463",
    borderRadius: "20px",
    background: "#DCE3E8",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  processingBtn: {
    color: "#FFFFFF",
    borderRadius: "4px",
    background: "#518CE0",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  successfulBtn: {
    color: "#FFFFFF",
    borderRadius: "4px",
    background: "#548574",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
  createdBtn: {
    color: "#FFFFFF",
    borderRadius: "4px",
    background: "#FFE3E0",
    padding: "4px 8px 4px 8px",
    fontSize: 16,
  },
});
export const ActiveStatus = () => {
  const classes = useStyles();
  return <span className={classes.approvedBtn}>Active</span>;
};
export const PendingStatus = () => {
  const classes = useStyles();
  return <span className={classes.pendingBtn}>Pending review</span>;
};
export const RejectedStatus = () => {
  const classes = useStyles();
  return <span className={classes.rejectedBtn}>Rejected</span>;
};
export const DeactivatedStatus = () => {
  const classes = useStyles();
  return <span className={classes.deactivatedBtn}>Deactivated</span>;
};
export const ProcessingStatus = () => {
  const classes = useStyles();
  return <span className={classes.processingBtn}>Processing </span>;
};
export const SuccessfulStatus = () => {
  const classes = useStyles();
  return <span className={classes.successfulBtn}>Successful </span>;
};
export const CreatedStatus = () => {
  const classes = useStyles();
  return <span className={classes.createdBtn}>Created </span>;
};
