/* eslint-disable max-len */
import { Box, MenuItem, Pagination, Select, Typography } from "@mui/material";

const CustomRangePagination = (props: any) => {
  const { meta, rowsPerPage, page, setRowsPerPage, setPage } = props;

  return (
    <Box component={"div"} display={"flex"} alignItems="center" justifyContent={"space-between"}>
      <Typography variant="subtitle2">
        Showing {meta?.totalItems ? (meta?.currentPage - 1) * meta?.itemsPerPage + 1 : 0}-
        {meta?.totalItems
          ? meta?.currentPage === meta?.totalPages
            ? meta?.totalItems
            : meta?.currentPage * meta?.itemsPerPage
          : 0}
        &nbsp; of&nbsp;
        {meta?.totalItems} results{" "}
      </Typography>
      <Box component={"div"} display={"flex"} alignItems="baseline">
        <Select
          sx={{
            marginTop: 1,
            borderRadius: 20,
            height: "40px",
          }}
          value={rowsPerPage}
          onChange={(e: any) => {
            if (e?.target?.value * page > meta?.totalPages) {
              setRowsPerPage(parseInt(e?.target?.value, 10));
              setPage(0);
              return;
            }
            setRowsPerPage(+e.target.value);
          }}
        >
          <MenuItem value={20}>20/page</MenuItem>
          <MenuItem value={50}>50/page</MenuItem>
          <MenuItem value={100}>100/page</MenuItem>
        </Select>
        <Pagination
          defaultPage={1}
          boundaryCount={2}
          count={+meta?.totalPages}
          showFirstButton
          showLastButton
          onChange={(e, page) => {
            setPage(page - 1);
          }}
          page={page + 1}
        />
      </Box>
    </Box>
  );
};

export default CustomRangePagination;
