import axiosClient from "../../api/axiosClient";

const Categories = {
  getCategories(params?: any): Promise<any> {
    const url = `/categories?${params?.mainCategoryId !== undefined && `mainCategoryId=${params?.mainCategoryId}`}`;
    return axiosClient.get(url);
  },
};

export default Categories;
