import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import React, { useState } from "react";
import { LeftNFTDetails } from "../../../components/LeftNFTDetails";
import SentOffers from "./sent";
import ReceievedOffers from "./received";

const Offers = ({ title, walletDetails }: { title: string; walletDetails: any }) => {
  const [offerTab, setOfferTab] = useState("sent");

  return (
    <Grid container>
      <Grid item xs={2} pr={2}>
        <LeftNFTDetails title={title} walletDetails={walletDetails} />
      </Grid>
      <Grid item xs={10}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={offerTab}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setOfferTab((event.target as HTMLInputElement).value)
            }
          >
            <FormControlLabel value="sent" control={<Radio />} label="Sent" />
            <FormControlLabel value="received" control={<Radio />} label="Received" />
          </RadioGroup>
        </FormControl>
        {offerTab === "sent" ? <SentOffers /> : <ReceievedOffers />}
      </Grid>
    </Grid>
  );
};
export default Offers;
