import React from "react";
import { Props } from "../../utils/utils";
import { styled } from "@mui/material";

const MainContent = styled("div")(
  () => `
`,
);

export const EmptyLayout: React.FC<Props> = ({ children }) => {
  return <MainContent>{children}</MainContent>;
};
