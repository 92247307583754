import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BannerState, FileType } from ".";
import { Loading } from "../../components";
import CustomDateRangePicker from "../../components/date-range-picker";
import ErrorMessage from "../../components/ErrorMessage";
import WhiteButton from "../../components/WhiteButton";
import { links } from "../../constants/links";
import Banner, { BannerStatus, BannerType } from "../../services/banner";
import Users from "../../services/users";
import { inValidSizeFile } from "../../utils/inValidSizeFile";
import Countries from "./components/Countries";
import UploadFile from "./components/UploadFile";

interface ICreateBannerProps {
  setBannerStatus: React.Dispatch<React.SetStateAction<BannerState>>;
}

const useStyles = makeStyles({
  dateRangePicker: {
    marginLeft: "0 !important",
    marginTop: "9px !important",
    "& > *": {
      display: "block",
    },
    "& .MuiFormControl-root": {
      width: 100 + "% !important",
    },
  },
});

const SelectBox = styled(Select)({
  "& .MuiSelect-select": {
    padding: "12px",
  },
  borderRadius: "40px",
  width: "100%",
});

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    padding: "12px",
  },
});

export interface IBannerData {
  name: string;
  startDate: string;
  endDate: string;
  isAll: boolean;
  countries: string[];
  type: BannerType;
  ctaButton: string[];
  coverImage: string | FileType;
  url: string;
  status: BannerStatus;
  amount: any;
}

const CreateBanner: React.FunctionComponent<ICreateBannerProps> = ({ setBannerStatus }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useState<IBannerData>({
    name: "",
    startDate: "",
    endDate: "",
    isAll: true,
    countries: [],
    type: undefined,
    ctaButton: [],
    coverImage: {
      file: null,
      imagePreviewUrl: "",
    },
    url: "",
    status: "active",
    amount: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [, setSelectedDate] = useState<any[]>([null, null]);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (bannerData.ctaButton.includes(event.target.name as never)) {
      const index = bannerData.ctaButton.indexOf(event.target.name as never);
      if (index > -1) {
        bannerData.ctaButton.splice(index, 1);
      }
    } else {
      bannerData.ctaButton.push(event.target.name as never);
    }

    setBannerData((prev) => ({ ...prev, ctaButton: [...bannerData.ctaButton] }));
  };

  const handleUpload = async () => {
    try {
      setIsLoading(true);
      const res = await Users.uploadFiles({ file: (bannerData.coverImage as FileType).file });
      setIsLoading(false);
      return res.data[0].link;
    } catch (error: any) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const handleCreateBanner = async () => {
    try {
      setIsLoading(true);
      const linkImage = await handleUpload();
      const payload = {
        name: bannerData.name?.trim(),
        startDate: bannerData.startDate,
        endDate: bannerData.endDate,
        countries: bannerData.countries,
        isAll: bannerData.isAll,
        type: bannerData.type,
        ctaButton: bannerData.ctaButton,
        coverImage: linkImage,
        url: bannerData.url?.trim(),
        status: bannerData.status,
        amount: bannerData.amount,
      };

      await Banner.createBanner(payload);

      setIsLoading(false);
      toast("Create banner successfully");
      setBannerStatus(BannerState.VIEW);
      navigate(links.LIST_BANNERS);
    } catch (error: any) {
      setIsLoading(false);
      setError(error?.response?.data?.message || "Something went wrong");
    }
  };

  const isCreate = (): boolean => {
    const { name, startDate, endDate, countries, isAll, type, ctaButton, coverImage, url, status, amount } = bannerData;
    const isCountries = isAll || (!isAll && countries.length > 0);

    const isCorrectType = () => {
      if (type === "paid") {
        if (amount) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    };
    if (
      name.trim().length &&
      startDate &&
      endDate &&
      countries &&
      isCountries &&
      type &&
      ctaButton.length &&
      ((coverImage as FileType)?.file || (coverImage as FileType)?.imagePreviewUrl) &&
      coverImage &&
      url.trim().length &&
      isCorrectType() &&
      status
    ) {
      return true;
    }
    return false;
  };

  const handleDateChange = (date: any) => {
    setBannerData((prev) => ({
      ...prev,
      startDate: moment(date[0]).toISOString(),
      endDate: moment(date[1]).toISOString(),
    }));
    setSelectedDate(date);
  };

  return (
    <Box component={"div"}>
      {isLoading && <Loading />}
      <Typography variant="h3">Create new banner</Typography>
      <Box component={"div"} mt={2} width={"60%"} minWidth={"60%"}>
        <Box component={"div"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
            Banner name <span style={{ color: "red" }}>*</span>
          </Typography>
          <CustomTextField
            fullWidth
            placeholder="Enter banner name"
            inputProps={{ maxLength: 255 }}
            value={bannerData.name}
            onChange={(e) =>
              setBannerData({
                ...bannerData,
                name: e.target.value,
              })
            }
          />
          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Display time <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} width="100%">
              <CustomDateRangePicker
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => handleDateChange(date)}
                className={classes.dateRangePicker}
                sx={{ display: "block" }}
                defaultValue={[new Date(bannerData.startDate), new Date(bannerData.endDate)]}
              />
            </Box>
          </Box>

          <Countries
            bannerState={BannerState.CREATE}
            isAll={true}
            setCountries={({ countries, isAll = true }) => setBannerData((prev) => ({ ...prev, countries, isAll }))}
          />

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner type <span style={{ color: "red" }}>*</span>
            </Typography>
            <SelectBox
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              placeholder="Select banner type"
              value={bannerData.type}
              renderValue={
                !bannerData.type ? () => <div style={{ color: "#b3b3b3" }}>Select banner type</div> : undefined
              }
              onChange={(e) => setBannerData({ ...bannerData, type: e.target.value as BannerType })}
            >
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="unPaid">Unpaid</MenuItem>
            </SelectBox>
          </Box>

          {bannerData.type === "paid" && (
            <Box component={"div"} mt={3}>
              <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
                Paid amount (USD) <span style={{ color: "red" }}>*</span>
              </Typography>
              <CustomTextField
                type="number"
                fullWidth
                placeholder="Enter paid amount"
                inputProps={{ maxLength: 255 }}
                value={bannerData.amount}
                onChange={(e) =>
                  setBannerData({
                    ...bannerData,
                    amount: +e.target.value as number,
                  })
                }
              />
            </Box>
          )}
          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              CTA button <span style={{ color: "red" }}>*</span>
            </Typography>
            <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bannerData.ctaButton.includes("buyNow")}
                    onChange={handleChangeCheckbox}
                    name="buyNow"
                  />
                }
                label="Buy now"
                sx={{ mr: 5 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bannerData.ctaButton.includes("share")}
                    onChange={handleChangeCheckbox}
                    name="share"
                  />
                }
                label="Share"
              />
            </Box>
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Image <span style={{ color: "red" }}>*</span>
            </Typography>
            <UploadFile
              fileUpload={bannerData.coverImage as FileType}
              setFileUpload={(file) => setBannerData((prev) => ({ ...prev, coverImage: file as FileType }))}
            />

            {(bannerData.coverImage as FileType).file &&
              inValidSizeFile((bannerData.coverImage as FileType).file as File) && (
                <ErrorMessage msg="Maximum upload file size is 10MB" />
              )}
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Banner URL <span style={{ color: "red" }}>*</span>
            </Typography>
            <CustomTextField
              fullWidth
              placeholder="http://, https://...."
              inputProps={{ maxLength: 150 }}
              value={bannerData.url}
              onChange={(e) => setBannerData({ ...bannerData, url: e.target.value })}
            />
          </Box>

          <Box component={"div"} mt={3}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
              Status <span style={{ color: "red" }}>*</span>
            </Typography>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={bannerData.status}
                onChange={(e) => {
                  setBannerData({
                    ...bannerData,
                    status: e.target.value as BannerStatus,
                  });
                }}
              >
                <FormControlLabel value="active" control={<Radio />} label="Active" />
                <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box component={"div"} mt={3} display="flex" justifyContent="center">
            <WhiteButton
              label="Cancel"
              sx={{ paddingLeft: "40px", paddingRight: "40px", marginRight: "10px" }}
              onClick={() => setBannerStatus(BannerState.VIEW)}
            />
            <Button
              variant="contained"
              sx={{ paddingLeft: "40px", paddingRight: "40px" }}
              disabled={
                !isCreate() ||
                (!!(bannerData.coverImage as FileType).file &&
                  inValidSizeFile((bannerData.coverImage as FileType).file as File))
              }
              onClick={handleCreateBanner}
            >
              Save
            </Button>
          </Box>

          <Box component={"div"} mt={3} sx={{ textAlign: "center" }}>
            <ErrorMessage msg={error} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBanner;
