import React, { useContext, useState } from "react";
import { MayBe } from "../types";
import { Props } from "../utils/utils";

export const AppConfigContext = React.createContext<MayBe<any>>(null);

export const SetAppConfigContext = React.createContext<React.Dispatch<React.SetStateAction<MayBe<any>>>>(() => null);

export const useAppConfig = (): MayBe<any> => useContext(AppConfigContext);

export const useSetAppConfig = (): React.Dispatch<React.SetStateAction<MayBe<any>>> => useContext(SetAppConfigContext);

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [user, setApp] = useState<MayBe<any>>(null);
  return (
    <AppConfigContext.Provider value={user}>
      <SetAppConfigContext.Provider value={setApp}>{children}</SetAppConfigContext.Provider>
    </AppConfigContext.Provider>
  );
};
