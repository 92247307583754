import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import CustomTextField from "../../../components/CustomTextField";
import { validationPatterns } from "../../../validation/pattern";
import Authenticate from "../../../services/authenticate";
import ChangePassword from "../../settings/change-password";
import successIcon from "../../../assets/icons/check-one.svg";
import SVG from "react-inlinesvg";
import { useLocation, useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyToken, setVerifyToken] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const onSendEmail = async () => {
    const res = await Authenticate.forgotPassword({ email: email });
    if (res.statusCode === 200) {
      setStep(2);
    }
  };

  const onVerifyCode = async () => {
    try {
      const res = await Authenticate.verifyCode({ email: email, code: verifyCode });
      if (res.statusCode === 200) {
        setVerifyToken(res.data);
        setStep(3);
        setErrMsg("");
      }
    } catch (error: any) {
      setErrMsg(error.response.data.message);
    }
  };

  useEffect(() => {
    if (step === 4) {
      setTimeout(() => {
        window.location.href = location?.state?.previousLink;
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);
  return (
    <>
      <AuthenticateLayout>
        {step === 1 && (
          <>
            <Box
              component={"div"}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb={3}
              gap={0.5}
            >
              <Typography sx={{ fontSize: 20, fontWeight: "600 !important" }}>Forgot password</Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal">
                Enter your account login email.
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal">
                A verification code will be sent to your email to change your password.
              </Typography>
            </Box>
            <CustomTextField
              label="Email"
              placeholder={"Enter your email"}
              value={email}
              onChange={(e: string) => setEmail(e)}
            />
            <Box component={"div"} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{ width: 160 }}
                disabled={!validationPatterns.email.test(email)}
                onClick={onSendEmail}
              >
                Next
              </Button>
            </Box>
          </>
        )}
        {step === 2 && (
          <>
            <Box component={"div"} display="flex" flexDirection="column" alignItems="center" gap={0.5}>
              <Typography sx={{ fontSize: 20, fontWeight: "600 !important" }}>Forgot password</Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" mt={2}>
                We have been sending a verification code to the email
              </Typography>
              <Typography variant="h4">{email}.</Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" mb={2}>
                Enter the verification code to change the new password.
              </Typography>
            </Box>
            <CustomTextField
              label="Verification code"
              placeholder={"Enter your verification code"}
              value={verifyCode}
              onChange={(e: string) => setVerifyCode(e)}
            />
            <Box component={"div"} display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{ width: 160 }}
                disabled={!validationPatterns.email.test(email)}
                onClick={onVerifyCode}
              >
                Next
              </Button>
            </Box>
          </>
        )}
        {step === 3 && (
          <>
            <ChangePassword
              verifyToken={verifyToken}
              setStep={setStep}
              step={step}
              previousLink={location?.state?.previousLink}
            />
          </>
        )}
        {step === 4 && (
          <>
            <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
              <SVG src={successIcon} />
              <Typography
                variant="h3"
                sx={{
                  mb: 2,
                  mt: 2,
                }}
              >
                Congratulations!
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{ textAlign: "center", marginBottom: 2 }}
              >
                Your password has been changed successfully.
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
                You will be transferred to the next page in a few moments. If you are
              </Typography>
              <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
                not automatically transferred, please press the <b>“Next”</b> button below.
              </Typography>

              <Button variant="contained" sx={{ marginTop: 4 }} onClick={() => navigate(-1)}>
                Back to Sign in
              </Button>
            </Box>
          </>
        )}
        {errMsg && (
          <Typography align="center" sx={{ color: "red", fontStyle: "italic", marginTop: 2, fontSize: 14 }}>
            {errMsg}
          </Typography>
        )}
      </AuthenticateLayout>
    </>
  );
};

export default ForgetPassword;
