import React, { useEffect, useState } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { DateRangeDelimiter, DateRangePicker } from "@material-ui/pickers";
import { DateRange } from "@material-ui/pickers/DateRangePicker/RangeTypes";
import _ from "lodash";
import moment, { Moment } from "moment";
import SVG from "react-inlinesvg";
import calenderIcon from "../../assets/icons/calendar.svg";
import { commonLetterSpacing } from "../../theme";
import colors from "../../theme/colors";
import { Box, IconButton, SxProps, Theme } from "@mui/material";
import { convertTimeZoneISOString } from "../../utils/convertTimeZoneISOString";
import { makeStyles } from "@mui/styles";
import { TextField } from "@material-ui/core";

const useToolbarStyles: any = makeStyles((): any => ({
  root: {
    marginLeft: 30,
    display: "flex",
    transition: "0.2s",
    "&.disabled .MuiPickersDateRangePickerInput-root": {
      background: "#E0E0E0",
    },
    "& .MuiInput-root": {
      padding: "14px 10px",
      width: 178,
      border: "1px solid #D9D9D9",
    },
    "& .MuiFormHelperText-root": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
    "& .MuiPickersDateRangePickerInput-root": {
      border: "1px solid #babcbf",
      borderRadius: 40,
      alignItems: "center",
      flexDirection: "unset",
      "&:hover": {
        border: "1px solid #8a94a6",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "12px 10px",
    },
    "& .MuiFormControl-root": {
      width: 110,
    },
    "& .MuiTypography-root": {
      margin: 0,
    },
    "& .MuiInputBase-root": {
      fontSize: 14,
      lineHeight: "19px",
      letterSpacing: commonLetterSpacing,
    },
    "& .calenderIcon": {
      paddingRight: 12,
      "& .MuiButtonBase-root": {
        padding: 0,
      },
    },
    "& .cancelIcon": {
      "& svg": {
        "& path": {
          color: "#e9e1e1",
        },
      },
      "& button": {
        "&:hover": {
          backgroundColor: "unset",
          color: colors.primary,
          "& svg": {
            "& path": {
              color: colors.primary,
            },
          },
        },
      },
    },
  },
  dateInput: {
    "&::-webkit-appearance, ::-webkit-calendar-picker-indicator": {
      display: "none",
      "-webkit-appearance": "none",
    },
    cursor: "pointer",
    pointerEvents: "none !important",
  },
}));

interface ICustomDateRangePickerProps {
  onChange?: (selectedDate: [Date | null | Moment, Date | null | Moment]) => void;
  value?: DateRange<Date>;
  open?: boolean;
  onClose?: (boolean?: boolean) => void;
  onOpen?: (boolean?: boolean) => void;
  handleClear?: () => void;
  setForceUpdate?: (bool: boolean) => void;
  defaultValue?: DateRange<Date>;
  className?: any;
  setStartDate: any;
  setEndDate: any;
  startDate: any;
  endDate: any;
  sx?: SxProps<Theme> | undefined;
  disable?: boolean;
  triggerClear?: boolean;
}

const CustomDateRangePicker = (props: ICustomDateRangePickerProps): JSX.Element => {
  const { setStartDate, setEndDate, startDate, endDate } = props;
  const classes = useToolbarStyles();

  const [selectedDate, setSelectedDate] = React.useState<DateRange<Date>>(props.defaultValue ?? [null, null]);
  const [openCalender, setOpenCalender] = React.useState(false);
  const [forceUpdatePicker, setForceUpdatePicker] = useState(true);
  useEffect(() => {
    if (!openCalender && forceUpdatePicker) {
      if (props.onChange) {
        const newDate: [Date | null | Moment, Date | null | Moment] = [
          selectedDate[0] ? convertTimeZoneISOString(selectedDate[0]) : null,
          selectedDate[1] ? convertTimeZoneISOString(selectedDate[1]) : null,
        ];
        props.onChange(newDate);
      }
    }

    if (!forceUpdatePicker) {
      setForceUpdatePicker(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCalender, selectedDate]);

  useEffect(() => {
    if (_.isEqual(selectedDate[0], selectedDate[1])) {
      setOpenCalender(false);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (props.value) {
      setSelectedDate(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (startDate === "" && endDate === "") {
      setSelectedDate([null, null]);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedDate.length) {
      if (selectedDate[0]) {
        const value: string = selectedDate[0] ? moment(selectedDate[0]).format("YYYY-MM-DD") : "";
        setStartDate(value);
      }
      if (selectedDate[1]) {
        const value: string = selectedDate[1] ? moment(selectedDate[1]).format("YYYY-MM-DD") : "";
        setEndDate(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);
  useEffect(() => {
    props.triggerClear ? handleClear() : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.triggerClear]);

  const handleClose = () => {
    setOpenCalender(false);
  };

  const handleOpen = () => {
    setOpenCalender(true);
    if (props.onOpen) {
      props.onOpen(true);
    }
  };

  const handleClear = () => {
    setSelectedDate([null, null]);
    setEndDate("");
    setStartDate("");
    if (props.handleClear) {
      props.handleClear();
    }
    if (props.onChange) {
      props.onChange([null, null]);
    }
  };

  const handleChange = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <Box
      component={"div"}
      className={`${classes.root} ${props.className} ${props.disable ? "disabled" : ""}`}
      sx={{ ...props.sx, minWidth: 293 }}
    >
      <DateRangePicker
        startText=""
        endText=""
        value={selectedDate}
        OpenPickerButtonProps={<SVG src={calenderIcon} />}
        openPickerIcon={<SVG src={calenderIcon} />}
        open={openCalender}
        onClose={() => handleClose()}
        inputFormat="DD/MM/YYYY"
        {...props}
        onChange={handleChange}
        mask={"__/__/____"}
        toolbarFormat="DD/MM/YYYY"
        renderInput={(startProps, endProps) => {
          return (
            <>
              <TextField
                type="date"
                className={classes.dateInput}
                {...startProps}
                name="startDate"
                inputProps={{ type: "date", max: "2500-12-31" }}
                value={startDate}
                placeholder="dd/mm/yyyy"
              />
              <DateRangeDelimiter> - </DateRangeDelimiter>
              <TextField
                type="date"
                className={classes.dateInput}
                {...endProps}
                name="endDate"
                inputProps={{ type: "date", max: "2500-12-31" }}
                value={endDate}
                placeholder="dd/mm/yyyy"
              />
              <div className="cancelIcon">
                <IconButton onClick={handleClear}>
                  <CancelIcon />
                </IconButton>
              </div>
              <div className="calenderIcon">
                <IconButton onClick={() => handleOpen()}>
                  <SVG src={calenderIcon} />
                </IconButton>
              </div>
            </>
          );
        }}
      />
    </Box>
  );
};

export default CustomDateRangePicker;
