import { Button, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CustomTextField from "../../components/CustomTextField";
import { PhoneNumberInput } from "../../components/phoneNumberInput";
import WhiteButton from "../../components/WhiteButton";
import { themeColors } from "../../theme/schemes/PureLightTheme";
import bin from "../../assets/icons/delete.svg";
import SVG from "react-inlinesvg";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import CustomModal from "../../components/modal";
import { toast } from "react-toastify";
import { links } from "../../constants/links";
import Categories from "../../services/categories";
import ServicesManagment from "../../services/services-managment";
import { Loading } from "../../components";
import Users from "../../services/users";

enum ModalStatus {
  NONE = "NONE",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

const useStyles = makeStyles({
  input: {
    width: "60% !important",
  },
  uploadButton: {
    color: `${themeColors.primary} !important`,
    border: `1px solid ${themeColors.primary} !important`,
    "&:hover": {
      background: `${themeColors.white} !important`,
    },
    marginRight: 16,
    width: 120,
  },
  customTextArea: {
    "& .MuiInputBase-root": {
      borderRadius: "16px !important",
    },
  },
  customSelect: {
    width: "60% !important",
    "& .Mui-disabled": {
      background: themeColors.lighterGray1,
    },
  },
});
export default function NFTServiceDetails() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reader, _] = useState(new FileReader());
  const [imagePreview, setImagePreview] = useState<any>(undefined);
  const [updateImage, setUpdateImage] = useState<any>(undefined);

  const [textLength, setTextLength] = useState(0);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [subCategoryList, setSubCategoryList] = useState<any>([]);

  const [serviceDetails, setServiceDetails] = useState<any>({});
  const [showModalOption, setShowModalOption] = useState({ status: false, type: ModalStatus.NONE });
  const [isLoading, setIsLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const fileHandler = (e: any) => {
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
      reader.addEventListener("load", () => {
        setImagePreview(reader?.result);
        setUpdateImage(e.target.files![0]);
      });
    }
  };
  const onHandleTextAreaChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextLength(e.target.value.length);
    setServiceDetails({ ...serviceDetails, description: e.target.value });
  };

  const getSubCategory = async (item?: any) => {
    const res = await Categories.getCategories({ mainCategoryId: item ? item : undefined });
    if (res.statusCode === 200) {
      setSubCategoryList(res.data);
    }
  };

  const getServiceDetail = async () => {
    const serviceID = window.location.href.split("nft-service-details/").pop();

    const res = await ServicesManagment.getServiceDetail({ serviceId: serviceID });
    if (res.statusCode === 200) {
      setServiceDetails({
        ...res?.data,
        category: res?.data?.category?.name,
        subCategory: res?.data?.subCategory?.name,
        serviceType: res?.data?.serviceType,
      });
      setImagePreview(res?.data?.serviceLogo);
      await getSubCategory(res?.data?.category?.id);
    }
  };

  useEffect(() => {
    getServiceDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = (e: string) => {
    const selectedCategory = categoryList.find((x: any) => {
      return x.name === e;
    });

    setServiceDetails({ ...serviceDetails, category: e });
    getSubCategory(selectedCategory?.id);
  };

  const handleSubCategoryChange = (e: string) => {
    setServiceDetails({ ...serviceDetails, subCategory: e });
  };

  const onHandleDelete = async () => {
    const res = await ServicesManagment.deleteService({ serviceId: serviceDetails.id });
    if (res.statusCode === 200) {
      navigate(links.NFT_SERVICE);
      toast("Delete service brand successfully.");
    }
  };

  useEffect(() => {
    if (location?.state?.serviceState === "edit") {
      setIsEdit(true);
    }
  }, [location?.state?.serviceState]);

  const renderModalContent = (type: ModalStatus) => {
    switch (type) {
      case ModalStatus.EDIT:
        return renderCancelEdit();
      case ModalStatus.DELETE:
        return renderWarningDelete();
      default:
        return <></>;
    }
  };

  const renderCancelEdit = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              mb: 1,
            }}
          >
            Cancel editing
          </Typography>
        </Box>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Your changes will be lost if you exit now.
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Are you sure you want to cancel the editing?
        </Typography>
        <Box component={"div"} display={"flex"} justifyContent="center" alignItems="center" mt={2}>
          <WhiteButton
            label="Cancel"
            onClick={() => {
              setShowModalOption({ status: false, type: ModalStatus.NONE });
              if (location?.state?.serviceState === "create") {
                navigate(links.NFT_SERVICE);
              }
            }}
            width={160}
          />
          <Button
            variant="contained"
            onClick={() => {
              setIsEdit(false);
              getServiceDetail();
              setShowModalOption({ status: false, type: ModalStatus.NONE });
              if (location?.state?.serviceState === "create") {
                navigate(links.NFT_SERVICE);
              }
            }}
            sx={{ marginLeft: 2, color: "white", width: 160 }}
          >
            Confirm
          </Button>
        </Box>
      </>
    );
  };

  const renderWarningDelete = () => {
    return (
      <>
        <Box component={"div"} display={"flex"} flexDirection="column" alignItems="center">
          {/* <Box component={'div'} height={500} width={500}> */}
          <SVG src={bin} width={50} height={50} />
          {/* </Box> */}
          <Typography
            variant="h3"
            sx={{
              mb: 1,
              mt: 2,
            }}
          >
            Delete Service brand
          </Typography>
        </Box>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          Are you sure you want to delete this Service brand?
        </Typography>
        <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ textAlign: "center" }}>
          WARNING this process can not be reversed. Cancel to go back.
        </Typography>

        <Box component={"div"} display={"flex"} justifyContent="center" alignItems="center" mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              setShowModalOption({ status: false, type: ModalStatus.NONE });
            }}
            sx={{ marginRight: 2, color: "white", width: 160 }}
          >
            Cancel
          </Button>
          <WhiteButton width={160} label="Delete" onClick={onHandleDelete} />
        </Box>
      </>
    );
  };
  const onCreateService = async (item?: any) => {
    const selectedCategory = categoryList.find((x: any) => {
      return x.name === serviceDetails?.category;
    });
    const selectedSubCategory = subCategoryList.find((x: any) => {
      return x.name === serviceDetails?.subCategory;
    });
    try {
      const res = await ServicesManagment.createService({
        ...(serviceDetails.id && { serviceId: serviceDetails.id }),
        categoryId: selectedCategory?.id,
        subCategoryId: selectedSubCategory?.id,
        serviceType: serviceDetails?.serviceType,
        serviceBrand: serviceDetails?.serviceBrand,
        logo: item?.link ?? serviceDetails?.serviceLogo,
        description: serviceDetails?.description,
        website: serviceDetails?.website,
        address: serviceDetails?.address,
        phoneNumber: serviceDetails?.phoneNumber,
        email: serviceDetails?.email,
      });
      if (res.statusCode === 200) {
        setIsEdit(false);
        if (location?.state?.serviceState === "create") {
          setIsLoading(false);
          navigate(`/nft-service-details/${res?.data?.id}`, { state: { serviceState: "edit" } });
          toast("Create new service brand successfully");
        } else {
          setIsLoading(false);

          toast("Update service brand successfully");
        }
      }
    } catch (error) {
      toast.error("Please fill all required fields");
    }
  };

  const onUpdateClick = async () => {
    if (location?.state?.serviceState !== "create") {
      if (updateImage !== undefined) {
        setIsLoading(true);
        const res = await Users.uploadFiles({ file: updateImage });
        if (res.statusCode === 200) {
          onCreateService(res.data[0]);
        }
      } else {
        onCreateService();
      }
    }
  };

  const onCreateClick = async () => {
    if (location?.state?.serviceState == "create") {
      if (updateImage !== undefined) {
        setIsLoading(true);
        const res = await Users.uploadFiles({ file: updateImage });
        if (res.statusCode === 200) {
          onCreateService(res.data[0]);
        }
      } else {
        toast.error("Please fill all required fields");
      }
      setIsEdit(false);
    }
  };

  const getCategory = async () => {
    const res = await Categories.getCategories();
    if (res.statusCode === 200) {
      setCategoryList(res.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Box component={"div"}>
      <span style={{ color: themeColors.lightGray }}>
        NFT Service &nbsp;&nbsp;&gt;&nbsp;&nbsp;
        <span style={{ color: themeColors.gray }}>
          {location?.state?.serviceState === "create" ? "Create new service brand" : "Services detail"}
        </span>
      </span>
      <Box component={"div"} display="flex" justifyContent={"space-between"}>
        <Typography variant="h3" mt={2} mb={1}>
          {location?.state?.serviceState === "create" ? "Create new service brand" : "Services detail"}
        </Typography>
        {!isEdit && location?.state?.serviceState !== "create" && (
          <Box component={"div"} display="flex" justifyContent="center" mt={2}>
            <Button
              onClick={() => {
                setShowModalOption({ status: true, type: ModalStatus.DELETE });
              }}
              variant="outlined"
              color="error"
              sx={{ width: "140px" }}
              startIcon={<SVG src={bin} />}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsEdit(true);
              }}
              sx={{ width: "140px", marginLeft: 2 }}
              startIcon={<BorderColorOutlinedIcon />}
            >
              Edit
            </Button>
          </Box>
        )}
      </Box>

      <Grid mb={1}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
          <Box component={"div"} display="flex">
            Category
            <div style={{ color: "red" }}>*</div>
          </Box>
        </Typography>

        {location?.state?.serviceState === "create" ? (
          <Select
            className={classes.customSelect}
            disabled={!isEdit && location?.state?.serviceState !== "create"}
            value={serviceDetails?.category}
            onChange={(e: any) => handleCategoryChange(e.target.value)}
            style={{ marginRight: 10, minWidth: 200 }}
          >
            {categoryList.length > 0 &&
              categoryList.map((x: any, index: number) => {
                return (
                  <MenuItem key={index} value={x.name}>
                    {x.name}
                  </MenuItem>
                );
              })}
          </Select>
        ) : (
          serviceDetails?.category && (
            <Select
              className={classes.customSelect}
              disabled={!isEdit && location?.state?.serviceState !== "create"}
              value={serviceDetails?.category}
              onChange={(e: any) => handleCategoryChange(e.target.value)}
              style={{ marginRight: 10, minWidth: 200 }}
            >
              {categoryList.length > 0 &&
                categoryList.map((x: any, index: number) => {
                  return (
                    <MenuItem key={index} value={x.name}>
                      {x.name}
                    </MenuItem>
                  );
                })}
            </Select>
          )
        )}
      </Grid>

      <Grid mb={1}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
          <Box component={"div"} display="flex">
            Sub-Category
            <div style={{ color: "red" }}>*</div>
          </Box>
        </Typography>
        {location?.state?.serviceState === "create" ? (
          <Select
            className={classes.customSelect}
            disabled={!isEdit && location?.state?.serviceState !== "create"}
            value={serviceDetails?.subCategory}
            onChange={(e: any) => handleSubCategoryChange(e.target.value)}
            style={{ marginRight: 10, minWidth: 200 }}
          >
            {subCategoryList.map((x: any, index: number) => {
              return (
                <MenuItem key={index} value={x.name}>
                  {x.name}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          serviceDetails?.subCategory && (
            <Select
              className={classes.customSelect}
              disabled={!isEdit && location?.state?.serviceState !== "create"}
              value={serviceDetails?.subCategory}
              onChange={(e: any) => handleSubCategoryChange(e.target.value)}
              style={{ marginRight: 10, minWidth: 200 }}
            >
              {subCategoryList.map((x: any, index: number) => {
                return (
                  <MenuItem key={index} value={x.name}>
                    {x.name}
                  </MenuItem>
                );
              })}
            </Select>
          )
        )}
      </Grid>

      <Grid mb={1}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
          <Box component={"div"} display="flex">
            Service type
            <div style={{ color: "red" }}>*</div>
          </Box>
        </Typography>
        {location?.state?.serviceState === "create" ? (
          <Select
            className={classes.customSelect}
            disabled={!isEdit && location?.state?.serviceState !== "create"}
            value={serviceDetails?.serviceType}
            onChange={(e: any) => setServiceDetails({ ...serviceDetails, serviceType: e.target.value })}
            style={{ marginRight: 10, minWidth: 200 }}
          >
            <MenuItem value={"insurance"}>{"Insurance"}</MenuItem>
            <MenuItem value={"finance"}>{"Finance"}</MenuItem>
            <MenuItem value={"service"}>{"Service"}</MenuItem>
          </Select>
        ) : (
          serviceDetails.serviceType && (
            <Select
              className={classes.customSelect}
              disabled={!isEdit && location?.state?.serviceState !== "create"}
              value={serviceDetails?.serviceType}
              onChange={(e: any) => setServiceDetails({ ...serviceDetails, serviceType: e.target.value })}
              style={{ marginRight: 10, minWidth: 200 }}
            >
              <MenuItem value={"insurance"}>{"Insurance"}</MenuItem>
              <MenuItem value={"finance"}>{"Finance"}</MenuItem>
              <MenuItem value={"service"}>{"Service"}</MenuItem>
            </Select>
          )
        )}
      </Grid>

      <Typography variant="h3" mt={2} mb={1}>
        Service information
      </Typography>
      <Grid>
        <CustomTextField
          disabled={!isEdit && location?.state?.serviceState !== "create"}
          className={classes.input}
          value={serviceDetails?.serviceBrand}
          label="Service brand"
          required
          placeholder={"Enter service brand"}
          onChange={(e: any) => setServiceDetails({ ...serviceDetails, serviceBrand: e })}
        ></CustomTextField>
      </Grid>

      <Grid mb={2}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
          <Box component={"div"} display="flex">
            Logo &nbsp;
            <div style={{ color: "red" }}> *</div>
          </Box>
        </Typography>
        <Typography variant="subtitle1" mb={1}>
          Maximum 10 MB
        </Typography>
        {imagePreview && <img src={imagePreview} alt="Alt" style={{ borderRadius: 60, height: 120, width: 120 }} />}
        <input
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          onChange={fileHandler}
          accept="image/*"
        />
        <br />
        {(isEdit || location?.state?.serviceState === "create") && (
          <label htmlFor="contained-button-file">
            <Button className={classes.uploadButton} component="span">
              Upload
            </Button>
          </label>
        )}
      </Grid>
      <Grid mb={2} width={"60%"}>
        <Typography sx={{ fontWeight: "bold", mb: 1 }} variant="h4">
          <Box component={"div"} display="flex">
            Description &nbsp;
            <div style={{ color: "red" }}> *</div>
          </Box>
        </Typography>

        <TextField
          className={classes.customTextArea}
          fullWidth
          multiline
          rows={4}
          placeholder="Enter description"
          inputProps={{ maxLength: 150 }}
          onChange={(e) => onHandleTextAreaChange(e)}
          sx={{
            background:
              !isEdit && location?.state?.serviceState !== "create" ? themeColors.lighterGray1 : themeColors.white,
            borderRadius: "16px",
            pointerEvents: !isEdit && location?.state?.serviceState !== "create" ? "none" : "auto",
          }}
          value={serviceDetails?.description}
        />
        <Box component={"div"} display="flex" justifyContent={"end"}>
          <Typography variant="subtitle1" mb={1}>
            {textLength}/150
          </Typography>
        </Box>
      </Grid>

      <Grid>
        <CustomTextField
          disabled={!isEdit && location?.state?.serviceState !== "create"}
          className={classes.input}
          value={serviceDetails?.website}
          label="Website"
          required
          placeholder={"Enter website"}
          onChange={(e: any) => setServiceDetails({ ...serviceDetails, website: e })}
        ></CustomTextField>
      </Grid>
      <Grid>
        <PhoneNumberInput
          className={classes.input}
          label="Phone number"
          required
          disabled={!isEdit && location?.state?.serviceState !== "create"}
          value={serviceDetails?.phoneNumber}
          onChange={(e: any) => setServiceDetails({ ...serviceDetails, phoneNumber: e })}
        />
      </Grid>
      <Grid>
        <CustomTextField
          disabled={!isEdit && location?.state?.serviceState !== "create"}
          className={classes.input}
          value={serviceDetails?.address}
          label="Address"
          required
          placeholder={"Enter website"}
          onChange={(e: any) => setServiceDetails({ ...serviceDetails, address: e })}
        ></CustomTextField>
      </Grid>
      <Grid>
        <CustomTextField
          disabled={!isEdit && location?.state?.serviceState !== "create"}
          className={classes.input}
          value={serviceDetails?.email}
          label="Email"
          required
          placeholder={"Enter email"}
          onChange={(e: any) => setServiceDetails({ ...serviceDetails, email: e })}
        ></CustomTextField>
      </Grid>
      {location?.state?.serviceState !== "create" && isEdit && (
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <WhiteButton
            label="Cancel"
            onClick={() => {
              setShowModalOption({ status: true, type: ModalStatus.EDIT });
            }}
            width="140px"
          />

          <Button variant="contained" onClick={onUpdateClick} sx={{ width: "140px", marginLeft: 2 }}>
            Save
          </Button>
        </Box>
      )}
      {location?.state?.serviceState === "create" && (
        <Box component={"div"} display="flex" justifyContent="center" mt={2}>
          <WhiteButton
            label="Cancel"
            onClick={() => {
              setShowModalOption({ status: true, type: ModalStatus.EDIT });
            }}
            width="140px"
          />

          <Button variant="contained" onClick={onCreateClick} sx={{ width: "140px", marginLeft: 2 }}>
            Create
          </Button>
        </Box>
      )}
      <CustomModal
        sx={{ maxWidth: "608px" }}
        open={showModalOption.status}
        onClose={() => setShowModalOption({ status: false, type: ModalStatus.NONE })}
      >
        {renderModalContent(showModalOption.type)}
      </CustomModal>
      {isLoading && <Loading />}
    </Box>
  );
}
